import { styled } from 'utils/styles';

export const Button = styled('button', {
  border: '1px solid',
  br: '4px',
  cursor: 'pointer',
  transition: 'background-color 200ms, border-color 200ms',
  '&:disabled': {
    pointerEvents: 'none',
    opacity: '0.5',
  },
  '& svg': {
    height: '14px',
    transformOrigin: '50% 25%',
    transform: 'scale(2)',
    '& path': {
      fill: '$white',
    },
  },
  variants: {
    size: {
      md: {
        minHeight: '40px',
        py: '$spacing12',
        px: '$5',
        typo: 'heading2',
      },
      sm: {
        py: '$1',
        px: '$5',
        typo: 'body2',
      },
    },
    variant: {
      contained: {
        color: '$white',
      },
      outlined: {
        bg: 'transparent',
      },
      ghost: {
        bg: 'transparent',
        borderColor: 'transparent',
      },
    },
    color: {
      primary: {},
      secondary: {},
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    isLoading: {
      true: {
        lineHeight: '0px',
        pointerEvents: 'none',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'contained',
      color: 'primary',
      css: {
        bg: '$primary',
        borderColor: '$primary',
      },
    },
    {
      variant: 'outlined',
      color: 'primary',
      css: {
        borderColor: '$primary',
        color: '$primary',
        '&:hover': {
          bg: '$gray30',
        },
        '& svg path': {
          fill: '$primary',
        },
      },
    },
    {
      variant: 'ghost',
      color: 'primary',
      css: {
        color: '$primary',
        '&:hover': {
          bg: '$gray30',
          borderColor: '$gray30',
        },
        '& svg path': {
          fill: '$primary',
        },
      },
    },
    {
      variant: 'contained',
      color: 'secondary',
      css: {
        bg: '$blue',
        borderColor: '$blue',
      },
    },
    {
      variant: 'outlined',
      color: 'secondary',
      css: {
        borderColor: '$blue',
        color: '$blue',
        '&:hover': {
          bg: '$blueLight',
        },
        '& svg path': {
          fill: '$blue',
        },
      },
    },
    {
      variant: 'ghost',
      color: 'secondary',
      css: {
        color: '$blue',
        '&:hover': {
          bg: '$blueLight',
          borderColor: '$blueLight',
        },
        '& svg path': {
          fill: '$blue',
        },
      },
    },
  ],
  defaultVariants: {
    size: 'md',
    variant: 'contained',
    color: 'primary',
  },
});
