import { ComponentProps, ReactNode } from 'react';

import { isFree } from 'utils/functions/currency';

import { SelectBox } from '../SelectBox/SelectBox';
import { Price } from '../Price';

import { ReactComponent as StoreIcon } from 'utils/assets/images/svg/store.svg';
import { ReactComponent as HouseIcon } from 'utils/assets/images/svg/house.svg';

import { Typography } from '../Typography/Typography';
import { ContainerTypography } from './styles';
import { colors } from 'utils/styles/tokens/colors';

interface IDeliverySelectProps extends Omit<ComponentProps<typeof SelectBox>, 'children'> {
  placeholder: string;
  title?: string;
  price?: string | number;
  description?: string;
  descriptionLine2?: string;
  isBopisOrPickUpStore?: boolean;
  component?: ReactNode;
}

export const DeliverySelect = ({
  placeholder,
  title,
  price,
  description,
  descriptionLine2,
  selected,
  isBopisOrPickUpStore,
  onAction,
  component,
  ...rest
}: IDeliverySelectProps) => {
  const priceVariant = price !== undefined && isFree(price) ? 'discount' : undefined;
  const iconSelected =
    selected && isBopisOrPickUpStore ? (
      <StoreIcon preserveAspectRatio="xMidYMid meet" width="100%" fill={colors.blue} />
    ) : (
      <HouseIcon preserveAspectRatio="xMidYMid meet" width="100%" />
    );
  const iconProp = selected ? iconSelected : undefined;

  return (
    <>
      <SelectBox onAction={onAction} icon={iconProp} selected={selected} {...rest}>
        <SelectBox.Placeholder>{placeholder}</SelectBox.Placeholder>
        <SelectBox.Selected>
          {!!component ? (
            component
          ) : (
            <>
              <Typography variant="heading3">
                {title}
                {price !== undefined && (
                  <Price variant={priceVariant} css={{ ml: '$2' }}>
                    {price}
                  </Price>
                )}
              </Typography>
              <ContainerTypography>
                <Typography variant="body2">{description}</Typography>
                <Typography variant="body2">{descriptionLine2}</Typography>
              </ContainerTypography>
            </>
          )}
        </SelectBox.Selected>
      </SelectBox>
    </>
  );
};

DeliverySelect.Placeholder = SelectBox.Placeholder;
DeliverySelect.Selected = SelectBox.Selected;
