import { styled } from 'utils/styles';

export const Container = styled('div', {
  height: 16,
  width: '100%',
  maxWidth: 184,
  display: 'flex',
  borderRadius: 4,
  color: '#2083B0',
  alignItems: 'center',
  background: '#D0F0FF',
  justifyContent: 'center',
});

export const Message = styled('span', {
  fontSize: 11,
  marginLeft: 8,
  fontWeight: 500,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
