import { cleanCardNumber, stringHasLength } from 'utils/functions/text';
import { isRiachueloBrandedCardByNumber, isRiachueloPlCardByNumber } from './credit-card';

import { regexOnlyNumbers } from './regex';
import validator from 'card-validator';

export const validateCpf = (cpf) => {
  if (/\D/.test(cpf) && cpf.length !== 14) {
    return null;
  }

  if (!/\D/.test(cpf) && cpf.length !== 11) {
    return null;
  }

  let formatCpf = cpf;
  if (/\D/.test(cpf)) {
    formatCpf = cpf.replace(/[.-]/g, '');
  }

  const operationDiscount = (initialDiscount, cpfDigits) => {
    let sum = 0;

    for (let i = 0; i < initialDiscount - 1; i += 1) {
      const currentNumberCpf = parseInt(cpfDigits[i], 10);
      sum += currentNumberCpf * (initialDiscount - i);
    }

    return sum;
  };

  const getDigitValidator = (initialDiscount, cpfDigits) => {
    const result = operationDiscount(initialDiscount, cpfDigits) % 11;
    return result === 0 || result === 1 ? 0 : 11 - result;
  };

  const firstDigitValidator = getDigitValidator(10, formatCpf);
  const secondDigitValidator = getDigitValidator(11, `${formatCpf}${firstDigitValidator}`);
  const calculatedDigits = `${firstDigitValidator}${secondDigitValidator}`;

  return formatCpf.slice(9, 11) === calculatedDigits;
};

export const isRiachueloCardNumberValid = (cardNumber) => {
  const cleanCreditCardNumber = cleanCardNumber(cardNumber);

  if (isRiachueloPlCardByNumber(cleanCreditCardNumber)) {
    const RiachueloPlCreditCardLength = 14;
    return stringHasLength(cleanCreditCardNumber, RiachueloPlCreditCardLength);
  }

  if (isRiachueloBrandedCardByNumber(cleanCreditCardNumber)) {
    const RiachueloBrandedCreditCardLength = 16;
    return stringHasLength(cleanCreditCardNumber, RiachueloBrandedCreditCardLength);
  }

  return false;
};

export const isCreditCardNumberValid = (cardNumberToValidate: string, isRchloCard: boolean) => {
  const cleanCreditCardNumber = cleanCardNumber(cardNumberToValidate);
  const isOnlyNumbers = regexOnlyNumbers.test(cleanCreditCardNumber);

  if (!isOnlyNumbers) {
    return false;
  }

  if (isRchloCard) {
    return isRiachueloCardNumberValid(cleanCreditCardNumber);
  }

  return validator.number(cleanCreditCardNumber)?.isValid;
};

export const getValidQrcode = (code: string) => {
  if (code?.length > 7) return '0';
  if (!/^[0-9]/.test(code)) return '0';
  return code;
};
