/**
 * Generate Cybersource Url script.
 * @param sessionId pass sessionId generate to browser client.
 * @returns url
 */
export function generateCybersourceUrl(sessionId: string) {
  if (!sessionId) {
    throw new Error('sessionId is null');
  }
  if (!process.env.REACT_APP_CSRC_SCRIPT_URL) {
    throw new Error('REACT_APP_CSRC_SCRIPT_URL is null');
  }
  if (!process.env.REACT_APP_CSRC_ORGID) {
    throw new Error('REACT_APP_CSRC_ORGID is null');
  }
  if (!process.env.REACT_APP_CSRC_MERCHANTID) {
    throw new Error('REACT_APP_CSRC_MERCHANTID is null');
  }
  return `${process.env.REACT_APP_CSRC_SCRIPT_URL}?org_id=${process.env.REACT_APP_CSRC_ORGID}&session_id=${process.env.REACT_APP_CSRC_MERCHANTID}${sessionId}`;
}
