import { type Category } from '@estore/types';

/**
 * Função que permite recuperar um valor de categoria.
 * @param categories Lista de categorias retornado pelo e-commerce.
 * @param index Posição da lista que deseja retornar.
 * @param prop Nome da propriedade que deseja recuperar o valor.
 * @returns string ou indefinido.
 */
export function getCategory(categories: Category[] = [], index = 0, prop: keyof Omit<Category, 'categoryId'> = 'slug') {
  return categories?.[index]?.[prop];
}
