import { styled } from 'utils/styles';

export const Box = styled('div', {
  p: '12px 20px',
  bg: '$white',
  variants: {
    isRounded: {
      true: {
        br: '4px',
      },
    },
    isFloating: {
      true: {
        bs: '0 1.5px 3px rgba(0, 0, 0, 0.25)',
      },
    },
  },
});
