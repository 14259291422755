import { styled } from 'utils/styles';
import { Box } from 'utils/shared/components';

export const Wrapper = styled(Box, {
  d: 'grid',
  gridAutoFlow: 'column',
  gridTemplateAreas: `"left center right"
  "footer footer footer"`,
  gridTemplateColumns: 'auto 1fr auto',
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        border: '1px solid $blue',
      },
    },
    disabled: {
      true: {
        bg: '$gray5',
        pointerEvents: 'none',
      },
    },
    type: {
      error: {
        border: '1px solid $red!important',
      },
      default: {
        border: 0,
      },
    },
    alignItems: {
      start: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'end',
      },
    },
  },
  defaultVariants: {
    alignItems: 'start',
    type: 'default',
  },
});

export const ContentWrapper = styled('div', {
  gridArea: 'center',
  d: 'grid',
  ai: 'start',
});

export const Aside = styled('div', {
  d: 'grid',
  ai: 'start',
  '& svg': {
    width: '20px',
    '& path': {
      fill: '$black',
    },
  },

  '&.left': {
    mr: '12px',
    gridArea: 'left',
  },

  '&.right': {
    ml: '12px',
    gridArea: 'right',
  },

  variants: {
    alignment: {
      start: {
        ai: 'start',
      },
      center: {
        ai: 'center',
      },
      end: {
        ai: 'end',
      },
    },
  },
});

export const AsideContent = styled('div', {
  d: 'grid',
  gridAutoFlow: 'column',
  ai: 'center',
  gap: '4px',
  variants: {
    disabled: {
      true: {
        '& svg path': {
          fill: '$gray30',
        },
      },
    },
    selected: {
      true: {
        '& svg path': {
          fill: '$green',
        },
      },
    },
    type: {
      error: {
        color: '$red',
        svg: {
          path: {
            fill: '$red',
          },
        },
      },
      default: {
        color: 'inherit',
      },
    },
  },
  defaultVariants: {
    type: 'default',
  },
});

export const Label = styled('span', {
  typo: 'heading2',
  color: '$black',
  textTransform: 'lowercase',
  variants: {
    type: {
      primary: {
        color: '$primary',
      },
      success: {
        color: '$green',
      },
      error: {
        color: '$error',
      },
      alert: {
        color: '$alert',
      },
      info: {
        color: '$info',
      },
      disabled: {
        color: '$gray30',
      },
    },
  },
});

export const FooterWrapper = styled('div', {
  gridArea: 'footer',
  d: 'grid',
  ai: 'center',
  mt: '$spacing8',
  mb: '$spacing8',
  '&:empty': {
    margin: 0,
  },
});
