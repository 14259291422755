import * as S from './styles';

import { ComponentProps } from 'react';
import { Value } from '../Value';
import { isFree } from 'utils/functions/currency';

interface IPriceProps extends ComponentProps<typeof S.Container> {
  children?: string | number;
  prefix?: string;
  currency?: string;
  label?: string;
}

export const Price = ({ label, children, prefix, currency = 'R$', ...props }: IPriceProps) => {
  const isNumber = (value) => typeof value === 'number';
  const isString = (value) => typeof value === 'string';
  const isValidNumber = (value) => Number.isFinite(value);

  const isNotFreeLabels = ['Total', 'Vale Troca', 'Cartão Presente'];

  const handleNumbers = (value) => (isValidNumber(value) ? value : 0);

  let displayedValue = children;
  displayedValue = isNumber(displayedValue) ? handleNumbers(displayedValue) : displayedValue;
  displayedValue = isFree(displayedValue) && !isNotFreeLabels.includes(label ? label : '') ? 'GRÁTIS' : displayedValue;

  return (
    <S.Container {...props} data-clarity-unmask="true">
      {prefix && `${prefix} `}
      {isString(displayedValue) ? (
        displayedValue
      ) : (
        <>
          {currency} <Value value={displayedValue as number} />
        </>
      )}
    </S.Container>
  );
};
