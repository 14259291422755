/**
 * Remove termos duplicados.
 * @param list Lista de termos em string.
 * @returns Lista sem termos duplicados.
 */
export function dedupe(list: string[] = []) {
  return [...new Set(list)];
}

/**
 * Função que remove termos duplicados de textos que possuí padrão de divisor.
 * @param text Texto que contém algum padrão de separação.
 * @param splitter Divisor de texto.
 * @returns Texto sem termos duplicados.
 */
export function removeDuplicateTerm(text: string, splitter = ':') {
  return dedupe(text?.toLocaleLowerCase().split(splitter))?.join(splitter);
}
