import { ActionType, CheckoutType, RCHLOResponse } from './types';
import {
  AsyncCouponPayload,
  AsyncDeleteCouponPayload,
  AsyncExchangeVoucherPayload,
  AsyncGiftCardPayload,
  AsyncPutPaymentPayload,
  CSATPayload,
  OrderPayload,
  PostPaymentPlanPayload,
} from './../engine/types';
import {
  analyticsCouponButtonCallback,
  analyticsCreditCardCallback,
  analyticsFinishButtonCallback,
} from 'utils/functions/analytics';

import { useSelectContentDispatch } from '@estore/gtm-v4';

import {
  engineDeleteCoupon,
  engineDeleteExchangeVoucher,
  engineDeleteGiftCard,
  enginePostCSAT,
  enginePostCoupon,
  enginePostExchangeVoucher,
  enginePostGiftCard,
  enginePostOrder,
  enginePostPaymentPlan,
  enginePutPayment,
} from './../engine/index';

import { AuthTokenPayload } from 'store/engine/types';
import { CHECKOUT_ERRORS } from 'modules/Checkout/helpers';
import { GlobalTypes } from './global';
import {
  deleteCoupon,
  deleteCouponError,
  deleteCouponSuccess,
  deleteExchangeVoucher,
  deleteExchangeVoucherError,
  deleteExchangeVoucherSuccess,
  deleteGiftCard,
  deleteGiftCardError,
  deleteGiftCardSuccess,
  postCoupon,
  postCouponError,
  postCouponSuccess,
  postCsat,
  postCsatError,
  postCsatSuccess,
  postExchangeVoucher,
  postExchangeVoucherError,
  postExchangeVoucherSuccess,
  postGiftCard,
  postGiftCardError,
  postGiftCardSuccess,
  postOrder,
  postOrderError,
  postOrderSuccess,
  postPaymentPlan,
  postPaymentPlanError,
  postPaymentPlanSuccess,
  putPayment,
  putPaymentError,
  putPaymentSuccess,
} from './checkout/actions';
import { EventSelectContentCouponParams, useAddPaymentInfo, useExceptionCheckout, useSuccess } from 'libs';
import { useGtagCsat } from 'libs/gtag/event-csat';
import { sanitizeText } from 'utils/functions/analytics/helpers';

export const Types = {
  GET_CHECKOUT: 'checkout/GET_CHECKOUT',

  POST_COUPON: 'checkout/POST_COUPON',
  POST_COUPON_SUCCESS: 'checkout/POST_COUPON_SUCCESS',
  POST_COUPON_ERROR: 'checkout/POST_COUPON_ERROR',

  DELETE_COUPON: 'checkout/DELETE_COUPON',
  DELETE_COUPON_SUCCESS: 'checkout/DELETE_COUPON_SUCCESS',
  DELETE_COUPON_ERROR: 'checkout/DELETE_COUPON_ERROR',

  POST_EXCHANGE_VOUCHER: 'checkout/POST_EXCHANGE_VOUCHER',
  POST_EXCHANGE_VOUCHER_SUCCESS: 'checkout/POST_EXCHANGE_VOUCHER_SUCCESS',
  POST_EXCHANGE_VOUCHER_ERROR: 'checkout/POST_EXCHANGE_VOUCHER_ERROR',

  DELETE_EXCHANGE_VOUCHER: 'checkout/DELETE_EXCHANGE_VOUCHER',
  DELETE_EXCHANGE_VOUCHER_SUCCESS: 'checkout/DELETE_EXCHANGE_VOUCHER_SUCCESS',
  DELETE_EXCHANGE_VOUCHER_ERROR: 'checkout/DELETE_EXCHANGE_VOUCHER_ERROR',

  POST_GIFT_CARD: 'checkout/POST_GIFT_CARD',
  POST_GIFT_CARD_SUCCESS: 'checkout/POST_GIFT_CARD_SUCCESS',
  POST_GIFT_CARD_ERROR: 'checkout/POST_GIFT_CARD_ERROR',

  DELETE_GIFT_CARD: 'checkout/DELETE_GIFT_CARD',
  DELETE_GIFT_CARD_SUCCESS: 'checkout/DELETE_GIFT_CARD_SUCCESS',
  DELETE_GIFT_CARD_ERROR: 'checkout/DELETE_GIFT_CARD_ERROR',

  RESET_COUPON_STATUS: 'checkout/RESET_COUPON_STATUS',

  SET_PAYMENT_METHOD: 'checkout/SET_PAYMENT_METHOD',

  POST_PAYMENT_PLAN: 'checkout/POST_PAYMENT_PLAN',
  POST_PAYMENT_PLAN_SUCCESS: 'checkout/POST_PAYMENT_PLAN_SUCCESS',
  POST_PAYMENT_PLAN_ERROR: 'checkout/POST_PAYMENT_PLAN_ERROR',

  PUT_PAYMENT: 'checkout/PUT_PAYMENT',
  PUT_PAYMENT_SUCCESS: 'checkout/PUT_PAYMENT_SUCCESS',
  PUT_PAYMENT_ERROR: 'checkout/PUT_PAYMENT_ERROR',

  POST_ORDER: 'checkout/POST_ORDER',
  POST_ORDER_SUCCESS: 'checkout/POST_ORDER_SUCCESS',
  POST_ORDER_ERROR: 'checkout/POST_ORDER_ERROR',

  GET_ACCESS_TOKEN: 'checkout/GET_ACCESS_TOKEN',
  GET_ACCESS_TOKEN_SUCCESS: 'checkout/GET_ACCESS_TOKEN_SUCCESS',
  GET_ACCESS_TOKEN_ERROR: 'checkout/GET_ACCESS_TOKEN_ERROR',

  POST_CSAT: 'csat/POST_CSAT',
  POST_CSAT_SUCCESS: 'csat/POST_CSAT_SUCCESS',
  POST_CSAT_ERROR: 'csat/POST_CSAT_ERROR',

  RESET_ORDER_ERROR: 'checkout/RESET_ORDER_ERROR',

  SET_CREDIT_CARD_INFO: 'checkout/SET_CREDIT_CARD_INFO',

  RESET_ACCESS_TOKEN: 'checkout/RESET_ACCESS_TOKEN',

  RESET_PAYMENT_METHOD_ERROR: 'checkout/RESET_PAYMENT_METHOD_ERROR',

  RESET_PAYMENT_METHOD: 'checkout/RESET_PAYMENT_METHOD',

  RESET_CART_AND_SHIPPING: 'checkout/RESET_CART_AND_SHIPPING',

  RESET_CHECKOUT_STATUS: 'checkout/RESET_CHECKOUT_STATUS',

  RESET_PROMOTION: 'checkout/RESET_PROMOTION',
};

const initialState: CheckoutType = {
  status: 'idle',
  coupom: {
    status: 'idle',
    promotion: null,
  },
  promotion: null,
  paymentInfo: [],
  paymentMethod: undefined,
  creditCardInfo: null,
  installmentInfo: null,
  order: null,
  orderErrorType: null,
  exchangeVoucher: {
    status: 'idle',
    voucher: null,
  },
  giftCard: {
    status: 'idle',
    cardNumber: null,
  },
  coupons: [],
};

export const reducer = (state = initialState, action: ActionType): CheckoutType => {
  switch (action.type) {
    // GET_CHECKOUT
    case Types.GET_CHECKOUT:
      return {
        ...state,
        ...action.payload,
        status: state.status,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          ...action.payload?.exchangeVoucher,
          voucher: state.exchangeVoucher?.voucher,
        },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        giftCard: {
          ...state.giftCard,
          ...action.payload?.giftCard,
          cardNumber: state.giftCard?.cardNumber,
        },
      };

    // POST_COUPON
    case Types.POST_COUPON:
      return {
        ...state,
        coupom: {
          ...state.coupom,
          status: 'pending',
        },
      };
    case Types.POST_COUPON_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.data.exchangeVoucher },
        giftCard: { ...state.giftCard, ...action.payload.data.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupom: {
          ...state.coupom,
          status: 'success',
          promotion: {
            progressiveDiscountValue: action.payload.data.couponAmount,
            coupom: {
              promotionalCoupon: action.payload.data.coupon,
            },
          },
        },
      };
    case Types.POST_COUPON_ERROR:
      return {
        ...state,
        ...action?.payload,
        exchangeVoucher: { ...state.exchangeVoucher },
        giftCard: { ...state.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        promotion: null,
        coupom: {
          status: 'error',
          promotion: {
            progressiveDiscountValue: action?.payload?.couponAmount,
            coupom: {
              promotionalCoupon: action?.payload?.coupon,
            },
          },
        },
      };

    // DELETE_COUPON
    case Types.DELETE_COUPON:
      return {
        ...state,
        coupom: {
          ...state.coupom,
          status: 'pending',
        },
      };
    case Types.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.exchangeVoucher },
        giftCard: { ...state.giftCard, ...action.payload.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupon: null,
        coupom: {
          ...state.coupom,
          status: 'success',
          promotion: null,
          deleted: true,
        },
      };
    case Types.DELETE_COUPON_ERROR:
      return {
        ...state,
        ...action?.payload,
        exchangeVoucher: { ...state.exchangeVoucher },
        giftCard: { ...state.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupom: {
          ...state.coupom,
          status: 'error',
          deleted: false,
        },
      };

    // POST EXCHANGE VOUCHER
    case Types.POST_EXCHANGE_VOUCHER:
      return {
        ...state,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          status: 'pending',
        },
        giftCard: { ...state.giftCard },
      };
    case Types.POST_EXCHANGE_VOUCHER_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        exchangeVoucher: {
          ...action.payload.data.exchangeVoucher,
          voucher: action.payload.voucher,
          status: 'success',
        },
        giftCard: { ...state.giftCard, ...action.payload.data.giftCard },
      };
    case Types.POST_EXCHANGE_VOUCHER_ERROR: {
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: {
          ...action.payload.exchangeVoucher,
          status: 'error',
        },
        giftCard: { ...state.giftCard },
      };
    }

    // DELETE EXCHANGE VOUCHER
    case Types.DELETE_EXCHANGE_VOUCHER:
      return {
        ...state,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          status: 'pending',
        },
        giftCard: { ...state.giftCard },
      };
    case Types.DELETE_EXCHANGE_VOUCHER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: {
          ...action.payload.exchangeVoucher,
          status: 'success',
        },
        giftCard: { ...state.giftCard, ...action.payload.giftCard },
      };
    case Types.DELETE_EXCHANGE_VOUCHER_ERROR: {
      return {
        ...state,
        exchangeVoucher: {
          ...action.payload,
          status: 'error',
        },
        giftCard: { ...state.giftCard },
      };
    }

    // POST GIFT CARD
    case Types.POST_GIFT_CARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          status: 'pending',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    case Types.POST_GIFT_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        giftCard: {
          ...action.payload.data.giftCard,
          cardNumber: action.payload.cardNumber,
          status: 'success',
        },
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.data.exchangeVoucher },
      };
    case Types.POST_GIFT_CARD_ERROR: {
      return {
        ...state,
        ...action.payload,
        giftCard: {
          ...action.payload.giftCard,
          status: 'error',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    }

    // DELETE EXCHANGE VOUCHER
    case Types.DELETE_GIFT_CARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          status: 'pending',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    case Types.DELETE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        giftCard: {
          ...action.payload.giftCard,
          status: 'success',
        },
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.exchangeVoucher },
      };
    case Types.DELETE_GIFT_CARD_ERROR: {
      return {
        ...state,
        giftCard: {
          ...action.payload,
          status: 'error',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    }

    // SET_PAYMENT_METHOD
    case Types.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };

    // POST_PAYMENT_PLAN
    case Types.POST_PAYMENT_PLAN:
      return { ...state, status: 'pending', creditCardInfo: action.payload };
    case Types.POST_PAYMENT_PLAN_SUCCESS:
      return { ...state, status: 'success', paymentInfo: action.payload };
    case Types.POST_PAYMENT_PLAN_ERROR:
      return { ...state, status: 'error' };

    // PUT_PAYMENT_PLAN
    case Types.PUT_PAYMENT:
      return { ...state, status: 'pending' };
    case Types.PUT_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: { ...state.exchangeVoucher, status: 'idle' },
        giftCard: { ...state.giftCard, status: 'idle' },
        installmentInfo: { quantity: action.payload.installmentId, amount: action.payload.installmentAmount },
        coupom: {
          ...state.coupom,
        },
        status: 'success',
      };
    case Types.PUT_PAYMENT_ERROR:
      return { ...state, status: 'error' };

    // POST_ORDER
    case Types.POST_ORDER:
      return { ...state, status: 'pending', orderErrorType: null };
    case Types.POST_ORDER_SUCCESS:
      return { ...state, status: 'success', order: action.payload.response, showCsat: action.payload.showCsat };
    case Types.POST_ORDER_ERROR:
      return { ...state, status: 'error', orderErrorType: action.payload };

    case Types.SET_CREDIT_CARD_INFO:
      return { ...state, ...action.payload };

    // ACCESS_TOKEN
    case Types.GET_ACCESS_TOKEN:
      return { ...state, status: 'pending' };
    case Types.GET_ACCESS_TOKEN_SUCCESS:
      return { ...state, status: 'success', accessToken: action.payload };
    case Types.GET_ACCESS_TOKEN_ERROR:
      return { ...state, status: 'error' };

    case Types.RESET_ACCESS_TOKEN:
      return { ...state, accessToken: null };

    case Types.RESET_PAYMENT_METHOD_ERROR:
      return { ...state, orderErrorType: null, status: 'idle' };

    // CSAT
    case Types.POST_CSAT:
      return { ...state, status: 'pending' };
    case Types.POST_CSAT_SUCCESS:
      return { ...state, status: 'success', showCsat: false };
    case Types.POST_CSAT_ERROR:
      return { ...state, status: 'error', showCsat: false };

    case Types.RESET_ORDER_ERROR:
      return { ...state, orderErrorType: null };

    // RESET_PAYMENT_METHOD
    case Types.RESET_PAYMENT_METHOD:
      return { ...state, paymentMethod: undefined };

    // RESET_CHECKOUT_STATUS
    case Types.RESET_CHECKOUT_STATUS:
      return {
        ...state,
        status: 'idle',
        exchangeVoucher: { ...state.exchangeVoucher, status: 'idle' },
        giftCard: { ...state.giftCard, status: 'idle' },
      };

    // RESET_PROMOTION
    case Types.RESET_PROMOTION:
      return { ...state, promotion: null };

    // RESET CART AND SHIPPING
    case Types.RESET_CART_AND_SHIPPING:
      return {
        ...state,
        cart: undefined,
        shippingGroups: undefined,
      };

    // RESET COUPON
    case Types.RESET_COUPON_STATUS:
      return {
        ...state,
        coupom: {
          ...state.coupom,
          status: 'idle',
        },
      };

    case GlobalTypes.GLOBAL_STATE:
      return { ...state, ...action.payload };

    // RESET_CHECKOUT_STATE
    case GlobalTypes.GLOBAL_RESET:
      return { ...initialState };

    default:
      return state;
  }
};

const asyncPostCoupon =
  ({ cart_id, ...rest }: AsyncCouponPayload) =>
  async (dispatch: any) => {
    dispatch(postCoupon());
    try {
      const response = await enginePostCoupon({ ...rest });
      const { data } = response;

      analyticsCouponButtonCallback(rest.coupon, true);

      if (data.success) {
        useSuccess({
          description: 'cupom aplicado com sucesso',
          coupon_name: rest.coupon,
          cart_id,
        });
        return dispatch(postCouponSuccess(data.data));
      }
      throw response;
    } catch (e: any) {
      analyticsCouponButtonCallback(rest.coupon, false, 'Cupom inválido');
      useExceptionCheckout({
        description: sanitizeText(
          e?.response?.data?.errorMessages?.[0]?.message || e?.data?.errorMessages?.[0]?.message || 'Cupom inválido',
        ),
        fatal: false,
      });
      dispatch(postCouponError());
    }
  };

const asyncDeleteCoupon =
  ({ cart_id, coupon_name, ...rest }: AsyncDeleteCouponPayload) =>
  async (dispatch: any) => {
    dispatch(deleteCoupon());
    try {
      const response = await engineDeleteCoupon({ ...rest });
      const {
        data: { data, success },
      } = response;
      if (success) {
        useSelectContentDispatch<EventSelectContentCouponParams>({
          content_type: 'cupom removido com sucesso',
          coupon_name,
          flow: 'checkout:msite',
          cart_id,
        });
        return dispatch(deleteCouponSuccess(data));
      }
      throw response;
    } catch (error: any) {
      useExceptionCheckout({
        description: sanitizeText(
          error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message,
        ),
        fatal: false,
      });
      dispatch(deleteCouponError());
    }
  };

const asyncPostPaymentPlan = (payload: PostPaymentPlanPayload, signal?: AbortSignal) => async (dispatch: any) => {
  const creditCardInfo = {
    cardNumber: payload.cardNumber || null,
    cardHolderName: payload.cardHolderName || null,
    cardExpirationMonth: payload.cardExpirationMonth || null,
    cardExpirationYear: payload.cardExpirationYear || null,
    cardSecurityCode: payload.cardSecurityCode || null,
    cardBrand: payload.cardBrand || null,
    cardDocument: payload.cardDocument || null,
    singleUse: payload.singleUse,
  };
  dispatch(postPaymentPlan(creditCardInfo));
  try {
    const response = await enginePostPaymentPlan(payload, signal);
    const { data } = response;
    if (data.success) {
      analyticsCreditCardCallback(true);
      return dispatch(postPaymentPlanSuccess(data.data));
    }

    throw response;
  } catch (error: any) {
    let description = error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message;
    if (!description) {
      description = 'Problema ao validar cartão de crédito';
    }
    useExceptionCheckout({
      description,
      fatal: false,
    });
    analyticsCreditCardCallback(false, description);
    dispatch(postPaymentPlanError());
  }
};

const asyncPutPayment =
  (
    {
      cart,
      currentPaymentMethod,
      qr_code,
      user_id,
      registration_id,
      store,
      coupon,
      isSharedBag,
      ...rest
    }: AsyncPutPaymentPayload,
    onSuccess?: (data: RCHLOResponse<unknown>) => void,
    onError?: (error?: unknown) => void,
    signal?: AbortSignal,
  ) =>
  async (dispatch: any) => {
    dispatch(putPayment({ ...rest }));
    try {
      const response = await enginePutPayment({ ...rest }, signal);
      const { data } = response;

      onSuccess?.(data);

      if (!data.success) {
        throw response;
      }
      const paymentData = { ...data.data, ...rest };
      useAddPaymentInfo({
        cart,
        currentPaymentMethod,
        value: cart.total,
        coupon,
        qr_code,
        user_id,
        registration_id,
        store,
        isSharedBag,
      });
      return dispatch(putPaymentSuccess(paymentData));
    } catch (error: any) {
      onError?.(error);
      dispatch(putPaymentError());
      useExceptionCheckout({
        description: sanitizeText(
          error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message,
        ),
        fatal: false,
      });
    }
  };

const asyncPostOrder = (payload: OrderPayload, signal?: AbortSignal) => async (dispatch: any) => {
  dispatch(postOrder());
  try {
    const response = await enginePostOrder(payload, signal);
    const { data, status } = response;
    if (status === 200) {
      analyticsFinishButtonCallback(true);
      return dispatch(postOrderSuccess(data.data));
    }

    throw response;
  } catch (error: any) {
    let description = error?.response?.data?.errorMessages?.[0]?.message || error?.data?.errorMessages?.[0]?.message;
    if (!description) {
      description = 'Problema ao processar pagamento';
    }
    analyticsFinishButtonCallback(false, description);
    dispatch(
      postOrderError(
        error?.response?.status || error?.status || CHECKOUT_ERRORS.UNEXPECTED,
        error?.response?.data || error?.data,
      ),
    );
  }
};

const asyncPostCsat = (payload: CSATPayload) => async (dispatch: any) => {
  const { useExceptionCsat } = useGtagCsat();
  dispatch(postCsat());
  try {
    const response = await enginePostCSAT(payload);
    if (response) return dispatch(postCsatSuccess());
    throw response;
  } catch {
    useExceptionCsat();
    dispatch(postCsatError());
  }
};

const asyncPostExchangeVoucher =
  ({ qr_code, cart, user_id, registration_id, store, coupon, isSharedBag, ...rest }: AsyncExchangeVoucherPayload) =>
  async (dispatch: any) => {
    dispatch(postExchangeVoucher());
    try {
      const response = await enginePostExchangeVoucher({ ...rest });
      const { data, status } = response;
      if (status === 200) {
        useSelectContentDispatch({
          content_type: 'Insira um vale troca:aplicar',
          flow: 'checkout:msite',
        });
        useAddPaymentInfo({
          cart,
          currentPaymentMethod: 'ExchangeVoucher',
          value: cart.total,
          coupon,
          qr_code,
          user_id,
          registration_id,
          store,
          isSharedBag,
        });
        return dispatch(postExchangeVoucherSuccess(data.data, rest.exchangeVoucherCode));
      }
      throw response;
    } catch (error: any) {
      let description = error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message;
      if (!description) {
        description = 'Oops! Vale troca inativo ou indisponível';
      }
      useExceptionCheckout({
        description: sanitizeText(description),
        fatal: false,
      });
      dispatch(
        postExchangeVoucherError(
          error?.status || error?.response?.status || CHECKOUT_ERRORS.UNEXPECTED,
          error?.data || error?.response?.data,
        ),
      );
    }
  };

const asyncDeleteExchangeVoucher = (payload: AuthTokenPayload) => async (dispatch: any) => {
  dispatch(deleteExchangeVoucher());
  try {
    const response = await engineDeleteExchangeVoucher(payload);
    const { data, status } = response;
    if (status === 200) {
      return dispatch(deleteExchangeVoucherSuccess(data.data));
    }
    throw response;
  } catch (error: any) {
    error?.response;
    let description = error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message;
    if (!description) {
      description = 'falha ao remover vale troca';
    }
    useExceptionCheckout({
      description: sanitizeText(description),
      fatal: false,
    });
    dispatch(
      deleteExchangeVoucherError(
        error?.status || error?.response?.status !== 200 || CHECKOUT_ERRORS.UNEXPECTED,
        error?.data || error?.response?.data,
      ),
    );
  }
};

const asyncPostGiftCard =
  ({ qr_code, cart, user_id, registration_id, store, coupon, isSharedBag, ...rest }: AsyncGiftCardPayload) =>
  async (dispatch: any) => {
    dispatch(postGiftCard());
    try {
      const response = await enginePostGiftCard({ ...rest });
      const { data, status } = response;
      if (status === 200) {
        useSelectContentDispatch({
          content_type: 'Insira um cartao presente:aplicar',
          flow: 'checkout:msite',
        });
        useAddPaymentInfo({
          cart,
          currentPaymentMethod: 'GiftCard',
          value: cart.total,
          coupon,
          qr_code,
          user_id,
          registration_id,
          store,
          isSharedBag,
        });
        return dispatch(postGiftCardSuccess(data.data, rest?.cardNumber));
      }
      throw response;
    } catch (error: any) {
      let description = error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message;
      if (!description) {
        description = 'Vale presente inativo ou indisponível';
      }

      useExceptionCheckout({
        description: sanitizeText(description),
        fatal: false,
      });

      dispatch(
        postGiftCardError(
          error?.status || error?.response?.status || CHECKOUT_ERRORS.UNEXPECTED,
          error?.data || error?.response?.data,
        ),
      );
    }
  };

const asyncDeleteGiftCard = (payload: AuthTokenPayload) => async (dispatch: any) => {
  dispatch(deleteGiftCard());
  try {
    const response = await engineDeleteGiftCard(payload);
    const { data, status } = response;
    if (status === 200) {
      return dispatch(deleteGiftCardSuccess(data.data));
    }
    throw response;
  } catch (error: any) {
    let description = error?.data?.errorMessages?.[0]?.message || error?.response?.data?.errorMessages?.[0]?.message;
    if (!description) {
      description = 'falha ao remover cartão presente';
    }
    useExceptionCheckout({
      description: sanitizeText(description),
      fatal: false,
    });
    dispatch(
      deleteGiftCardError(
        error?.status || error?.response?.status || CHECKOUT_ERRORS.UNEXPECTED,
        error?.data || error?.response?.data,
      ),
    );
  }
};

export {
  asyncPostCoupon,
  asyncDeleteCoupon,
  asyncPostPaymentPlan,
  asyncPutPayment,
  asyncPostOrder,
  asyncPostCsat,
  asyncPostExchangeVoucher,
  asyncDeleteExchangeVoucher,
  asyncPostGiftCard,
  asyncDeleteGiftCard,
};
