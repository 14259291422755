export const GlobalTypes = {
  GLOBAL_RESET: 'GLOBAL/RESET',
  GLOBAL_STATE: 'GLOBAL/STATE',
};

export const globalResetState = () => ({
  type: GlobalTypes.GLOBAL_RESET,
});

export const globalState = (payload) => ({
  type: GlobalTypes.GLOBAL_STATE,
  payload,
});
