import * as S from './TextInput.styles';

import { ReactNode, forwardRef } from 'react';

import type { ComponentProps } from 'react';

export interface ITextInputProps extends ComponentProps<typeof S.Input> {
  id: string;
  message?: any;
  messageType?: 'info' | 'warning' | 'error';
  afterAdornment?: ReactNode;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  ({ id, placeholder, message, value, messageType, disabled, afterAdornment, ...rest }, ref) => {
    return (
      <S.Wrapper disabled={disabled}>
        <S.InputWrapper variant={message ? messageType : undefined} disabled={disabled}>
          {(value || (ref as React.MutableRefObject<HTMLInputElement | undefined>)?.current?.value) &&
            id !== 'register-form-document-input' &&
            id !== 'schedule' && <S.Label htmlFor={id}>{placeholder}</S.Label>}
          <S.InputContainer>
            <S.Input ref={ref} id={id} placeholder={placeholder} readOnly={disabled} value={value} {...rest} />
            {afterAdornment}
          </S.InputContainer>
        </S.InputWrapper>
        {!disabled && message && (
          <S.MessageWrapper>
            <S.Message variant={messageType}>{message}</S.Message>
          </S.MessageWrapper>
        )}
      </S.Wrapper>
    );
  },
);

TextInput.displayName = 'TextInput';
