export const formatPrice = (unformattedPrice: number) => {
  const priceFormat = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

  return priceFormat.format(unformattedPrice);
};

export const isFree = (value?: string | number) => {
  const acceptedValues = ['R$ 0,00', '0,00', 'R$ 0', '0', 0, 'R$0,00', 'R$0'];
  return acceptedValues.some((acceptedValue) => acceptedValue === value || 0 || undefined || null);
};
