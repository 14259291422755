import { creditCardType, cctGetTypeInfo, CARD_TYPES, RIACHUELO_CARD_TYPE } from 'libs/credit-card-types';
import { CreditCardType } from 'libs/credit-card-types/types';
import { RCHLO_CARD_CVV, RCHLO_CARD_EXPIRATION_MONTH, RCHLO_CARD_EXPIRATION_YEAR } from 'utils/config/environments';
import { cleanCardNumber } from 'utils/functions/text';
import { isCreditCardNumberValid } from '../validations';
import { CardTypes } from 'utils/shared/components/PaymentCard/constants';

export const CREDIT_CARD_VALIDATION_MESSAGES = {
  INVALID_CARD_NUMBER: 'Número do cartão inválido',
  INVALID_CARD_HOLDER_NAME: 'Por favor, digite o nome conforme consta em seu cartão',
  INVALID_CVV: 'CVV inválido',
  INVALID_DATE: 'Data inválida',
  INVALID_CARD_DOCUMENT: 'CPF inválido',
  INVALID_PAYMENT_PLAN: 'Selecione o número de parcelas para continuar',
};

const getRiachueloCardType = () => RIACHUELO_CARD_TYPE;

const DEFAULT_FORMAT_TYPE = CARD_TYPES.visa as CardTypes;

const getWhitelistedCards = (cards: CreditCardType[]) => {
  const cardsWhitelist = Object.values(CARD_TYPES);
  return cards.filter((card) => cardsWhitelist.includes(card.type as CardTypes));
};

export const getCreditCardTypeByNumber = (number: string) => {
  if (number.startsWith('02')) {
    return [getRiachueloCardType()];
  }

  return getWhitelistedCards(creditCardType(number));
};

export const getCreditCardTypeByType = (type: CardTypes) => {
  if (type === CARD_TYPES.riachuelo) {
    return getRiachueloCardType();
  }
  return cctGetTypeInfo(type);
};

export const prettyCardNumber = (cardNumber: string, cardType: CardTypes) => {
  const card = getCreditCardTypeByType(cardType);

  if (card) {
    const offsets = [0, ...card.gaps, cardNumber.length];
    const components: string[] = [];

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], cardNumber.length);
      components.push(cardNumber.substring(start, end));
    }

    return components.join(' ');
  }

  return cardNumber;
};

export const showCreditCardMask = (cardNumber: string, cardType: CardTypes = DEFAULT_FORMAT_TYPE) => {
  const { lengths } = getCreditCardTypeByType(cardType);
  const numberMask = cardNumber.padEnd(Math.min(...lengths), '∗');

  return prettyCardNumber(numberMask, cardType);
};

export const showCVVMask = (cvv: string, cardType: CardTypes = DEFAULT_FORMAT_TYPE) => {
  const { code } = getCreditCardTypeByType(cardType);

  const cvvMask = cvv?.padEnd(code.size, '∗');

  return cvvMask;
};

export const isNonBrandedRiachueloCard = (type: CardTypes) => type === CARD_TYPES.riachuelo;
export const isBrandedRiachueloCard = (type: CardTypes) =>
  [CARD_TYPES['riachuelo-master'], CARD_TYPES['riachuelo-visa']].includes(type);
export const isRiachueloCard = (type: CardTypes) => isNonBrandedRiachueloCard(type) || isBrandedRiachueloCard(type);

export const getCreditCardInputMask = (cardType: CardTypes = DEFAULT_FORMAT_TYPE) => {
  const { lengths } = getCreditCardTypeByType(cardType);

  const length = Math.min(...lengths);
  const mask = ''.padEnd(length, '9');

  return prettyCardNumber(mask, cardType);
};

export const getNonBrandedRiachueloCardDefaultInfo = () => ({
  cardSecurityCode: RCHLO_CARD_CVV,
  cardExpirationMonth: RCHLO_CARD_EXPIRATION_MONTH,
  cardExpirationYear: RCHLO_CARD_EXPIRATION_YEAR,
});

export enum RchloInitialsCreditCardNumbers {
  Master = '536518',
  Visa = '482425',
  Riachuelo = '02',
  TsysMaster = '523679',
  TsysVisa = '407703',
  TsysVisaMidway = '405860',
}

export const isRiachueloPlCardByNumber = (cardNumber: string) => {
  const { Riachuelo } = RchloInitialsCreditCardNumbers;
  return cardNumber?.startsWith(Riachuelo);
};

export const isRiachueloBrandedCardByNumber = (cardNumber: string) => {
  const { Master, Visa, TsysMaster, TsysVisa, TsysVisaMidway } = RchloInitialsCreditCardNumbers;
  return (
    cardNumber?.startsWith(Master) ||
    cardNumber?.startsWith(Visa) ||
    cardNumber?.startsWith(TsysMaster) ||
    cardNumber?.startsWith(TsysVisaMidway) ||
    cardNumber?.startsWith(TsysVisa)
  );
};

export const isRchloCardByNumber = (cardNumber: string) => {
  return isRiachueloBrandedCardByNumber(cardNumber) || isRiachueloPlCardByNumber(cardNumber);
};

export const validateCreditCardNumber = (creditCardNumber: string) => {
  const cleanCreditCardNumber = cleanCardNumber(creditCardNumber);
  const isRchloCard = isRchloCardByNumber(cleanCreditCardNumber);

  return isCreditCardNumberValid(cleanCreditCardNumber, isRchloCard);
};

export const isCardToken = (cardNumber: string, maskChar = '*') => {
  const maskLength = Math.max(cardNumber.length - 10, 0);
  return cardNumber.slice(6, -4) === maskChar.repeat(maskLength);
};
