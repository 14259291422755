import { colors, borderWidths, radii, fonts, space, shadows } from '../tokens';

export const base = {
  borderWidths,
  colors,
  fonts,
  radii,
  space,
  shadows,
};
