import { styled } from 'utils/styles';

export const Container = styled('div', {
  d: 'flex',
  ai: 'center',
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
});

export const Label = styled('span', {
  typo: 'heading1',
  mr: 10,
});

export const Selector = styled('div', {
  d: 'flex',
});

export const Button = styled('button', {
  appearance: 'none',
  border: 'none',
  cursor: 'pointer',
  p: 0,
  width: '18px',
  height: '18px',

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});

export const Counter = styled('input', {
  background: 'transparent',
  border: '0 none',
  d: 'flex',
  p: '0 5px',
  width: '46px',
  fontSize: '16px',
  textAlign: 'center',
  '&:focus:read-only': {
    outline: 'none',
  },
  typo: 'heading1',
});
