const grays = {
  gray75: '#261E1E',
  gray65: '#757575',
  gray30: '#B2B2B2',
  gray10: '#E6E6E6',
  gray5: '#F4F4F4',
  gray6: '#EEEEEE',
};

const greens = {
  green: '#008844',
  green50: '#80C3A1',
  greenLight: '#E5FFF2',
};

const blues = {
  blue: '#0071A3',
  blueLight: '#E6F7FF',
};

const reds = {
  red: '#C6352F',
  redLight: '#FFE8E5',
};

const yellows = {
  yellow: '#8E6415',
  yellowLight: '#FFF9EF',
};

export const colors = {
  black: '#141414',
  white: '#FFFFFF',
  ...grays,
  ...greens,
  ...blues,
  ...reds,
  ...yellows,
  text: '$black',
  headings: '$gray65',
  background: '$gray5',
  shadow: '$gray10',
  primary: '$black',
  error: '$red',
  errorLight: '$redLight',
  alert: '$yellow',
  alertLight: '$yellowLight',
  info: '$blue',
  infoLight: '$blueLight',
  warning: '$yellow',
  warningLight: '$yellowLight',
  success: '$green',
  successLight: '$greenLight',
};
