import { type Variation } from '@estore/types';

export enum VARIATION_KEYS {
  SIZE = 'size',
  COLOR = 'color',
}

/**
 * Função para recuperar a variação do produto pela chave.
 * @param variations Lista de variações do produto.
 * @param key Nome da chave
 * @param prop Nome da propriedade que irá recuperar o valor.
 * @returns Valor, Objeto ou Indefinido.
 */
export function getVariationByKey(
  variations: Variation[] = [],
  key: Lowercase<keyof typeof VARIATION_KEYS>,
  prop?: keyof Variation,
) {
  return prop
    ? variations.find((variation) => variation.key === key)?.[prop]
    : variations.find((variation) => variation.key === key);
}
