import { createContext, useContext } from 'react';

interface IPopoverContext {
  open: boolean;
  modal: boolean;
  triggerRef: React.RefObject<HTMLButtonElement>;
  onToggle: () => void;
  onOpenChange: (open: boolean) => void;
}

const PopoverContext = createContext<IPopoverContext | undefined>(undefined);

export const usePopover = () => {
  const context = useContext(PopoverContext);

  if (context === undefined) {
    throw new Error('usePopover must be used within a Popover');
  }

  return context;
};

export const PopoverProvider = ({ value, children }) => (
  <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
);
