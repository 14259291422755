import * as S from './styles';

import { Button } from '..';

export const ErrorGenericModal = ({ onClick, messages }) => {
  return (
    <S.ModalWrapper>
      {messages?.map((message, key) => (
        <p key={key}>{message}</p>
      ))}
      <Button color="secondary" onClick={onClick} style={{ marginTop: '20px' }}>
        Confirmar
      </Button>
    </S.ModalWrapper>
  );
};
