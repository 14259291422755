import React, { ReactNode, useCallback, useState, useRef, useLayoutEffect } from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Portal } from '../Portal';
import { PopoverProvider, usePopover } from './Popover.context';

import * as S from './Popover.styles';

type VerticalPosition = 'top' | 'center' | 'bottom';
type HorizontalPosition = 'left' | 'center' | 'right';

interface IVerticalPosition {
  vertical: VerticalPosition;
}

interface IHorizontalPosition {
  horizontal: HorizontalPosition;
}

interface IPoint {
  x: number;
  y: number;
}

interface IPosition extends IVerticalPosition, IHorizontalPosition {
  offset: IPoint;
}

interface IPopoverProps {
  children: ReactNode;
  initialOpenState: boolean;
  modal: boolean;
}

export const Popover = (props: IPopoverProps) => {
  const { children, initialOpenState, modal } = props;
  const [open, setOpen] = useState<boolean>(initialOpenState);
  const onToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [setOpen]);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [, forceUpdate] = React.useState({});
  useLayoutEffect(() => {
    forceUpdate({});
  }, []);

  const value = {
    open,
    modal,
    triggerRef,
    onToggle,
    onOpenChange: setOpen,
  };
  return <PopoverProvider value={value}>{children}</PopoverProvider>;
};

Popover.defaultProps = {
  open: false,
  modal: true,
};

interface IPopoverTriggerProps {
  children: ReactNode;
}

const PopoverTrigger = ({ children }: IPopoverTriggerProps) => {
  const { onToggle, triggerRef } = usePopover();

  const [, forceUpdate] = React.useState({});
  useLayoutEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <button
      onClick={() => {
        onToggle();
      }}
      ref={triggerRef}
      style={{
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
      }}
    >
      {children}
    </button>
  );
};

export interface IPopoverContentProps {
  anchorPosition?: Partial<IPosition>;
  contentAlignment?: Partial<IPosition>;
  hideOnClickOutside?: boolean;
  children: ReactNode;
}

const PopoverContent = (props: IPopoverContentProps) => {
  const {
    children,
    anchorPosition: anchorPositionProp,
    contentAlignment: contentAlignmentProp,
    hideOnClickOutside,
  } = props;
  const { open, modal, triggerRef, onOpenChange } = usePopover();

  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRect: any = triggerRef.current?.getBoundingClientRect();

  useOnClickOutside(contentRef, (event) => {
    if (hideOnClickOutside && triggerRef.current && !triggerRef.current?.contains(event.target as Node) && open) {
      onOpenChange(false);
    }
  });

  const anchorDefaultPosition: IPosition = {
    horizontal: 'left',
    vertical: 'bottom',
    offset: {
      x: 0,
      y: 0,
    },
  };
  const contentDefaultPosition: IPosition = {
    horizontal: 'left',
    vertical: 'top',
    offset: {
      x: 0,
      y: 0,
    },
  };

  const anchorPosition = {
    ...anchorDefaultPosition,
    ...anchorPositionProp,
    offset: {
      ...anchorDefaultPosition.offset,
      ...(anchorPositionProp?.offset || {}),
    },
  };

  const contentAlignment = {
    ...contentDefaultPosition,
    ...contentAlignmentProp,
    offset: {
      ...contentDefaultPosition.offset,
      ...(contentAlignmentProp?.offset || {}),
    },
  };

  const createPoint = (x, y) => ({ x, y });

  const getCoordinates = (position: IPosition, rect: DOMRect | undefined) => {
    if (!rect) return { x: 0, y: 0 };

    const { vertical, horizontal } = position;

    const coordinates = {
      top: {
        left: createPoint(rect.x, rect.y),
        center: createPoint(rect.x + rect.width / 2, rect.y),
        right: createPoint(rect.x + rect.width, rect.y),
      },
      center: {
        left: createPoint(rect.x, rect.y + rect.height / 2),
        center: createPoint(rect.x + rect.width / 2, rect.y + rect.height / 2),
        right: createPoint(rect.x + rect.width, rect.y + rect.height / 2),
      },
      bottom: {
        left: createPoint(rect.x, rect.y + rect.height),
        center: createPoint(rect.x + rect.width / 2, rect.y + rect.height),
        right: createPoint(rect.x + rect.width, rect.y + rect.height),
      },
    };

    const coords: IPoint = {
      x: coordinates[vertical][horizontal].x + position.offset.x + window.pageXOffset,
      y: coordinates[vertical][horizontal].y + position.offset.y + window.pageYOffset,
    };

    return coords;
  };

  const calculateTransform = (position, rect, anchorPoint) => {
    if (!rect) return anchorPoint;

    const { vertical, horizontal } = position;

    const coordinates = {
      top: {
        left: createPoint(anchorPoint.x, anchorPoint.y),
        center: createPoint(anchorPoint.x - rect.width / 2, anchorPoint.y),
        right: createPoint(anchorPoint.x - rect.width, anchorPoint.y),
      },
      center: {
        left: createPoint(anchorPoint.x, anchorPoint.y - rect.height / 2),
        center: createPoint(anchorPoint.x - rect.width / 2, anchorPoint.y - rect.height / 2),
        right: createPoint(anchorPoint.x - rect.width, anchorPoint.y - rect.height / 2),
      },
      bottom: {
        left: createPoint(anchorPoint.x, anchorPoint.y - rect.height),
        center: createPoint(anchorPoint.x - rect.width / 2, anchorPoint.y - rect.height),
        right: createPoint(anchorPoint.x - rect.width, anchorPoint.y - rect.height),
      },
    };

    const coords: IPoint = {
      x: coordinates[vertical][horizontal].x + position.offset.x,
      y: coordinates[vertical][horizontal].y + position.offset.y,
    };

    return coords;
  };

  const translateCoordinates = (point) => {
    return point ? `translate(${point.x}px, ${point?.y}px)` : undefined;
  };

  const anchorCoordinates = getCoordinates(anchorPosition, triggerRect);

  useLayoutEffect(() => {
    const contentEl: any = contentRef.current;
    const contentRect = contentEl?.getBoundingClientRect();

    if (contentRect) {
      const coordinates = calculateTransform(contentAlignment, contentRect, anchorCoordinates);
      contentEl.style.transform = translateCoordinates(coordinates);
    }
  }, [contentRef, open]);

  const PortalWrapper = modal ? Portal : React.Fragment;

  return <PortalWrapper>{open && <S.Content ref={contentRef}>{children}</S.Content>}</PortalWrapper>;
};

PopoverContent.defaultProps = {
  anchorPosition: {},
  contentAlignment: {},
};

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
