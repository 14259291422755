import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthType } from 'store/ducks/types';
import { styled, globalStyles } from '../utils/styles';
import { CybersourceProvider } from 'libs';
import { useMicrosoftClarity } from '@estore/react-clarity-js';

const Main = styled('div', {
  height: '100%',
});

const UnauthRoutes: React.FC = lazy(() => import('./UnauthRoutes'));
const AuthRoutes: React.FC = lazy(() => import('./AuthRoutes'));

const LoadingComponent: React.FC = () => <div />;

const Routes: React.FC = () => {
  const { isAuthenticated } = useSelector((state: { authCustomerState: AuthType }) => state.authCustomerState);
  globalStyles();

  const { start } = useMicrosoftClarity();

  useEffect(() => {
    start();
  }, []);

  return (
    <CybersourceProvider>
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <Main>{isAuthenticated ? <AuthRoutes /> : <UnauthRoutes />}</Main>
        </Suspense>
      </Router>
    </CybersourceProvider>
  );
};

export default Routes;
