import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import * as S from './Card.styles';

type CardLabelType = 'primary' | 'success' | 'error' | 'alert' | 'info' | 'disabled';

type CardAlignment = 'start' | 'center' | 'end';

export interface ICardProps {
  leftIcon?: ReactNode;
  leftLabelText?: string;
  leftLabelType?: CardLabelType;
  rightIcon?: ReactNode;
  rightLabelText?: string;
  rightLabelType?: CardLabelType;
  alignment?: CardAlignment;
  onClick?: (event?: any) => void;
  disabled?: boolean;
  selected?: boolean;
  alignItems?: 'start' | 'center' | 'end';
  children?: ReactNode;
  footerChildren?: ReactNode;
  type?: 'error' | 'default';
}

export const Card = forwardRef<HTMLDivElement, ICardProps>(
  (
    {
      leftIcon,
      leftLabelText,
      leftLabelType,
      rightIcon,
      rightLabelText,
      rightLabelType,
      alignment,
      disabled,
      selected,
      children,
      footerChildren,
      type = 'default',
      ...rest
    },
    ref,
  ) => {
    return (
      <S.Wrapper ref={ref} isRounded isFloating disabled={disabled} type={type} selected={selected} {...rest}>
        {leftIcon && (
          <S.Aside className="left" alignment={alignment}>
            <S.AsideContent disabled={disabled} selected={selected} type={type}>
              {leftIcon}
              {leftLabelText && (
                <S.Label type={disabled ? 'disabled' : type === 'error' ? type : leftLabelType}>
                  {leftLabelText}
                </S.Label>
              )}
            </S.AsideContent>
          </S.Aside>
        )}
        {children && <S.ContentWrapper>{children}</S.ContentWrapper>}
        {rightIcon && (
          <S.Aside className="right" alignment={alignment}>
            <S.AsideContent disabled={disabled} type={type}>
              {rightLabelText && (
                <S.Label type={disabled ? 'disabled' : type === 'error' ? type : rightLabelType}>
                  {rightLabelText}
                </S.Label>
              )}
              {rightIcon}
            </S.AsideContent>
          </S.Aside>
        )}
        {footerChildren && <S.FooterWrapper>{footerChildren}</S.FooterWrapper>}
      </S.Wrapper>
    );
  },
);

Card.displayName = 'Card';

Card.defaultProps = {
  alignment: 'center',
};
