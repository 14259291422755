import { ReactComponent as Arrow } from 'utils/assets/images/svg/arrowLeft.svg';
import { ReactComponent as Logout } from 'utils/assets/images/svg/logout.svg';
import * as S from './styles';

export interface IHeaderProps {
  title: string;
  leftButtonDisabled?: boolean;
  leftButtonCallback?: () => void;
  rightButtonCallback?: () => void;
  isDisabled?: boolean;
  rightButtonDisabled?: boolean;
}

export const Header = ({
  title,
  leftButtonCallback,
  rightButtonCallback,
  isDisabled = false,
  leftButtonDisabled = false,
  rightButtonDisabled = false,
}: IHeaderProps) => {
  return (
    <S.Container>
      {typeof leftButtonCallback === 'function' && (
        <S.IconButton position="left" onClick={leftButtonCallback} disabled={leftButtonDisabled || isDisabled}>
          <Arrow />
        </S.IconButton>
      )}
      <S.Title>{title}</S.Title>
      {typeof rightButtonCallback === 'function' && (
        <S.IconButton position="right" onClick={rightButtonCallback} disabled={rightButtonDisabled || isDisabled}>
          <Logout />
        </S.IconButton>
      )}
    </S.Container>
  );
};
