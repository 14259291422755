import { styled } from 'utils/styles';

export const Container = styled('div', {
  d: 'flex',
  ai: 'stretch',
  border: '1px solid #9D9D9D',
  br: '$2',
  variants: {
    selected: {
      true: {
        bg: '$blueLight',
      },
      false: {
        bg: '$white',
      },
    },
  },
  defaultVariants: {
    selected: false,
  },
});

export const IconContainer = styled('div', {
  d: 'flex',
  ai: 'center',
  pl: '$3',
  flex: '0 0 35px',
});

export const ChildrenContainer = styled('div', {
  d: 'flex',
  flexDirection: 'column',
  jc: 'center',
  px: '$3',
  py: '$1',
  typo: 'body2',
  flex: '1 1 auto',

  variants: {
    size: {
      large: {
        py: '$1',
        minHeight: '48px',
      },
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export const Button = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  ml: 'auto',
  flex: '0 0 40px',
  pl: '$1',
  pr: '$2',
  d: 'flex',
  ai: 'center',
});
