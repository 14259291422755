import { styled } from 'utils/styles';

export const Container = styled('div', {
  br: '$1',
  d: 'flex',
  ai: 'center',
  gap: '$3',
  typo: 'body2',
  width: '100%',
  lineHeight: '1rem',
  minHeight: '27px',
  '.alert-ui-icon': {
    display: 'flex',
    alignItems: 'center',
    svg: {
      minWidth: '20px',
    },
  },

  variants: {
    severity: {
      error: {
        '.alert-ui-icon': {
          color: '$error',
          path: {
            fill: '$error',
          },
        },
      },
      warning: {
        '.alert-ui-icon': {
          color: '$warning',
          path: {
            fill: '$warning',
          },
        },
      },
      info: {
        '.alert-ui-icon': {
          color: '$info',
          path: {
            fill: '$info',
          },
        },
      },
      success: {
        '.alert-ui-icon': {
          color: '$success',
          path: {
            fill: '$success',
          },
        },
      },
    },
    variant: {
      default: {},
      filled: {},
      outlined: {},
      ghost: {},
    },
    space: {
      xs: {
        p: '$1',
        '.alert-ui-content': {
          fontSize: '11px',
          fontWeight: 500,
          textAlign: 'left',
          lineHeight: 1,
        },
        '.alert-ui-icon': {
          svg: {
            minWidth: '13px',
          },
        },
      },
      sm: {
        p: '$2',
      },
      md: {
        p: '$3',
      },
      lg: {
        p: '$4',
      },
    },
  },
  compoundVariants: [
    {
      severity: 'error',
      variant: 'default',
      css: {
        color: '$error',
        bg: '$errorLight',
      },
    },
    {
      severity: 'error',
      variant: 'outlined',
      css: {
        color: '$error',
        bg: 'transparent',
        border: '1px solid $error',
      },
    },
    {
      severity: 'error',
      variant: 'filled',
      css: {
        color: '$white',
        bg: '$error',
        '.alert-ui-icon': {
          color: '$white',
          path: {
            fill: '$white',
          },
        },
      },
    },
    {
      severity: 'error',
      variant: 'ghost',
      css: {
        color: '$error',
        bg: 'transparent',
      },
    },
    {
      severity: 'warning',
      variant: 'default',
      css: {
        color: '$warning',
        bg: '$warningLight',
      },
    },
    {
      severity: 'warning',
      variant: 'outlined',
      css: {
        color: '$warning',
        bg: 'transparent',
        border: '1px solid $warning',
      },
    },
    {
      severity: 'warning',
      variant: 'filled',
      css: {
        color: '$white',
        bg: '$warning',
        '.alert-ui-icon': {
          color: '$white',
          path: {
            fill: '$white',
          },
        },
      },
    },
    {
      severity: 'warning',
      variant: 'ghost',
      css: {
        color: '$warning',
        bg: 'transparent',
      },
    },
    {
      severity: 'info',
      variant: 'default',
      css: {
        color: '$info',
        bg: '$infoLight',
      },
    },
    {
      severity: 'info',
      variant: 'outlined',
      css: {
        color: '$info',
        bg: 'transparent',
        border: '1px solid $info',
      },
    },
    {
      severity: 'info',
      variant: 'filled',
      css: {
        color: '$white',
        bg: '$info',
        '.alert-ui-icon': {
          color: '$white',
          path: {
            fill: '$white',
          },
        },
      },
    },
    {
      severity: 'info',
      variant: 'ghost',
      css: {
        color: '$info',
        bg: 'transparent',
      },
    },
    {
      severity: 'success',
      variant: 'default',
      css: {
        color: '$success',
        bg: '$successLight',
      },
    },
    {
      severity: 'success',
      variant: 'outlined',
      css: {
        color: '$success',
        bg: 'transparent',
        border: '1px solid $success',
      },
    },
    {
      severity: 'success',
      variant: 'filled',
      css: {
        color: '$white',
        bg: '$success',
        '.alert-ui-icon': {
          color: '$white',
          path: {
            fill: '$white',
          },
        },
      },
    },
    {
      severity: 'success',
      variant: 'ghost',
      css: {
        color: '$success',
        bg: 'transparent',
      },
    },
  ],
  defaultVariants: {
    variant: 'default',
    severity: 'error',
    space: 'md',
  },
});

export const Box = styled('div', {
  textAlign: 'left',
  '&.alert-ui-content': {
    width: '100%',
  },
});

export const Button = styled('button', {
  p: '$2',
  d: 'flex',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',

  variants: {
    variant: {
      default: {},
      filled: {
        path: {
          fill: '$white',
        },
      },
      outlined: {},
      ghost: {},
    },
  },
});
