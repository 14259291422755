import { createContext, useContext } from 'react';

export interface ISelectBoxContext {
  selected: boolean;
}

const SelectBoxContext = createContext<ISelectBoxContext | undefined>(undefined);

const useSelectBox = () => {
  const context = useContext(SelectBoxContext);

  if (context === undefined) {
    throw new Error('useSelectBox must be used within a SelectBox');
  }

  return context;
};

export { SelectBoxContext, useSelectBox };
