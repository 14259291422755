import type { ReactNode } from 'react';

import { getSellerDisplayName } from 'utils/functions/product';
import * as S from './styles';
import { type SellerType } from 'types';

export interface IBagProps {
  children: ReactNode;
  seller: SellerType;
}

export const Bag = ({ seller, children }: IBagProps) => {
  return (
    <S.Container>
      <S.Title>
        Vendido e entregue por <S.Seller>{getSellerDisplayName(seller.id, seller.businessDisplayName)}</S.Seller>
      </S.Title>

      {children}
    </S.Container>
  );
};
