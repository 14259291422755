import * as S from './PaymentCard.styles';

import { CardTypesLogo, isRchlo } from './constants';
import { forwardRef, useEffect, useState } from 'react';
import {
  getCreditCardTypeByNumber,
  isNonBrandedRiachueloCard,
  prettyCardNumber,
  showCVVMask,
  showCreditCardMask,
} from 'utils/functions/credit-card';

import type { CardTypes } from './constants';
import type { ComponentPropsWithRef } from 'react';
import { CreditCardType } from 'libs/credit-card-types/types';
import { ReactComponent as Riachuelo } from 'utils/assets/images/svg/Riachuelo.svg';
import { maskText } from 'utils/functions/mask';
import { paymentPlanBrand } from 'utils/functions/creditCardEnum';

export enum PaymentCardFieldsEnum {
  CardNumber = 'cardNumber',
  HolderName = 'holderName',
  ValidThru = 'validThru',
  SecurityCode = 'securityCode',
}

export type PaymentCardFieldsType = `${PaymentCardFieldsEnum}`;

export interface IPaymentCardProps extends Omit<ComponentPropsWithRef<typeof S.Wrapper>, 'children'> {
  cardNumber: string;
  holderName: string;
  validThru: string;
  securityCode: string;
  focusedField?: PaymentCardFieldsType;
  hideValues?: boolean;
}

const backSideFields = ['securityCode'];

export const PaymentCard = forwardRef<HTMLDivElement, IPaymentCardProps>(
  ({ cardNumber = '', holderName = '', validThru = '', securityCode = '', focusedField, hideValues = false }, ref) => {
    const [cardInfo, setCardInfo] = useState<CreditCardType | undefined>();
    const sanitizedCardNumber = cardNumber?.replaceAll(' ', '');

    useEffect(() => {
      let type: CreditCardType | undefined;
      const creditCardTypes = getCreditCardTypeByNumber(sanitizedCardNumber);

      if (creditCardTypes?.length === 1) {
        type = creditCardTypes[0];
      }

      setCardInfo(type);
    }, [sanitizedCardNumber]);

    return (
      <S.Wrapper ref={ref}>
        <S.InnerWrapper flipped={backSideFields.some((backSideField) => focusedField === backSideField)}>
          <S.FrontSide type={cardInfo?.type as CardTypes}>
            {cardInfo?.type && isRchlo.includes(cardInfo?.type) && <Riachuelo />}
            {cardInfo?.type && (
              <S.FrontSideBrand type={paymentPlanBrand(cardInfo?.type)}>
                {CardTypesLogo[cardInfo?.type?.toLowerCase() as CardTypes]}
              </S.FrontSideBrand>
            )}
            <S.FrontSideFields>
              <S.FrontSideFieldsRow>
                <S.CardNumber>
                  {!hideValues
                    ? showCreditCardMask(sanitizedCardNumber, cardInfo?.type as CardTypes)
                    : prettyCardNumber(
                        cardNumber
                          ?.replaceAll('x', '∗')
                          ?.replaceAll('*', '∗')
                          ?.replace(/^[0-9]{0,6}/g, '∗∗∗∗∗∗'),
                        cardInfo?.type?.toLowerCase() as CardTypes,
                      )}
                </S.CardNumber>
              </S.FrontSideFieldsRow>
              <S.FrontSideFieldsRow>
                <S.HolderName>
                  {holderName.length > 0 ? holderName.replace(/[^A-Za-z ]/g, '') : 'Nome Sobrenome'}
                </S.HolderName>
                {!isNonBrandedRiachueloCard(cardInfo?.type as CardTypes) && (
                  <S.ValidThru>
                    {!hideValues
                      ? validThru?.replace(/^([0-9]{2})([0-9]{2,})$/g, '$1/$2')
                      : maskText(validThru, '∗∗/∗∗', '-')}
                  </S.ValidThru>
                )}
              </S.FrontSideFieldsRow>
            </S.FrontSideFields>
          </S.FrontSide>
          {/* TODO: Alterar tipagem type ou alterar CreditCardType.type */}
          <S.BackSide type={cardInfo?.type as CardTypes}>
            <S.BackSideMagneticStripe />
            <S.SecurityCodeWrapper>
              <S.SecurityCodeContainer>
                <S.SecurityCode>{showCVVMask(securityCode, cardInfo?.type as CardTypes)}</S.SecurityCode>
              </S.SecurityCodeContainer>
            </S.SecurityCodeWrapper>
          </S.BackSide>
        </S.InnerWrapper>
      </S.Wrapper>
    );
  },
);

PaymentCard.displayName = 'PaymentCard';
