import * as S from './Button.styles';

import { ComponentPropsWithRef, forwardRef } from 'react';

import { ReactComponent as LoaderIcon } from 'utils/assets/images/svg/loader.svg';
import type { ReactNode } from 'react';

export interface IButtonProps extends ComponentPropsWithRef<typeof S.Button> {
  loader?: ReactNode;
  size?: any;
  variant?: any;
  fullWidth?: any;
  isLoading?: any;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(({ isLoading, loader, children, ...rest }, ref) => (
  <S.Button ref={ref} {...rest}>
    {isLoading ? loader : children}
  </S.Button>
));

Button.displayName = 'Button';

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'md',
  fullWidth: true,
  isLoading: false,
  loader: <LoaderIcon />,
};
