import { useUserDispatch } from '@estore/gtm-v4';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalState, selectUser } from 'store/helpers/selectors';
import { getStoreName } from './utils/text/getStoreName';
import sha1Encrypt from 'utils/functions/encrypt';

export const useGtagUser = () => {
  const { document, status: userStatus } = useSelector(selectUser);
  const { recoveryCart, status: globalStatus } = useSelector(selectGlobalState);

  useEffect(() => {
    if (globalStatus === 'success' && userStatus === 'success') {
      useUserDispatch({
        qr_code: recoveryCart?.code,
        registration_id: String(recoveryCart?.sellerId),
        store: getStoreName(recoveryCart?.storePrefix, recoveryCart?.storeName),
        user_id: sha1Encrypt(String(document)).toString(),
      });
    }
  }, [globalStatus, userStatus]);
};
