import { styled } from 'utils/styles';

export const Container = styled('span', {
  variants: {
    variant: {
      discount: {
        color: '$green',
      },
      add: {
        color: '$red',
      },
    },
  },
});
