import * as S from './styles';

import { ComponentProps, ReactNode } from 'react';

type TypographyAs = 'p' | 'span' | 'abbr' | 'b' | 'cite' | 'code' | 'em' | 's' | 'strong';

interface TypographyProps extends ComponentProps<typeof S.Container> {
  as?: TypographyAs;
  children: ReactNode;
  variant?: any;
}

export const Typography = ({ children, ...rest }: TypographyProps) => {
  return <S.Container {...rest}>{children}</S.Container>;
};

Typography.defaultProps = {
  as: 'p',
};
