import {
  AdditionalInformationPayload,
  AddressDeletePayload,
  AddressPayload,
  AuthTokenPayload,
  CSATPayload,
  CouponPayload,
  EngineGetAddress,
  ExchangeVoucherPayload,
  FreightPatchPayload,
  AllowedPeoplePayload,
  AllowedPeopleDeletePayload,
  GiftCardPayload,
  ListTokensPayload,
  LoginPayload,
  MergePayload,
  MultiCartPayload,
  OrderPayload,
  PatchUserPayload,
  PostPaymentPlanPayload,
  PutDeleteCartPayload,
  PutPaymentPayload,
  RemoveTokenPayload,
  GuestCartPayload,
} from './types';

import axios from 'axios';
import { estoreEngineApi } from '../../utils/config/api';

const engineURL = `${process.env.REACT_APP_API_DOTNET_FACADE_NEW}/engine`;
const estoreURL = process.env.REACT_APP_API_DOTNET_FACADE_NEW;

export const locationBusinessId = {
  locationId: 'L400',
  businessId: 'ESTORE',
};

/* LOGIN */
export const engineLogin = (payload: LoginPayload) => {
  return axios.post(`${engineURL}/v1/logins`, {
    userId: payload.cpf,
    password: payload.password,
    token: payload.token,
    ...locationBusinessId,
  });
};

export const engineLoginListTokens = (payload: ListTokensPayload, signal?: AbortSignal) =>
  axios.post(
    `${engineURL}/v1/logins/list-tokens`,
    {
      sessionId: payload.sessionId,
    },
    {
      headers: {
        authToken: payload.authToken,
      },
      signal,
    },
  );

export const engineLoginRemoveToken = (payload: RemoveTokenPayload, signal?: AbortSignal) =>
  axios.patch(
    `${engineURL}/v1/logins/remove-token`,
    {
      sessionId: payload.sessionId,
      token: payload.token,
    },
    {
      headers: {
        authToken: payload.authToken,
      },
      signal,
    },
  );

/* CUSTOMER */
export const engineGetCustomer = (payload: AuthTokenPayload) =>
  axios.get(`${estoreURL}/v2/customers`, {
    headers: { authToken: payload.authToken },
  });

export const enginePatchCustomer = (payload: PatchUserPayload) =>
  axios.patch(
    `${estoreURL}/v2/customers`,
    {
      ...payload,
    },
    { headers: { authToken: payload.authToken } },
  );

/* CART */
export const engineMergeCart = (payload: MergePayload) =>
  axios.post(
    `${engineURL}/v2/carts/merge`,
    {
      ...payload,
      ...locationBusinessId,
    },
    {
      headers: {
        authToken: payload.authToken,
      },
    },
  );

export const engineGetCart = (payload: AuthTokenPayload, signal?: AbortSignal) => {
  return axios.get(`${engineURL}/v3/carts`, {
    headers: { authToken: payload.authToken },
    signal,
  });
};

export const engineGetMultiCart = (payload: MultiCartPayload, signal?: AbortSignal) => {
  return axios.get(`${engineURL}/v3/carts?address-id=${payload.addressId}`, {
    headers: { authToken: payload.authToken },
    signal,
  });
};

export const enginePutProductCart = (payload: PutDeleteCartPayload) => {
  return axios.put(
    `${engineURL}/v2/carts`,
    {
      sellerId: payload.sellerId,
      sku: payload.sku,
      quantity: payload.quantity.toString(),
      ...locationBusinessId,
    },
    {
      headers: { authToken: payload.authToken },
    },
  );
};

export const engineDeleteProductCart = ({ addressId, sellerId, sku, quantity, authToken }: PutDeleteCartPayload) => {
  const addressIdKey = 'address-id';
  const query = new URLSearchParams();

  if (addressId) {
    query.append(addressIdKey, addressId);
  }

  return axios.delete(
    `${engineURL}/v3/carts/${locationBusinessId.locationId}/${sellerId}/${sku}/${quantity}${
      query.has(addressIdKey) ? `?${query.toString()}` : ''
    }`,
    {
      headers: { authToken },
    },
  );
};

/* ADDRESS */
export const engineGetAddress = (payload: AuthTokenPayload, signal?: AbortSignal) => {
  return estoreEngineApi.get<EngineGetAddress>('/v1/adresses', {
    headers: { authToken: payload.authToken },
    signal,
  });
};

export const enginePostAddress = (payload: AddressPayload) => {
  return estoreEngineApi.post(
    '/v1/adresses',
    {
      address: payload.addressInfo?.street,
      addressNumber: payload.addressInfo?.number,
      complement: payload.addressInfo?.complement,
      district: payload.addressInfo?.neighborhood,
      zipCode: payload.addressInfo?.zipCode,
      city: payload.addressInfo?.city,
      stateCode: payload.addressInfo?.state_short,
      favorAs: payload.addressInfo?.name,
      isBillingAddress: payload.addressInfo?.defaultBilling,
      isDeliveryAddress: payload.addressInfo?.defaultShipping,
      phone: payload.addressInfo?.phone,
      ibgeCode: payload.addressInfo?.ibge,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const enginePutAddress = (payload: AddressPayload) => {
  return estoreEngineApi.put(
    '/v1/adresses',
    {
      addressId: payload.addressInfo?.addressId,
      address: payload.addressInfo?.street,
      addressNumber: payload.addressInfo?.number,
      complement: payload.addressInfo?.complement,
      district: payload.addressInfo?.district,
      zipCode: payload.addressInfo?.zipCode,
      city: payload.addressInfo?.city,
      stateCode: payload.addressInfo?.state_short,
      favorAs: payload.addressInfo?.name,
      isBillingAddress: payload.addressInfo?.defaultBilling,
      isDeliveryAddress: payload.addressInfo?.defaultShipping,
      phone: payload.addressInfo?.phone,
      ibgeCode: payload.addressInfo?.ibge,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const engineDeleteAddress = (payload: AddressDeletePayload) => {
  return estoreEngineApi.delete(`/v1/adresses/${payload.addressId}`, {
    headers: { authToken: payload.authToken },
  });
};

/* FREIGHT */

export const enginePatchFreight = (payload: FreightPatchPayload, signal?: AbortSignal) => {
  return axios.patch(
    `${engineURL}/v2/freights`,
    {
      selectedFreights: payload.selectedFreights,
    },
    { headers: { authToken: payload.authToken }, signal },
  );
};

export const engineGetAdditionalInformation = (payload: AdditionalInformationPayload) => {
  return axios.get(
    `${engineURL}/v1/freights/additional-informations?freightModality=${payload.freightModality}&shippingGroupId=${payload.shippingGroupId}`,
    {
      headers: { authToken: payload.authToken },
    },
  );
};

/* ALLOWED PEOPLE */
export const enginePostAllowedPeople = ({
  authToken,
  freightId,
  name,
  document,
  relationship,
}: AllowedPeoplePayload) => {
  const payload = { freightId, name, document, relationship };
  return estoreEngineApi.post('/v2/freights/allowed-people', payload, { headers: { authToken } });
};

export const enginePuttAllowedPeople = ({
  authToken,
  freightId,
  name,
  document,
  relationship,
}: AllowedPeoplePayload) => {
  const payload = { freightId, name, document, relationship };
  return estoreEngineApi.put('/v2/freights/allowed-people', payload, { headers: { authToken } });
};

export const engineDeleteAllowedPeople = ({ authToken, freightId }: AllowedPeopleDeletePayload) => {
  return estoreEngineApi.delete(`/v2/freights/allowed-people/${freightId}`, { headers: { authToken } });
};

/* PROMOTIONS */
export const enginePostCoupon = (payload: CouponPayload) => {
  return axios.post(
    `${engineURL}/v2/promotions`,
    {
      coupon: payload.coupon,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const engineDeleteCoupon = (payload: AuthTokenPayload) => {
  return axios.delete(`${engineURL}/v2/promotions`, {
    headers: { authToken: payload.authToken },
  });
};

/* PAYMENTS */
export const enginePostPaymentPlan = (payload: PostPaymentPlanPayload, signal?: AbortSignal) => {
  return axios.post(
    `${engineURL}/v2/payments/payment-plans`,
    {
      cardNumber: payload?.cardNumber,
      paymentMethod: payload.paymentMethod,
      cardholderDocument: payload?.cardDocument,
      brand: payload?.cardBrand,
    },
    { headers: { authToken: payload.authToken }, signal },
  );
};

export const enginePutPayment = (payload: PutPaymentPayload, signal?: AbortSignal) => {
  return axios.put(
    `${engineURL}/v2/payments`,
    { payments: payload.paymentArray },
    {
      headers: { authToken: payload.authToken },
      signal,
    },
  );
};

/* ORDER */
export const enginePostOrder = (payload: OrderPayload, signal?: AbortSignal) => {
  return axios.post(
    `${engineURL}/v2/orders`,
    {
      orderPayments: payload.paymentArray,
    },
    {
      headers: { authToken: payload.authToken },
      signal,
    },
  );
};

/* CSAT */
export const enginePostCSAT = (payload: CSATPayload) => {
  return axios.post(
    `${engineURL}/v1/csat-answer/create/${payload.orderId}`,
    {
      ...payload.data,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const enginePostExchangeVoucher = (payload: ExchangeVoucherPayload) => {
  return axios.post(
    `${engineURL}/v1/payments/exchange-voucher`,
    {
      exchangeVoucherCode: payload.exchangeVoucherCode,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const engineDeleteExchangeVoucher = (payload: AuthTokenPayload) => {
  return axios.delete(`${engineURL}/v1/payments/exchange-voucher`, {
    headers: { authToken: payload.authToken },
  });
};

export const enginePostGiftCard = (payload: GiftCardPayload) => {
  return axios.post(
    `${engineURL}/v1/payments/gift-card`,
    {
      cardNumber: payload.cardNumber,
      pinCode: payload?.pinCode,
    },
    { headers: { authToken: payload.authToken } },
  );
};

export const engineDeleteGiftCard = (payload: AuthTokenPayload) => {
  return axios.delete(`${engineURL}/v1/payments/gift-card`, {
    headers: { authToken: payload.authToken },
  });
};

export const engineGetGuestCart = ({ cartId, params }: GuestCartPayload) => {
  return estoreEngineApi.get(`/v3/carts/guest/${cartId}`, { params });
};
