import { forwardRef } from 'react';
import { Box } from 'utils/shared/components';
import { ReactComponent as DeliveryIcon } from 'utils/assets/images/svg/delivery.svg';
import * as S from './DeliveryCard.styles';

export interface IDeliveryCardProps {
  storeName?: string;
  storeAddress?: string;
  deliveryPeriod?: string;
  deliveryForecast?: string;
  onClick?: () => void;
}

export const DeliveryCard = forwardRef<HTMLDivElement, IDeliveryCardProps>(
  ({ storeName, storeAddress, deliveryPeriod, deliveryForecast, onClick }, ref) => (
    <Box ref={ref} isRounded isFloating onClick={onClick}>
      {storeName && <S.StoreName>{storeName}</S.StoreName>}
      {storeAddress && <S.StoreAddress>{storeAddress}</S.StoreAddress>}
      {deliveryPeriod && <S.DeliveryPeriod>{deliveryPeriod}</S.DeliveryPeriod>}
      {deliveryForecast && (
        <S.DeliveryForecastWrapper>
          <DeliveryIcon />
          <S.DeliveryForecast>{deliveryForecast}</S.DeliveryForecast>
        </S.DeliveryForecastWrapper>
      )}
    </Box>
  ),
);

DeliveryCard.displayName = 'DeliveryCard';
