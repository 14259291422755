import { styled } from 'utils/styles';

export const Wrapper = styled('div', {
  width: '100%',
  height: 'auto',
  p: '8px',
  bg: '$white',
  d: 'grid',
  gridAutoFlow: 'column',
  ai: 'center',
  gap: '4px',
  borderBottom: '2px solid $shadow',
  borderTop: '1px solid $shadow',
});

export const Step = styled('div', {
  d: 'grid',
  justifyItems: 'center',
  gap: '2px',
  opacity: '0.5',
  variants: {
    active: {
      true: {
        opacity: '1',
      },
    },
  },
});

export const StepSymbol = styled('div', {
  width: '24px',
  height: '24px',
  bg: '$black',
  br: '50%',
  d: 'grid',
  justifyItems: 'center',
  ai: 'center',
  typo: 'heading3',
  color: '$white',
  '& svg': {
    width: '12px',
    '& path': {
      fill: '$white',
    },
  },
});

export const StepLabel = styled('span', {
  typo: 'heading3',
  textTransform: 'lowercase',
  color: '$black',
});

export const Separator = styled('hr', {
  borderTop: '1px solid $gray30',
  borderRight: 'none',
  borderBottom: 'none',
  borderLeft: 'none',
});
