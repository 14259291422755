import { isRCHLOSeller } from '../product';

const SEPARATORS = {
  PROPS: '-',
  METHODS: ';',
};

export const sanitizeText = (text: string) =>
  text
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/[@#$%*,.:;!?'"|(){}[\]<>]/g, '')
    ?.replace(/&/g, 'e')
    ?.replace(/\s-\s/g, '-')
    ?.replace(/(\s|_)/g, '-');

const getDeliveryDays = (days) => {
  let numberOfDays: number | string = days;
  const phrase = days < 2 ? 'dia' : 'dias';

  if (days === 0) {
    numberOfDays = '-';
  }

  return `${numberOfDays} ${phrase}`;
};

const getShippingToAddressEstimation = (shippingMethod) => {
  const deliveryDate = shippingMethod?.deliveryEstimateDateExact || shippingMethod?.deliveryEstimateBusinessDay;
  const daysToDeliver = getDeliveryDays(shippingMethod?.deliveryEstimateBusinessDays);

  return deliveryDate || daysToDeliver;
};

const mapShippingToAddressToCheckoutOption = (shippingMethod) => {
  return [
    sanitizeText(shippingMethod.description),
    sanitizeText(getShippingToAddressEstimation(shippingMethod)),
    shippingMethod.finalShippingCost,
  ].join(SEPARATORS.PROPS);
};

const mapBopisToCheckoutOption = () => [sanitizeText(`Retira rápido`), '4h', 0].join(SEPARATORS.PROPS);

const mapPickupStoreToCheckoutOption = (method) =>
  [sanitizeText(method.description), method.arrivalDateStore, 0].join(SEPARATORS.PROPS);

export const getCheckoutOptions = (methods) =>
  [
    methods.bopis?.map(mapBopisToCheckoutOption).join(SEPARATORS.METHODS),
    methods.pickUpStore?.map(mapPickupStoreToCheckoutOption).join(SEPARATORS.METHODS),
    methods.shippingToAddress?.map(mapShippingToAddressToCheckoutOption).join(SEPARATORS.METHODS),
  ]
    .filter(Boolean)
    .join(SEPARATORS.METHODS);

export const getDimension17 = (sellerId: string) => {
  const isSoldByRchlo = isRCHLOSeller(sellerId) ? `sim` : `nao`;
  const dimension17 = `${isSoldByRchlo}:${sellerId.toLocaleLowerCase()}`;
  return dimension17;
};

export const getSellerType = (seller: string) => {
  if (seller.includes('Surface' || 'Mac')) {
    return 'desktop';
  }
  return 'mobile';
};
