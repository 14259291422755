import { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import * as S from './RadioButton.styles';

export interface IRadioButtonProps extends ComponentProps<typeof S.RadioInput> {
  value?: string;
  checked: boolean;
  error?: boolean;
}

export const RadioButton = forwardRef<HTMLDivElement, IRadioButtonProps>(({ error = false, checked, ...rest }, ref) => {
  let isError: boolean | 'true' | 'checked' = false;
  if (error && checked) {
    isError = 'checked';
  } else if (error && !checked) {
    isError = 'true';
  } else {
    isError = false;
  }
  return <S.RadioInput ref={ref} checked={checked} error={isError} {...rest} />;
});

RadioButton.displayName = 'RadioButton';
