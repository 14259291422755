import { styled } from 'utils/styles';

export const ModalWrapper = styled('div', {
  p: '20px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> p': {
    textAlign: 'center',
    my: '8px',
  },
});
