import { styled } from 'utils/styles';

export const RadioInput = styled('div', {
  width: '20px',
  height: '20px',
  p: '2px',
  position: 'relative',
  d: 'inline-block',
  br: '50%',
  border: '2px solid $gray30',
  cursor: 'pointer',
  transition: 'border-color 200ms ease-in',
  '&::after': {
    content: '',
    width: '10px',
    height: '10px',
    position: 'absolute',
    top: '3px',
    left: '3px',
    bg: '$gray30',
    br: '50%',
    transform: 'scale(0)',
    transition: 'transform 200ms ease-in',
  },
  variants: {
    checked: {
      true: {
        borderColor: '$blue',
        '&::after': {
          bg: '$blue',
          transform: 'scale(1)',
        },
      },
    },
    error: {
      true: {
        borderColor: '$red',
      },
      checked: {
        borderColor: '$red',
        '&::after': {
          bg: '$red!important',
          transform: 'scale(1)',
        },
      },
    },
  },
  defaultVariants: {
    error: false,
  },
});
