import { ProductType } from 'modules/Bag/components/ProductList/types';
import React, { ChangeEvent, Dispatch, useState } from 'react';
import { AnyAction } from 'redux';
import { ReactComponent as DecreaseIcon } from 'utils/assets/images/svg/selector-decrease.svg';
import { ReactComponent as IncreaseIcon } from 'utils/assets/images/svg/selector-increase.svg';

import * as S from './styles';

export interface IQuantitySelectorProps {
  type: 'text' | 'number';
  label: string;
  min: number;
  max: number;
  quantity: number;
  disabled?: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSetValue?: (product: ProductType, quantity: number) => void | ((dispatch: Dispatch<AnyAction>) => Promise<void>);
  product: any;
}

export const QuantitySelector = ({
  quantity,
  disabled,
  onIncrease,
  onDecrease,
  label,
  type,
  min,
  max,
  onSetValue,
  product,
}: IQuantitySelectorProps) => {
  const [quantityInput, setQuantityInput] = useState(quantity);
  return (
    <S.Container disabled={disabled}>
      {label && <S.Label>{label}:</S.Label>}
      <S.Selector>
        <S.Button onClick={onDecrease} disabled={disabled || quantity <= min}>
          <DecreaseIcon />
        </S.Button>
        <S.Counter
          type={type}
          onChange={(e) => setQuantityInput(parseInt(e?.target?.value))}
          min={min}
          max={max}
          value={quantityInput || 0}
          onBlur={() => onSetValue && onSetValue(product, quantityInput)}
          onKeyUp={(e) => e.key === 'Enter' && onSetValue && onSetValue(product, quantityInput)}
          disabled={disabled}
        />
        <S.Button onClick={onIncrease} disabled={disabled || quantity >= max}>
          <IncreaseIcon />
        </S.Button>
      </S.Selector>
    </S.Container>
  );
};

QuantitySelector.defaultProps = {
  disabled: false,
  type: 'text',
  min: 0,
};
