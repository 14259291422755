import { ReactComponent as TrashIcon } from 'utils/assets/images/svg/trash.svg';
import { ReactComponent as PencilIcon } from 'utils/assets/images/svg/pencil.svg';
import { ReactComponent as DotsMenu } from 'utils/assets/images/svg/dots-menu.svg';

import { IPopoverContentProps, Popover } from '../Popover/Popover';

import * as S from './AddressMenu.styles';

interface IAddressMenuProps extends Pick<IPopoverContentProps, 'anchorPosition' | 'contentAlignment'> {
  onEdit: () => void;
  onDelete: () => void;
}

export const AddressMenu = ({ anchorPosition, contentAlignment, onEdit, onDelete }: IAddressMenuProps) => {
  return (
    <Popover initialOpenState={false}>
      <Popover.Trigger>
        <S.IconContainer>
          <DotsMenu />
        </S.IconContainer>
      </Popover.Trigger>

      <Popover.Content hideOnClickOutside anchorPosition={anchorPosition} contentAlignment={contentAlignment}>
        <S.Box
          isRounded
          isFloating
          css={{
            padding: 0,
            minWidth: '120px',
          }}
        >
          <S.Button onClick={onEdit}>
            <PencilIcon /> <S.ButtonLabel>Editar</S.ButtonLabel>
          </S.Button>
          <S.Button onClick={onDelete}>
            <TrashIcon /> <S.ButtonLabel>Excluir</S.ButtonLabel>
          </S.Button>
        </S.Box>
      </Popover.Content>
    </Popover>
  );
};

AddressMenu.defaultProps = {
  anchorPosition: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  contentAlignment: {
    horizontal: 'right',
    vertical: 'top',
  },
};
