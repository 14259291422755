import { useState } from 'react';
import { HelmetProvider } from '../helmet';
import CybersourceContex from './CybersourceContext';
import { CybersourceHead } from './components';
import { generateCybersourceUrl } from './helper';

function CybersourceProvider({ children }) {
  const [sessionId, setSessionId] = useState<string>();

  const injectScript = (cartId: string) => {
    if (!cartId) {
      throw new Error('cartId is null');
    }
    setSessionId(cartId);
    return generateCybersourceUrl(cartId);
  };

  return (
    <HelmetProvider>
      <CybersourceContex.Provider value={{ sessionId, injectScript }}>
        <CybersourceHead sessionId={sessionId} />
        {children}
      </CybersourceContex.Provider>
    </HelmetProvider>
  );
}

export default CybersourceProvider;
