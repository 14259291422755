import { HelmetProvider as ReactHelmetProvider } from 'react-helmet-async';

function HelmetProvider({ children, ...rest }) {
  if (!ReactHelmetProvider) {
    throw new Error("react-helmet-async doesn't implemented!");
  }
  return <ReactHelmetProvider {...rest}>{children}</ReactHelmetProvider>;
}

export default HelmetProvider;
