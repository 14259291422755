export const AnalyticsStep = {
  Bag: 1,
  Shipping: 4,
  Payment: 5,
  PaymentCoupon: 5.1,
  PaymentVoucher: 5.2,
  PaymentGiftCard: 5.3,
  PaymentMethod: 5.4,
  PaymentOverview: 6,
} as const;

export type Values<T> = T[keyof T];
