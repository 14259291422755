import { memo } from 'react';
import { Helmet } from '../../helmet';
import { generateCybersourceUrl } from '../helper';

function CybersourceHead({ sessionId }) {
  if (!sessionId) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript" src={generateCybersourceUrl(sessionId)} />
    </Helmet>
  );
}

export default memo(CybersourceHead);
