import './index.css';

import ErrorBoundary from 'utils/common/components/error/ErrorBoundary';
import { ErrorBoundaryContext } from 'react-use-error-boundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Routes from './routes';

import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { MicrosoftClarityProvider } from '@estore/react-clarity-js';
import { MicrosoftSignalRProvider } from '@estore/react-signalr';
import { SIGNALR_URL } from 'utils/config/environments';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCKING === 'enabled') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <MicrosoftClarityProvider projectId={process.env.REACT_APP_CLARITY_PROJECT_ID}>
    <GoogleReCaptchaProvider
      reCaptchaKey={String(process.env.REACT_APP_FF_CAPTCHA_KEY_V3)}
      language="pt_BR"
      useEnterprise
      scriptProps={{
        id: 'google-recaptcha-v3',
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundaryContext>
            <ErrorBoundary>
              <MicrosoftSignalRProvider url={SIGNALR_URL}>
                <Routes />
              </MicrosoftSignalRProvider>
            </ErrorBoundary>
          </ErrorBoundaryContext>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  </MicrosoftClarityProvider>,
);
