import { Button } from '..';
import * as S from './styles';

export const InvalidQuantityModal = ({ onClick, message }: { onClick: any; message?: string }) => {
  return (
    <S.ModalWrapper>
      <p>{message || 'A quantidade desejada está indisponível no momento.'}</p>
      <Button color="secondary" onClick={onClick}>
        OK, ENTENDI
      </Button>
    </S.ModalWrapper>
  );
};
