import { ActionType, CartType, CheckoutType } from './types';
import {
  AuthTokenPayload,
  FreightPatchPayload,
  AllowedPeoplePayload,
  AllowedPeopleDeletePayload,
  MergePayload,
  MultiCartPayload,
  PutDeleteCartPayload,
} from 'store/engine/types';
import {
  engineDeleteProductCart,
  engineGetCart,
  engineGetMultiCart,
  engineMergeCart,
  enginePatchFreight,
  enginePutProductCart,
  enginePostAllowedPeople,
  enginePuttAllowedPeople,
  engineDeleteAllowedPeople,
} from 'store/engine';

import { AdditionalInformationPayload } from './../engine/types';
import { Dispatch } from 'redux';
import { GlobalTypes } from './global';
import { analyticsBagCallback } from 'utils/functions/analytics';
import { analyticsDeliveryAddressCallback } from 'utils/functions/analytics';
import { engineGetAdditionalInformation } from './../engine/index';
import { Types as CheckoutTypes } from './checkout';
import { Types as AddressTypes } from './address';
import { Types as AuthTypes } from './authCustomer';
import { useAllowedPeople } from 'libs/gtag';
import {
  mergeCartSuccess,
  mergeCartError,
  mergeCart,
  getCart,
  getCartSuccess,
  getCartError,
  getMultiCart,
  getMultiCartSuccess,
  getMultiCartError,
  addProductToCart,
  addProductToCartSuccess,
  addProductToCartError,
  removeProductFromCart,
  removeProductFromCartSuccess,
  removeProductFromCartError,
  patchDeliveryMethod,
  patchDeliveryMethodSuccess,
  patchDeliveryMethodError,
  getAdditionalInformation,
  getAdditionalInformationSuccess,
  getAdditionalInformationError,
  AllowedPeopleRequestMethod,
  AllowedPeopleRequestMethodError,
  setAllowedPeopleMethodSuccess,
  deleteAllowedPeopleMethodSuccess,
} from './cart/actions';
import { getPaymentMethods } from './appConfig';
import { AxiosResponse } from 'axios';
import { useAddShippingInfo } from 'libs';
import sha1Encrypt from 'utils/functions/encrypt';
import { getStoreName } from 'libs/gtag/utils/text/getStoreName';
import type { Cart, Coupon } from 'types';

export const Types = {
  MERGE_CART: 'cart/MERGE_CART',
  MERGE_CART_SUCCESS: 'cart/MERGE_CART_SUCCESS',
  MERGE_CART_ERROR: 'cart/MERGE_CART_ERROR',

  GET_CART: 'cart/GET_CART',
  GET_CART_SUCCESS: 'cart/GET_CART_SUCCESS',
  GET_CART_ERROR: 'cart/GET_CART_ERROR',

  GET_MULTI_CART: 'cart/GET_MULTI_CART',
  GET_MULTI_CART_SUCCESS: 'cart/GET_MULTI_CART_SUCCESS',
  GET_MULTI_CART_ERROR: 'cart/GET_MULTI_CART_ERROR',

  ADD_PRODUCT_TO_CART: 'cart/ADD_PRODUCT_TO_CART',
  ADD_PRODUCT_TO_CART_SUCCESS: 'cart/ADD_PRODUCT_TO_CART_SUCCESS',
  ADD_PRODUCT_TO_CART_ERROR: 'cart/ADD_PRODUCT_TO_CART_ERROR',

  REMOVE_PRODUCT_FROM_CART: 'cart/REMOVE_PRODUCT_FROM_CART',
  REMOVE_PRODUCT_FROM_CART_SUCCESS: 'cart/REMOVE_PRODUCT_FROM_CART_SUCCESS',
  REMOVE_PRODUCT_FROM_CART_ERROR: 'cart/REMOVE_PRODUCT_FROM_CART_ERROR',

  SET_DELIVERY_METHOD: 'cart/SET_DELIVERY_METHOD',
  SET_DELIVERY_METHOD_SUCCESS: 'cart/SET_DELIVERY_METHOD_SUCCESS',
  SET_DELIVERY_METHOD_ERROR: 'cart/SET_DELIVERY_METHOD_ERROR',

  PATCH_DELIVERY_METHOD: 'cart/PATCH_DELIVERY_METHOD',
  PATCH_DELIVERY_METHOD_SUCCESS: 'cart/PATCH_DELIVERY_METHOD_SUCCESS',
  PATCH_DELIVERY_METHOD_ERROR: 'cart/PATCH_DELIVERY_METHOD_ERROR',

  ALLOWED_PEOPLE_REQUEST: 'cart/ALLOWED_PEOPLE_REQUEST',
  ALLOWED_PEOPLE_REQUEST_ERROR: 'cart/ALLOWED_PEOPLE_REQUEST_ERROR',

  SET_ALLOWED_PEOPLE_SUCCESS: 'cart/SET_ALLOWED_PEOPLE_SUCCESS',
  REMOVE_ALLOWED_PEOPLE_SUCCESS: 'cart/REMOVE_ALLOWED_PEOPLE_SUCCESS',

  GET_ADDITIONAL_INFORMATION: 'cart/GET_ADDITIONAL_INFORMATION',
  GET_ADDITIONAL_INFORMATION_SUCCESS: 'cart/GET_ADDITIONAL_INFORMATION_SUCCESS',
  GET_ADDITIONAL_INFORMATION_ERROR: 'cart/GET_ADDITIONAL_INFORMATION_ERROR',

  UPDATE_CART_WITH_CHECKOUT: 'cart/UPDATE_CART_WITH_CHECKOUT',

  RESET_COUPON: 'cart/RESET_COUPON',

  RESET_CART_STATUS: 'cart/RESET_CART_STATUS',

  RESET_DELIVERY_METHOD: 'cart/RESET_DELIVERY_METHOD',
};

type CartState = CartType &
  CheckoutType & {
    coupons: (Coupon & { deleted?: boolean })[];
  };

const initialState: CartState = {
  cart: null,
  status: 'idle',
  isMerged: false,
  deliveryMethods: [],
  coupom: {
    status: 'idle',
    promotion: null,
  },
  promotion: undefined,
  paymentInfo: undefined,
  paymentMethod: undefined,
  creditCardInfo: undefined,
  installmentInfo: undefined,
  order: undefined,
  exchangeVoucher: {
    availableAmount: undefined,
    availableAmountAsText: undefined,
    appliedAmount: undefined,
    appliedAmountAsText: undefined,
    remainingAmount: undefined,
    remainingAmountAsText: undefined,
    status: 'idle',
    message: undefined,
    voucher: null,
  },
  giftCard: {
    availableAmount: undefined,
    availableAmountAsText: undefined,
    appliedAmount: undefined,
    appliedAmountAsText: undefined,
    remainingAmount: undefined,
    remainingAmountAsText: undefined,
    status: 'idle',
    message: undefined,
    cardNumber: null,
  },
  coupons: [],
  discountDetails: [],
};

export const reducer = (state = initialState, action: ActionType) => {
  const persistedStore = sessionStorage.getItem('persist:root');
  const deliveryMethods = JSON.parse(sessionStorage.getItem('persist:delivery') || '[]');
  const addDeliveryMethods = action?.payload?.hasFreight ? deliveryMethods : [];
  switch (action.type) {
    case CheckoutTypes.GET_CHECKOUT:
      return {
        ...state,
        ...action.payload,
        status: state.status,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          ...action?.payload?.exchangeVoucher,
          voucher: state?.exchangeVoucher?.voucher,
        },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        giftCard: {
          ...state.giftCard,
          ...action.payload?.giftCard,
          cardNumber: state?.giftCard?.cardNumber,
        },
      };

    // MERGE CART
    case Types.MERGE_CART:
      return { ...state, status: 'pending', isMerged: false };
    case Types.MERGE_CART_SUCCESS:
      return { ...state, status: 'success', isMerged: true, ...action.payload };
    case Types.MERGE_CART_ERROR:
      return { ...state, status: 'error', isMerged: true };

    // GET CART
    case Types.GET_CART:
      return { ...state, status: 'pending', isMerged: true };
    case Types.GET_CART_SUCCESS:
      const checkoutPersistedStore = persistedStore && JSON.parse(JSON.parse(persistedStore).checkoutState);
      if (!action.payload.hasFreight) sessionStorage.removeItem('persist:delivery');
      return {
        ...state,
        status: 'success',
        ...action.payload,
        deliveryMethods: addDeliveryMethods,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          ...checkoutPersistedStore?.payload?.exchangeVoucher,
          voucher: checkoutPersistedStore?.exchangeVoucher?.voucher,
        },
        creditCardInfo: checkoutPersistedStore?.creditCardInfo,
        paymentInfo: checkoutPersistedStore?.paymentInfo,
        installmentInfo: checkoutPersistedStore?.installmentInfo,
        giftCard: {
          ...state.giftCard,
          ...checkoutPersistedStore?.giftCard,
          cardNumber: checkoutPersistedStore?.giftCard?.cardNumber,
        },
      };
    case Types.GET_CART_ERROR:
      return { ...state, status: 'error' };

    //GET MULTI CART
    case Types.GET_MULTI_CART:
      return { ...state, status: 'pending' };
    case Types.GET_MULTI_CART_SUCCESS:
      if (!action.payload.hasFreight) sessionStorage.removeItem('persist:delivery');
      return { ...state, status: 'success', ...action.payload, deliveryMethods: addDeliveryMethods };
    case Types.GET_MULTI_CART_ERROR:
      return { ...state, status: 'error' };

    // ADD PRODUCT TO CART
    case Types.ADD_PRODUCT_TO_CART:
      return { ...state, status: 'pending' };
    case Types.ADD_PRODUCT_TO_CART_SUCCESS:
      return {
        ...state,
        deliveryMethods: [],
        status: 'success',
        ...action.payload,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          ...action.payload.exchangeVoucher,
          voucher: state.exchangeVoucher.voucher,
        },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        giftCard: {
          ...state.giftCard,
          ...action.payload.giftCard,
          cardNumber: state.giftCard.cardNumber,
        },
      };
    case Types.ADD_PRODUCT_TO_CART_ERROR:
      return { ...state, status: 'error', errorMessages: action.payload.response?.data?.errorMessages };

    // REMOVE PRODUCT FROM CART
    case Types.REMOVE_PRODUCT_FROM_CART:
      return { ...state, status: 'pending' };
    case Types.REMOVE_PRODUCT_FROM_CART_SUCCESS:
      return {
        ...state,
        deliveryMethods: [],
        status: 'success',
        ...action.payload,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          ...action.payload.exchangeVoucher,
          voucher: state.exchangeVoucher.voucher,
        },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        giftCard: {
          ...state.giftCard,
          ...action.payload.giftCard,
          cardNumber: state.giftCard.cardNumber,
        },
      };
    case Types.REMOVE_PRODUCT_FROM_CART_ERROR:
      return { ...state, status: 'error' };

    // SET DELIVERY METHODS
    case Types.SET_DELIVERY_METHOD:
      state.deliveryMethods[action.payload.index] = action.payload.method;
      sessionStorage.setItem('persist:delivery', JSON.stringify(state.deliveryMethods));
      return { ...state, status: 'success' };

    // PATCH DELIVERY METHODS
    case Types.PATCH_DELIVERY_METHOD:
      return { ...state, status: 'pending' };
    case Types.PATCH_DELIVERY_METHOD_SUCCESS:
      return { ...state, status: 'success', ...action.payload };
    case Types.PATCH_DELIVERY_METHOD_ERROR:
      return { ...state, status: 'error' };

    // ALLOWED PEOPLE CALLS
    case Types.ALLOWED_PEOPLE_REQUEST:
      return { ...state, loadingAllowedPeople: 'pending' };
    case Types.ALLOWED_PEOPLE_REQUEST_ERROR:
      return { ...state, loadingAllowedPeople: 'error' };

    // SET ALLOWED PEOPLE
    case Types.SET_ALLOWED_PEOPLE_SUCCESS:
      const {
        allowedPeople: { index, ...rest },
        data,
      } = action.payload;
      const deliveryMethods = [...state.deliveryMethods];
      deliveryMethods[index].allowedPeople = { ...rest };
      sessionStorage.setItem('persist:delivery', JSON.stringify(deliveryMethods));
      return { ...state, loadingAllowedPeople: 'success', ...data, deliveryMethods };

    // PUT ALLOWED PEOPLE
    case Types.SET_ALLOWED_PEOPLE_SUCCESS:
      const {
        allowedPeople: { index: indexEdit, ...editRest },
        data: editData,
      } = action.payload;
      const editedDeliveryMethods = [...state.deliveryMethods];
      editedDeliveryMethods[indexEdit].allowedPeople = { ...editRest };
      sessionStorage.setItem('persist:delivery', JSON.stringify(editedDeliveryMethods));
      return { ...state, loadingAllowedPeople: 'success', ...editData, editedDeliveryMethods };

    // REMOVE ALLOWED PEOPLE
    case Types.REMOVE_ALLOWED_PEOPLE_SUCCESS:
      const { index: removeIndex, data: removeData } = action.payload;
      const tempDeliveryMethods = [...state.deliveryMethods];
      delete tempDeliveryMethods[removeIndex].allowedPeople;
      sessionStorage.setItem('persist:delivery', JSON.stringify(tempDeliveryMethods));
      return { ...state, loadingAllowedPeople: 'success', ...removeData, deliveryMethods: tempDeliveryMethods };

    // GET ADDITIONAL INFORMATION
    case Types.GET_ADDITIONAL_INFORMATION:
      return { ...state, loadingFreight: 'pending' };
    case Types.GET_ADDITIONAL_INFORMATION_SUCCESS:
      return { ...state, loadingFreight: 'success', ...action.payload };
    case Types.GET_ADDITIONAL_INFORMATION_ERROR:
      return { ...state, loadingFreight: 'error' };

    // UPDATE CART WITH CHECKOUT
    case Types.UPDATE_CART_WITH_CHECKOUT:
      return { ...state, ...action.payload };

    // RESET COUPON
    case Types.RESET_COUPON:
      return { ...state, couponAmount: null, hasCoupon: false, coupon: null };

    // CHECKOUT

    // POST_COUPON
    case CheckoutTypes.POST_COUPON:
      return {
        ...state,
        coupom: {
          ...state.coupom,
          status: 'pending',
        },
      };
    case CheckoutTypes.POST_COUPON_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.data.exchangeVoucher },
        giftCard: { ...state.giftCard, ...action.payload.data.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupom: {
          ...state.coupom,
          status: 'success',
          promotion: {
            progressiveDiscountValue: action.payload.data.couponAmount,
            coupom: {
              promotionalCoupon: action.payload.data.coupon,
            },
          },
        },
      };
    case CheckoutTypes.POST_COUPON_ERROR:
      return {
        ...state,
        ...action?.payload,
        exchangeVoucher: { ...state.exchangeVoucher },
        giftCard: { ...state.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        promotion: null,
        coupom: {
          status: 'error',
          promotion: {
            progressiveDiscountValue: action?.payload?.couponAmount,
            coupom: {
              promotionalCoupon: action?.payload?.coupon,
            },
          },
        },
      };

    // DELETE_COUPON
    case CheckoutTypes.DELETE_COUPON:
      return {
        ...state,
        coupom: {
          ...state.coupom,
          status: 'pending',
        },
      };
    case CheckoutTypes.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.exchangeVoucher },
        giftCard: { ...state.giftCard, ...action.payload.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupon: null,
        coupom: {
          ...state.coupom,
          status: 'success',
          promotion: null,
          deleted: true,
        },
      };
    case CheckoutTypes.DELETE_COUPON_ERROR:
      return {
        ...state,
        ...action?.payload,
        exchangeVoucher: { ...state.exchangeVoucher },
        giftCard: { ...state.giftCard },
        creditCardInfo: state.creditCardInfo,
        paymentInfo: state.paymentInfo,
        coupom: {
          ...state.coupom,
          status: 'error',
          deleted: false,
        },
      };

    // POST EXCHANGE VOUCHER
    case CheckoutTypes.POST_EXCHANGE_VOUCHER:
      return {
        ...state,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          status: 'pending',
        },
        giftCard: { ...state.giftCard },
      };
    case CheckoutTypes.POST_EXCHANGE_VOUCHER_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        exchangeVoucher: {
          ...action.payload.data.exchangeVoucher,
          voucher: action.payload.voucher,
          status: 'success',
        },
        giftCard: { ...state.giftCard, ...action.payload.data.giftCard },
      };
    case CheckoutTypes.POST_EXCHANGE_VOUCHER_ERROR: {
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: {
          ...action.payload.exchangeVoucher,
          status: 'error',
        },
        giftCard: { ...state.giftCard },
      };
    }

    // DELETE EXCHANGE VOUCHER
    case CheckoutTypes.DELETE_EXCHANGE_VOUCHER:
      return {
        ...state,
        exchangeVoucher: {
          ...state.exchangeVoucher,
          status: 'pending',
        },
        giftCard: { ...state.giftCard },
      };
    case CheckoutTypes.DELETE_EXCHANGE_VOUCHER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: {
          ...action.payload.exchangeVoucher,
          status: 'success',
        },
        giftCard: { ...state.giftCard, ...action.payload.giftCard },
      };
    case CheckoutTypes.DELETE_EXCHANGE_VOUCHER_ERROR: {
      return {
        ...state,
        exchangeVoucher: {
          ...action.payload,
          status: 'error',
        },
        giftCard: { ...state.giftCard },
      };
    }

    // POST GIFT CARD
    case CheckoutTypes.POST_GIFT_CARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          status: 'pending',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    case CheckoutTypes.POST_GIFT_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        giftCard: {
          ...action.payload.data.giftCard,
          cardNumber: action.payload.cardNumber,
          status: 'success',
        },
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.data.exchangeVoucher },
      };
    case CheckoutTypes.POST_GIFT_CARD_ERROR: {
      return {
        ...state,
        ...action.payload,
        giftCard: {
          ...action.payload.giftCard,
          status: 'error',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    }

    // DELETE EXCHANGE VOUCHER
    case CheckoutTypes.DELETE_GIFT_CARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          status: 'pending',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    case CheckoutTypes.DELETE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        giftCard: {
          ...action.payload.giftCard,
          status: 'success',
        },
        exchangeVoucher: { ...state.exchangeVoucher, ...action.payload.exchangeVoucher },
      };
    case CheckoutTypes.DELETE_GIFT_CARD_ERROR: {
      return {
        ...state,
        giftCard: {
          ...action.payload,
          status: 'error',
        },
        exchangeVoucher: { ...state.exchangeVoucher },
      };
    }

    // SET_PAYMENT_METHOD
    case CheckoutTypes.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };

    // POST_PAYMENT_PLAN
    case CheckoutTypes.POST_PAYMENT_PLAN:
      return { ...state, status: 'pending', creditCardInfo: action.payload };
    case CheckoutTypes.POST_PAYMENT_PLAN_SUCCESS:
      return { ...state, status: 'success', paymentInfo: action.payload };
    case CheckoutTypes.POST_PAYMENT_PLAN_ERROR:
      return { ...state, status: 'error' };

    // PUT_PAYMENT_PLAN
    case CheckoutTypes.PUT_PAYMENT:
      return { ...state, status: 'pending' };
    case CheckoutTypes.PUT_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        exchangeVoucher: { ...state.exchangeVoucher, status: 'idle' },
        giftCard: { ...state.giftCard, status: 'idle' },
        installmentInfo: { quantity: action.payload.installmentId, amount: action.payload.installmentAmount },
        coupom: {
          ...state.coupom,
        },
        status: 'success',
      };
    case CheckoutTypes.PUT_PAYMENT_ERROR:
      return { ...state, status: 'error' };

    // POST_ORDER
    case CheckoutTypes.POST_ORDER:
      return { ...state, status: 'pending', orderErrorType: null };
    case CheckoutTypes.POST_ORDER_SUCCESS:
      return { ...state, status: 'success', order: action.payload.response, showCsat: action.payload.showCsat };
    case CheckoutTypes.POST_ORDER_ERROR:
      return { ...state, status: 'error', orderErrorType: action.payload };

    case CheckoutTypes.SET_CREDIT_CARD_INFO:
      return { ...state, ...action.payload };

    // ACCESS_TOKEN
    case CheckoutTypes.GET_ACCESS_TOKEN:
      return { ...state, status: 'pending' };
    case CheckoutTypes.GET_ACCESS_TOKEN_SUCCESS:
      return { ...state, status: 'success', accessToken: action.payload };
    case CheckoutTypes.GET_ACCESS_TOKEN_ERROR:
      return { ...state, status: 'error' };

    case CheckoutTypes.RESET_ACCESS_TOKEN:
      return { ...state, accessToken: null };

    case CheckoutTypes.RESET_PAYMENT_METHOD_ERROR:
      return { ...state, orderErrorType: null, status: 'idle' };

    // CSAT
    case CheckoutTypes.POST_CSAT:
      return { ...state, status: 'pending' };
    case CheckoutTypes.POST_CSAT_SUCCESS:
      return { ...state, status: 'success', showCsat: false };
    case CheckoutTypes.POST_CSAT_ERROR:
      return { ...state, status: 'error', showCsat: false };

    case CheckoutTypes.RESET_ORDER_ERROR:
      return { ...state, orderErrorType: null };

    // RESET_PAYMENT_METHOD
    case CheckoutTypes.RESET_PAYMENT_METHOD:
      return { ...state, paymentMethod: null };

    // RESET_CHECKOUT_STATUS
    case CheckoutTypes.RESET_CHECKOUT_STATUS:
      return {
        ...state,
        status: 'idle',
        exchangeVoucher: { ...state.exchangeVoucher, status: 'idle' },
        giftCard: { ...state.giftCard, status: 'idle' },
      };

    // ADDRESS
    case AddressTypes.SET_ADDRESS:
      return { ...state, selectedAddress: action.payload, hasFreight: false };

    // RESET CART STATUS
    case Types.RESET_CART_STATUS:
      return { ...state, status: 'idle' };

    case Types.RESET_DELIVERY_METHOD:
      sessionStorage.removeItem('persist:delivery');
      return { ...state, deliveryMethods: [] };

    case GlobalTypes.GLOBAL_STATE:
      return { ...state, ...action.payload };

    // RESET CART STATE
    case AuthTypes.AUTH_CUSTOMER_RESET_STATE:
    case GlobalTypes.GLOBAL_RESET:
      sessionStorage.clear();
      return { ...initialState };
    default:
      return { ...state };
  }
};

const asyncMergeCart = (payload: MergePayload) => async (dispatch: Dispatch) => {
  dispatch(mergeCart());
  try {
    const response = await engineMergeCart(payload);
    const { status, data } = response;
    if (status === 200) {
      if (data.data?.validationMessage) {
        analyticsBagCallback(false, data.data?.validationMessage);
        throw new Error(data.data.validationMessage);
      }
      analyticsBagCallback(true);
      dispatch(mergeCartSuccess(data.data));

      if (data?.data?.paymentMethods) {
        dispatch(getPaymentMethods(data));
      }
    } else if (status === 204) {
      analyticsBagCallback(false, data.data?.validationMessage);
      throw new Error('Token de autenticação inválido');
    }
  } catch (error) {
    let errorMessage = 'Problema ao vincular carrinho';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    analyticsBagCallback(false, errorMessage);
    dispatch(mergeCartError());
  }
};

const asyncGetCart =
  ({ authToken }: AuthTokenPayload, signal?: AbortSignal) =>
  async (dispatch: Dispatch) => {
    dispatch(getCart());
    try {
      const response: AxiosResponse<{ data: CartType<Cart> }> = await engineGetCart({ authToken }, signal);
      const {
        status,
        data: { data },
      } = response;
      if (status === 200) {
        if (data?.validationMessage) {
          analyticsBagCallback(false, data?.validationMessage);
          throw new Error(data.validationMessage);
        }
        analyticsBagCallback(true);

        dispatch(getCartSuccess(data));
      } else {
        analyticsBagCallback(false, data?.validationMessage);
        throw new Error();
      }
    } catch (error) {
      analyticsBagCallback(false, 'Problema ao recuperar carrinho');
      dispatch(getCartError());
    }
  };

const asyncGetMultiCart = (payload: MultiCartPayload, signal?: AbortSignal) => async (dispatch: Dispatch) => {
  dispatch(getMultiCart());
  try {
    const response = await engineGetMultiCart(payload, signal);
    const { data } = response.data;
    analyticsDeliveryAddressCallback(true);
    dispatch(getMultiCartSuccess(data));
  } catch {
    analyticsDeliveryAddressCallback(false, 'Problema no endereço selecionado');
    dispatch(getMultiCartError());
  }
};

const asyncAddProductToCart = (payload: PutDeleteCartPayload) => async (dispatch: Dispatch) => {
  dispatch(addProductToCart());
  try {
    const response = await enginePutProductCart(payload);
    const { data } = response.data;
    dispatch(addProductToCartSuccess(data));
  } catch (error) {
    dispatch(addProductToCartError(error));
  }
};

const asyncRemoveProductFromCart = (payload: PutDeleteCartPayload) => async (dispatch: Dispatch) => {
  dispatch(removeProductFromCart());
  try {
    const response = await engineDeleteProductCart(payload);
    const { data } = response.data;
    dispatch(removeProductFromCartSuccess(data));
  } catch (error) {
    dispatch(removeProductFromCartError());
  }
};

const asyncPatchDeliveryMethod =
  (payload: FreightPatchPayload, isSharedBag: boolean, signal?: AbortSignal) => async (dispatch: Dispatch) => {
    dispatch(patchDeliveryMethod());
    try {
      const response = await enginePatchFreight(payload, signal);
      if (response.status === 200) {
        const { data } = response.data;
        useAddShippingInfo({
          value: data.total || 0,
          coupon: data.coupons?.[0]?.coupon,
          shippingGroups: data.shippingGroups,
          freightId: payload?.freightId,
          qr_code: data?.recoveryCart?.code,
          user_id: sha1Encrypt(data.cart?.userId).toString(),
          registration_id: String(data.recoveryCart?.sellerId),
          cart_id: data.cart.cartId,
          store: getStoreName(data.recoveryCart?.storePrefix, data.recoveryCart?.storeName),
          isSharedBag,
        });
        return dispatch(patchDeliveryMethodSuccess(data));
      }
      return dispatch(patchDeliveryMethodError());
    } catch {
      return dispatch(patchDeliveryMethodError());
    }
  };

const asyncGetAdditionalInformation = (payload: AdditionalInformationPayload) => async (dispatch: Dispatch) => {
  dispatch(getAdditionalInformation());
  try {
    const response = await engineGetAdditionalInformation(payload);
    if (response.status === 200) {
      const { data } = response.data;
      return dispatch(getAdditionalInformationSuccess(data));
    }
    return dispatch(getAdditionalInformationError());
  } catch {
    return dispatch(getAdditionalInformationError());
  }
};

interface AllowedPeopleGTMProps {
  gtmProps: {
    cartId: string;
    contentType: string;
  };
}

const asyncSetAllowedPeople =
  ({ gtmProps, ...payload }: AllowedPeoplePayload & AllowedPeopleGTMProps) =>
  async (dispatch: Dispatch) => {
    dispatch(AllowedPeopleRequestMethod());
    try {
      const response = await enginePostAllowedPeople(payload);
      if (response.status === 200) {
        const { data } = response.data;
        const { freightIndex, name, document, relationship } = payload;
        const allowedPeople = { index: freightIndex, name, document, relationship };
        useAllowedPeople({
          ...gtmProps,
          flow: `entrega:${+freightIndex + 1}`,
        });
        return dispatch(setAllowedPeopleMethodSuccess({ data, allowedPeople }));
      }
      return dispatch(AllowedPeopleRequestMethodError());
    } catch {
      return dispatch(AllowedPeopleRequestMethodError());
    }
  };

const asyncPutAllowedPeople =
  ({ gtmProps, ...payload }: AllowedPeoplePayload & AllowedPeopleGTMProps) =>
  async (dispatch: Dispatch) => {
    dispatch(AllowedPeopleRequestMethod());
    try {
      const response = await enginePuttAllowedPeople(payload);
      if (response.status === 200) {
        const { data } = response.data;
        const { freightIndex, name, document, relationship } = payload;
        const allowedPeople = { index: freightIndex, name, document, relationship };
        useAllowedPeople({
          ...gtmProps,
          flow: `entrega:${+freightIndex + 1}`,
        });
        return dispatch(setAllowedPeopleMethodSuccess({ data, allowedPeople }));
      }
      return dispatch(AllowedPeopleRequestMethodError());
    } catch {
      return dispatch(AllowedPeopleRequestMethodError());
    }
  };

const asyncRemoveAllowedPeople =
  ({ gtmProps, ...payload }: AllowedPeopleDeletePayload & AllowedPeopleGTMProps) =>
  async (dispatch: Dispatch) => {
    dispatch(AllowedPeopleRequestMethod());
    try {
      const response = await engineDeleteAllowedPeople(payload);
      if (response.status === 200) {
        const { data } = response.data;
        const { freightIndex } = payload;
        useAllowedPeople({
          ...gtmProps,
          flow: `entrega:${+freightIndex + 1}`,
        });
        return dispatch(deleteAllowedPeopleMethodSuccess({ data, index: freightIndex }));
      }
      return dispatch(AllowedPeopleRequestMethodError());
    } catch {
      return dispatch(AllowedPeopleRequestMethodError());
    }
  };

export {
  asyncMergeCart,
  asyncGetCart,
  asyncGetMultiCart,
  asyncAddProductToCart,
  asyncRemoveProductFromCart,
  asyncPatchDeliveryMethod,
  asyncGetAdditionalInformation,
  asyncSetAllowedPeople,
  asyncPutAllowedPeople,
  asyncRemoveAllowedPeople,
};
