import { CHECKOUT_ERRORS } from 'modules/Checkout/helpers';
import { Types } from '../checkout';

export const getCheckout = (payload) => ({
  type: Types.GET_CHECKOUT,
  payload,
});

export const postCoupon = () => ({
  type: Types.POST_COUPON,
});

export const postCouponSuccess = (data: any) => ({
  type: Types.POST_COUPON_SUCCESS,
  payload: { data },
});

export const postCouponError = (data?: any) => ({
  type: Types.POST_COUPON_ERROR,
  payload: data,
});

export const deleteCoupon = () => ({
  type: Types.DELETE_COUPON,
});

export const deleteCouponSuccess = (payload: any) => ({
  type: Types.DELETE_COUPON_SUCCESS,
  payload,
});

export const deleteCouponError = (payload?: any) => ({
  type: Types.DELETE_COUPON_ERROR,
  payload,
});

export const resetCouponStatus = () => ({
  type: Types.RESET_COUPON_STATUS,
});

export const setPaymentMethod = (payload: any) => ({
  type: Types.SET_PAYMENT_METHOD,
  payload,
});

export const postPaymentPlan = (payload: any) => ({
  type: Types.POST_PAYMENT_PLAN,
  payload,
});

export const postPaymentPlanSuccess = (payload: any) => ({
  type: Types.POST_PAYMENT_PLAN_SUCCESS,
  payload,
});

export const setCreditCardInfo = (payload: any) => ({
  type: Types.SET_CREDIT_CARD_INFO,
  payload,
});

export const postPaymentPlanError = () => ({
  type: Types.POST_PAYMENT_PLAN_ERROR,
});

export const putPayment = (payload: any) => ({
  type: Types.PUT_PAYMENT,
  payload,
});

export const putPaymentSuccess = (payload: any) => ({
  type: Types.PUT_PAYMENT_SUCCESS,
  payload,
});

export const putPaymentError = () => ({
  type: Types.PUT_PAYMENT_ERROR,
});

export const postOrder = () => ({
  type: Types.POST_ORDER,
});

export const postOrderSuccess = (payload: any) => ({
  type: Types.POST_ORDER_SUCCESS,
  payload,
});

export const postOrderError = (errorType: CHECKOUT_ERRORS, data) => ({
  type: Types.POST_ORDER_ERROR,
  payload: {
    errorType,
    data,
  },
});

export const resetAccessToken = () => ({
  type: Types.RESET_ACCESS_TOKEN,
});

export const resetPaymentMethodError = () => ({
  type: Types.RESET_PAYMENT_METHOD_ERROR,
});

export const resetPaymentMethod = () => ({
  type: Types.RESET_PAYMENT_METHOD,
});

export const resetCartAndShipping = () => ({
  type: Types.RESET_CART_AND_SHIPPING,
});

export const resetPromotion = () => ({
  type: Types.RESET_PROMOTION,
});

export const resetCheckoutStatus = () => ({
  type: Types.RESET_CHECKOUT_STATUS,
});

export const resetOrderError = () => ({
  type: Types.RESET_ORDER_ERROR,
});

export const postCsat = () => ({
  type: Types.POST_CSAT,
});

export const postCsatSuccess = () => ({
  type: Types.POST_CSAT_SUCCESS,
});

export const postCsatError = () => ({
  type: Types.POST_CSAT_ERROR,
});

export const postExchangeVoucher = () => ({
  type: Types.POST_EXCHANGE_VOUCHER,
});

export const postExchangeVoucherSuccess = (payload: any, voucher: string) => ({
  type: Types.POST_EXCHANGE_VOUCHER_SUCCESS,
  payload: { data: payload, voucher },
});

export const postExchangeVoucherError = (errorType: CHECKOUT_ERRORS, data) => ({
  type: Types.POST_EXCHANGE_VOUCHER_ERROR,
  payload: {
    errorType,
    data,
  },
});

export const deleteExchangeVoucher = () => ({
  type: Types.DELETE_EXCHANGE_VOUCHER,
});

export const deleteExchangeVoucherSuccess = (payload: any) => ({
  type: Types.DELETE_EXCHANGE_VOUCHER_SUCCESS,
  payload,
});

export const deleteExchangeVoucherError = (errorType: CHECKOUT_ERRORS, data) => ({
  type: Types.DELETE_EXCHANGE_VOUCHER_ERROR,
  payload: {
    errorType,
    data,
  },
});

export const postGiftCard = () => ({
  type: Types.POST_GIFT_CARD,
});

export const postGiftCardSuccess = (payload: any, cardNumber?: string) => ({
  type: Types.POST_GIFT_CARD_SUCCESS,
  payload: { data: payload, cardNumber },
});

export const postGiftCardError = (errorType: CHECKOUT_ERRORS, data) => ({
  type: Types.POST_GIFT_CARD_ERROR,
  payload: {
    errorType,
    data,
  },
});

export const deleteGiftCard = () => ({
  type: Types.DELETE_GIFT_CARD,
});

export const deleteGiftCardSuccess = (payload: any) => ({
  type: Types.DELETE_GIFT_CARD_SUCCESS,
  payload,
});

export const deleteGiftCardError = (errorType: CHECKOUT_ERRORS, data) => ({
  type: Types.DELETE_GIFT_CARD_ERROR,
  payload: {
    errorType,
    data,
  },
});
