import { styled } from 'utils/styles';
import { Box, Price } from 'utils/shared/components';
import { colors } from 'utils/styles/tokens';

export const Wrapper = styled(Box, {
  d: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto 1fr auto',
  gap: '20px',
  cursor: 'pointer',
});

export const RadioWrapper = styled('div', {
  d: 'grid',
  ai: 'center',
});

export const ContentWrapper = styled('div', {
  d: 'grid',
});

export const ContentStoreName = styled('h3', {
  typo: 'body2',
  color: '$black',
  userSelect: 'none',
});

export const PriceWrapper = styled('div', {
  d: 'grid',
  ai: 'center',
});

export const PriceValue = styled(Price, {
  typo: 'heading3',
  userSelect: 'none',
});

export const changeStoreButton = styled('button', {
  backgroundColor: 'white',
  fontSize: '10px',
  border: `1px solid ${colors.blue}`,
  width: '160px',
  height: '24px',
  color: colors.blue,
  borderRadius: '4px',
});
