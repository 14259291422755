import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../ducks/types';

const BREAKED_DELIVERY = 'BREAKED';

const shippingIsAvailable = (shippingGroup) => shippingGroup.origin !== BREAKED_DELIVERY;

export const selectQrCode = (state: IRootState) => state.qrCodeState;
export const selectUser = (state: IRootState) => state.authCustomerState;
export const selectCart = (state: IRootState) => state.cartState;
export const selectCheckout = (state: IRootState) => state.checkoutState;
export const selectUserAddresses = (state: IRootState) => state.addressState;
export const selectAppConfig = (state: IRootState) => state.appConfigState;
export const selectGenericError = (state: IRootState) => state.genericError;
const selectState = (state: IRootState) => state;

export const selectShippingGroups = createSelector(selectCart, (cartState) => cartState.shippingGroups);

export const selectShippingAvailable = createSelector(selectShippingGroups, (shippingGroups) => {
  const hasShipping = shippingGroups?.some(shippingIsAvailable) || false;
  const availableShippingGroups = shippingGroups?.filter(shippingIsAvailable) || [];

  return {
    hasShipping,
    availableShippingGroups,
  };
});

export const selectShippingBreak = createSelector(selectShippingGroups, (shippingGroups) => {
  const hasRupture = shippingGroups?.some((group) => !shippingIsAvailable(group)) || false;
  const ruptureShippingGroups = shippingGroups?.filter((group) => !shippingIsAvailable(group)) || [];

  return {
    hasRupture,
    ruptureShippingGroups,
  };
});

export const selectProducts = createSelector(selectCart, (cartState) => {
  const seller = cartState?.cart?.seller;
  if (!seller) {
    return null;
  }
  const products: any[] = [];
  seller.forEach(({ seller, items }) => {
    items.forEach(({ product, ...rest }) => {
      products.push({ ...product, ...rest, seller });
    });
  });

  return products;
});

export const selectGlobalState = createSelector(selectState, (globalState) => {
  return { ...globalState.checkoutState, ...globalState.cartState };
});
