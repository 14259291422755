import { ActionType, AuthType } from './types';
import { engineLogin, engineLoginListTokens, engineLoginRemoveToken } from './../engine/index';

import { Dispatch } from 'redux';
import { GlobalTypes } from './global';
import {
  LoginPayload,
  ListTokensPayload,
  AsyncResetPassword,
  AsyncChangePassword,
  AsyncRemoveTokenPayload,
} from 'store/engine/types';
import { useGtagExceptionLogin } from 'libs/gtag';
import { getPaymentMethods } from './appConfig';
import { useLoginMSite, useSelectContentDispatch } from '@estore/gtm-v4';
import { sanitizeText } from 'utils/functions/analytics/helpers';
import sha1Encrypt from 'utils/functions/encrypt';
import { estoreApi } from '../../utils/config/api';

export const AUTH_CUSTOMER_ERROR_MESSAGE = {
  RECAPTCHA: 'Falha ao executar captcha. Tente novamente',
  AUTHENTICATION: 'CPF ou senha inválidos',
};

// Action Types
export const Types = {
  AUTH_CUSTOMER: 'auth/AUTH_CUSTOMER',
  AUTH_CUSTOMER_SUCCESS: 'auth/AUTH_CUSTOMER_SUCCESS',
  AUTH_CUSTOMER_ERROR: 'auth/AUTH_CUSTOMER_ERROR',
  AUTH_CUSTOMER_LOGOUT: 'auth/AUTH_CUSTOMER_LOGOUT',

  AUTH_CUSTOMER_RESET_PASSWORD: 'auth/AUTH_CUSTOMER_RESET_PASSWORD',
  AUTH_CUSTOMER_RESET_PASSWORD_SUCCESS: 'auth/AUTH_CUSTOMER_RESET_PASSWORD_SUCCESS',
  AUTH_CUSTOMER_RESET_PASSWORD_ERROR: 'auth/AUTH_CUSTOMER_RESET_PASSWORD_ERROR',

  AUTH_CUSTOMER_CHANGE_PASSWORD: 'auth/AUTH_CUSTOMER_CHANGE_PASSWORD',
  AUTH_CUSTOMER_CHANGE_PASSWORD_SUCCESS: 'auth/AUTH_CUSTOMER_CHANGE_PASSWORD_SUCCESS',
  AUTH_CUSTOMER_CHANGE_PASSWORD_ERROR: 'auth/AUTH_CUSTOMER_CHANGE_PASSWORD_ERROR',

  GET_NEW_SESSION_ID: 'auth/GET_NEW_SESSION_ID',
  GET_NEW_SESSION_ID_SUCCESS: 'auth/GET_NEW_SESSION_ID_SUCCESS',
  GET_NEW_SESSION_ID_ERROR: 'auth/GET_NEW_SESSION_ID_ERROR',

  UPDATE_AUTH_TOKEN: 'auth/UPDATE_AUTH_TOKEN',

  AUTH_CUSTOMER_RESET_STATE: 'auth/AUTH_CUSTOMER_RESET_STATE',

  GET_LIST_TOKENS: 'auth/GET_LIST_TOKENS',
  GET_LIST_TOKENS_SUCCESS: 'auth/GET_LIST_TOKENS_SUCCESS',
  GET_LIST_TOKENS_ERROR: 'auth/GET_LIST_TOKENS_ERROR',

  REMOVE_TOKEN: 'auth/REMOVE_TOKEN',
  REMOVE_TOKEN_SUCCESS: 'auth/REMOVE_TOKEN_SUCCESS',
  REMOVE_TOKEN_ERROR: 'auth/REMOVE_TOKEN_ERROR',
};

const initialState: AuthType = {
  changePasswordStatus: 'idle',
  changePasswordMessage: '',
  isAuthenticated: false,
  status: 'idle',
  authToken: '',
  tokens: [],
};

export const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    // AUTH CUSTOMER
    case Types.AUTH_CUSTOMER:
      return { ...state, status: 'pending' };
    case Types.AUTH_CUSTOMER_SUCCESS:
      return {
        ...state,
        status: 'success',
        document: action.payload.customerDocument,
        isAuthenticated: true,
        authToken: action.payload.authToken,
      };
    case Types.AUTH_CUSTOMER_ERROR:
      return { ...state, status: 'error', errorMessage: action.payload };

    // RESET PASSWORD
    case Types.AUTH_CUSTOMER_RESET_PASSWORD:
      return { ...state, status: 'pending' };
    case Types.AUTH_CUSTOMER_RESET_PASSWORD_SUCCESS:
      return { ...state, status: 'success', resetPassword: action.payload };
    case Types.AUTH_CUSTOMER_RESET_PASSWORD_ERROR:
      return { ...state, status: 'error' };

    // RESET PASSWORD
    case Types.AUTH_CUSTOMER_CHANGE_PASSWORD:
      return { ...state, changePasswordStatus: 'pending' };
    case Types.AUTH_CUSTOMER_CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordStatus: 'success' };
    case Types.AUTH_CUSTOMER_CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordStatus: 'error', changePasswordMessage: action.payload };

    // GET NEW SESSION ID
    case Types.GET_NEW_SESSION_ID:
      return { ...state, status: 'pending' };
    case Types.GET_NEW_SESSION_ID_SUCCESS:
      return { ...state, status: 'success', sessionId: action.payload };
    case Types.GET_NEW_SESSION_ID_ERROR:
      return { ...state, status: 'error' };

    // UPDATE AUTH TOKEN
    case Types.UPDATE_AUTH_TOKEN:
      return { ...state, authToken: action.payload };

    // GET LIST TOKENS (CC)
    case Types.GET_LIST_TOKENS:
      return { ...state, status: 'pending' };
    case Types.GET_LIST_TOKENS_SUCCESS:
      return { ...state, status: 'success', tokens: action.payload };
    case Types.GET_LIST_TOKENS_ERROR:
      return { ...state, status: 'success', tokens: [] };

    case Types.REMOVE_TOKEN:
      return { ...state, status: 'pending' };
    case Types.REMOVE_TOKEN_SUCCESS:
      return { ...state, status: 'success', tokens: action.payload };
    case Types.REMOVE_TOKEN_ERROR:
      return { ...state, status: 'error' };

    // RESET STATE
    case Types.AUTH_CUSTOMER_RESET_STATE:
    case GlobalTypes.GLOBAL_RESET:
      return { ...initialState };
    default:
      return state;
  }
};

const authCustomer = () => ({ type: Types.AUTH_CUSTOMER });

const authCustomerSuccess = (data: { data: { authToken: string | undefined } }, customerDocument?: string) => {
  const { authToken } = data.data;
  return {
    type: Types.AUTH_CUSTOMER_SUCCESS,
    payload: { customerDocument, authToken },
  };
};

const authCustomerError = (errorMessage: string) => ({ type: Types.AUTH_CUSTOMER_ERROR, payload: errorMessage });

const updateAuthToken = (authToken) => ({ type: Types.UPDATE_AUTH_TOKEN, payload: authToken });

const authCustomerResetState = () => ({ type: Types.AUTH_CUSTOMER_RESET_STATE });

const authCustomerResetPassword = () => ({ type: Types.AUTH_CUSTOMER_RESET_PASSWORD });

const authCustomerResetPasswordSuccess = (resetPassword) => ({
  type: Types.AUTH_CUSTOMER_RESET_PASSWORD_SUCCESS,
  payload: resetPassword,
});

const authCustomerResetPasswordError = () => ({ type: Types.AUTH_CUSTOMER_RESET_PASSWORD_ERROR });

const authCustomerChangePasswordStatus = () => ({ type: Types.AUTH_CUSTOMER_CHANGE_PASSWORD });
const authCustomerChangePasswordSuccess = () => ({ type: Types.AUTH_CUSTOMER_CHANGE_PASSWORD_SUCCESS });
const authCustomerChangePasswordError = (payload) => ({ type: Types.AUTH_CUSTOMER_CHANGE_PASSWORD_ERROR, payload });

function getListTokens() {
  return {
    type: Types.GET_LIST_TOKENS,
  };
}
function getListTokensSuccess(data) {
  return {
    type: Types.GET_LIST_TOKENS_SUCCESS,
    payload: data?.data?.tokens.map(({ maskedNumber, ...rest }) => ({
      ...rest,
      maskedNumber: maskedNumber.replaceAll('x', '*'),
    })),
  };
}
function getListTokensError() {
  return {
    type: Types.GET_LIST_TOKENS_ERROR,
  };
}

function removeCard() {
  return {
    type: Types.REMOVE_TOKEN,
  };
}

function removeCardError() {
  return {
    type: Types.REMOVE_TOKEN_ERROR,
  };
}

const asyncAuthCustomer =
  ({ store, cartId, ...rest }: LoginPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(authCustomer());
    try {
      const response = await engineLogin({ ...rest });
      const { data } = response;
      if (data.success) {
        useLoginMSite({
          store: store,
          method: 'msite',
          cart_id: cartId,
        });
        dispatch(authCustomerSuccess(data, rest.cpf));
        dispatch(getPaymentMethods(data));
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.errorMessages?.[0]?.code === 'GoogleAuthenticationInvalidTokenError'
          ? AUTH_CUSTOMER_ERROR_MESSAGE.RECAPTCHA
          : AUTH_CUSTOMER_ERROR_MESSAGE.AUTHENTICATION;
      useGtagExceptionLogin({
        description: errorMessage,
        device_type: 'msite',
        flow: 'login',
        fatal: false,
      });
      dispatch(authCustomerError(errorMessage));
    }
  };

const asyncAuthCustomerResetPassword =
  ({ document, token, code }: AsyncResetPassword) =>
  async (dispatch: Dispatch) => {
    dispatch(authCustomerResetPassword());
    try {
      const response = await estoreApi.put('/v2/customers/reset-password', { document, token, code });
      const { data } = response.data;
      if (data) {
        useSelectContentDispatch({
          content_type: 'Redefinir minha senha',
          flow: 'login:esqueceu-a-senha',
          device_type: 'msite',
        });
        dispatch(authCustomerResetPasswordSuccess(data));
      }
    } catch {
      useGtagExceptionLogin({
        description: 'Erro ao redefinir senha',
        flow: 'login:esqueceu-a-senha',
        device_type: 'msite',
        fatal: false,
      });
      dispatch(authCustomerResetPasswordError());
    }
  };

const asyncAuthCustomerChangePassword = (values: AsyncChangePassword) => async (dispatch: Dispatch) => {
  dispatch(authCustomerChangePasswordStatus());
  try {
    await estoreApi.put('/v2/customers/change-password', { ...values });
    useSelectContentDispatch({ content_type: 'Salvar', flow: 'login:redefinir-senha', device_type: 'msite' });
    dispatch(authCustomerChangePasswordSuccess());
  } catch (error: any) {
    const message = error?.response?.data?.errorMessages?.[0]?.message || '';
    useGtagExceptionLogin({
      description: message || 'Erro ao salvar nova senha',
      flow: 'login:redefinir-senha',
      device_type: 'msite',
      fatal: false,
    });
    dispatch(authCustomerChangePasswordError(message));
  }
};

function asyncGetListTokens(payload: ListTokensPayload, signal?: AbortSignal) {
  return async function (dispatch: Dispatch) {
    dispatch(getListTokens());
    try {
      const response = await engineLoginListTokens(payload, signal);
      const { data } = response;
      if (data.success) {
        dispatch(getListTokensSuccess(data));
      }
    } catch {
      dispatch(getListTokensError());
    }
  };
}

function asyncRemoveToken({ token, userId, cartId, ...rest }: AsyncRemoveTokenPayload, signal?: AbortSignal) {
  return async function (dispatch: Dispatch) {
    dispatch(removeCard());
    try {
      const response = await engineLoginRemoveToken({ token, ...rest }, signal);
      const { data } = response;
      if (data.success) {
        dispatch(getListTokensSuccess(data));
        useSelectContentDispatch({
          content_type: 'cartao excluido com sucesso',
          flow: 'checkout:msite',
          userId: sha1Encrypt(userId)?.toString(),
          cartId: sanitizeText(cartId),
        });
      }
    } catch {
      dispatch(removeCardError());
    }
  };
}

export {
  authCustomer,
  authCustomerError,
  asyncAuthCustomer,
  authCustomerResetState,
  asyncAuthCustomerResetPassword,
  asyncAuthCustomerChangePassword,
  authCustomerChangePasswordStatus,
  authCustomerChangePasswordError,
  updateAuthToken,
  authCustomerSuccess,
  asyncGetListTokens,
  asyncRemoveToken,
  removeCard,
  removeCardError,
};
