import { styled } from 'utils/styles';

export const ModalWrapper = styled('div', {
  p: '20px 16px',

  '> p': {
    textAlign: 'center',
    mb: '8px',
  },
});
