import { ChangeEvent, ReactNode, useMemo } from 'react';
import { RemainingUnitsUseCase } from '@estore/business-layer';

import { RemainingUnits } from 'utils/shared/components';
import { QuantitySelector } from '../QuantitySelector';
import { ReactComponent as TrashIcon } from 'utils/assets/images/svg/trash.svg';
import { colors } from 'utils/styles/tokens/colors';
import * as S from './styles';

interface Variation {
  name: string;
  value: string;
}

export interface Product {
  sku: string;
  skuGroup: string;
  title: string;
  image?: string;
  salePrice: number;
  listPrice: number;
  percentageDiscount?: number;
  soldOut: boolean;
  remainingUnits: number;
  variations: Variation[];
  medias?: any[];
  tags?: any[];
}

interface ProductProps {
  children?: ReactNode;
  product: Product;
  quantity: number;
  max: number;
  disableQty: boolean;
  onDecrease?: () => void;
  onIncrease?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  onSetValue?: any;
  isResume?: boolean;
}

export const ProductCard = ({
  children,
  product,
  quantity,
  max,
  disableQty,
  onDecrease,
  onIncrease,
  onChange,
  onRemove,
  onSetValue,
  isResume,
}: ProductProps) => {
  const { title, variations, salePrice, listPrice, soldOut, percentageDiscount, remainingUnits } = product;
  const message = useMemo(() => {
    const messages = { 10: '#QTT# Unidades em estoque' };
    const findedMessage = RemainingUnitsUseCase.execute({ remainingUnits, messages });
    return findedMessage;
  }, [remainingUnits]);

  const handleRemove = () => {
    if (typeof onRemove === 'function') {
      onRemove();
    }
  };

  return (
    <S.Container>
      <S.Row
        css={{
          d: 'grid',
          gridTemplateColumns: 'min-content 1fr min-content',
          ai: 'start',
          gridGap: '$spacing8',
        }}
      >
        <S.Image
          src={product?.image || `${product?.medias?.[0]?.urlSquare}?imwidth=80`}
          width={80}
          height={80}
          alt={product.title}
        />
        <div>
          {product.tags?.length ? (
            <span style={{ color: colors.blue, fontSize: '10px', textTransform: 'uppercase', fontWeight: '500' }}>
              {product.tags[0].text}
            </span>
          ) : null}
          <S.Title>{title}</S.Title>
          <S.Variations>
            {variations.map(({ name, value }) => (
              <S.VariationItem key={`${name}-${value}`}>
                {name}: {value}
              </S.VariationItem>
            ))}
            <>{quantity && !onDecrease && !onIncrease && <S.VariationItem>Qtd: {quantity}</S.VariationItem>}</>
            {!isResume && message && <RemainingUnits message={message} />}
          </S.Variations>
        </div>
        {typeof onRemove === 'function' && (
          <S.Button onClick={handleRemove}>
            <TrashIcon />
          </S.Button>
        )}
      </S.Row>

      <S.Row
        css={{
          ai: 'center',
          d: 'flex',
          jc: onDecrease && onIncrease ? 'space-between' : 'end',
          mt: !onDecrease && !onIncrease ? '0' : '$4',
          mb: children ? '$3' : '$0',
        }}
      >
        {quantity && typeof onDecrease === 'function' && typeof onIncrease === 'function' && (
          <QuantitySelector
            quantity={quantity}
            label="Qte"
            max={max}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            onChange={onChange}
            disabled={soldOut || disableQty}
            onSetValue={onSetValue}
            product={product}
          />
        )}
        <S.PriceContainer>
          {quantity && salePrice > 0 && salePrice < listPrice ? (
            <>
              <div>
                <S.SalePrice>{quantity * listPrice}</S.SalePrice>
                {percentageDiscount && percentageDiscount > 0 ? (
                  <>
                    &nbsp;
                    <S.DiscountPercentage>{`(${(percentageDiscount * 100).toFixed(0)}% off)`}</S.DiscountPercentage>
                  </>
                ) : null}
                <></>
              </div>
              <S.Price prefix="por" style={{ color: '#C6352F' }}>
                {quantity * salePrice}
              </S.Price>
            </>
          ) : (
            <S.Price>{quantity * listPrice}</S.Price>
          )}
        </S.PriceContainer>
      </S.Row>
      {children && <S.Row>{children}</S.Row>}
    </S.Container>
  );
};

ProductCard.defaultProps = {
  disableQty: false,
};
