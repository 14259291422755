import { styled } from 'utils/styles';

import { Box as BoxComponent, Typography } from 'utils/shared/components';

export const Box = styled(BoxComponent, {
  padding: 0,
  minWidth: '120px',
});

export const IconContainer = styled('div', {
  d: 'flex',
  padding: '$2 $3',
});

export const Button = styled('button', {
  alignItems: 'center',
  background: 'transparent',
  border: '0 none',
  cursor: 'pointer',
  display: 'flex',
  padding: '$3 $3 $3 $3',
  width: '100%',
});

export const ButtonLabel = styled(Typography, {
  marginLeft: '$3',
});
