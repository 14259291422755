export const space = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '1rem',
  4: '1.5rem',
  5: '2rem',
  spacing0: '0',
  spacing4: '0.25rem',
  spacing8: '0.5rem',
  spacing12: '0.75rem',
  spacing16: '1rem',
  spacing20: '1.25rem',
  spacing24: '1.5rem',
  spacing32: '2rem',
};
