import { useSelectContentDispatch, type EventSelectContentCsat } from '@estore/gtm-v4';
import { type RecoveryCart } from 'types';
import { useException } from './events';
import { getReviewCsat } from './utils';
import { getStoreName } from './utils/text/getStoreName';

type SelectContentCsatProps = {
  review: number;
  recoveryCart: RecoveryCart | undefined;
  uuid: string | undefined;
};

export const useGtagCsat = () => {
  const useSelectContentCsat = ({ recoveryCart, review, uuid }: SelectContentCsatProps) => {
    return useSelectContentDispatch<EventSelectContentCsat>({
      content_type: 'csat:enviar',
      csat: getReviewCsat(review),
      flow: 'csat',
      qr_code: recoveryCart?.code,
      registration_id: String(recoveryCart?.sellerId),
      store: getStoreName(recoveryCart?.storePrefix, recoveryCart?.storeName),
      user_id: uuid,
    });
  };

  const useExceptionCsat = () => {
    return useException({
      description: 'Ocorreu um erro ao salvar pesquisa de avaliação',
      fatal: false,
      flow: 'checkout:csat',
    });
  };

  return { useSelectContentCsat, useExceptionCsat };
};
