type TypoVariants = 'body1' | 'body2' | 'heading1' | 'heading2' | 'heading3' | 'regHeading2' | 'boldHeading3';

export const utils = {
  d: (value) => ({
    display: value,
  }),
  br: (value) => ({
    borderRadius: value,
  }),
  m: (value) => ({
    margin: value,
  }),
  mt: (value) => ({
    marginTop: value,
  }),
  mr: (value) => ({
    marginRight: value,
  }),
  mb: (value) => ({
    marginBottom: value,
  }),
  ml: (value) => ({
    marginLeft: value,
  }),
  mx: (value) => ({
    marginLeft: value,
    marginRight: value,
  }),
  my: (value) => ({
    marginTop: value,
    marginBottom: value,
  }),
  p: (value) => ({
    padding: value,
  }),
  pt: (value) => ({
    paddingTop: value,
  }),
  pr: (value) => ({
    paddingRight: value,
  }),
  pb: (value) => ({
    paddingBottom: value,
  }),
  pl: (value) => ({
    paddingLeft: value,
  }),
  px: (value) => ({
    paddingRight: value,
    paddingLeft: value,
  }),
  py: (value) => ({
    paddingTop: value,
    paddingBottom: value,
  }),
  ai: (value) => ({
    alignItems: value,
  }),
  jc: (value) => ({
    justifyContent: value,
  }),
  bg: (value) => ({
    backgroundColor: value,
  }),
  bs: (value) => ({
    boxShadow: value,
  }),
  typo: (variant: TypoVariants) => {
    let typoDefinitions;

    switch (variant) {
      case 'body1':
        typoDefinitions = {
          fontSize: '0.875rem',
          fontWeight: '400',
          lineHeight: '1.125rem',
        };
        break;

      case 'body2':
        typoDefinitions = {
          fontSize: '0.75rem',
          fontWeight: '350',
          lineHeight: '1rem',
        };
        break;

      case 'heading1':
        typoDefinitions = {
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.25',
        };
        break;

      case 'heading2':
        typoDefinitions = {
          fontSize: '0.875rem',
          fontWeight: '400',
          lineHeight: '1.125',
        };
        break;

      case 'regHeading2':
        typoDefinitions = {
          fontSize: '0.875rem',
          fontWeight: '350',
          lineHeight: '1.125',
        };
        break;

      case 'boldHeading3':
        typoDefinitions = {
          fontSize: '0.75rem',
          fontWeight: '500',
          lineHeight: '1rem',
        };
        break;

      case 'heading3':
        typoDefinitions = {
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '1rem',
        };
        break;
    }

    return typoDefinitions;
  },
};
