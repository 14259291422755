import { addMonths, addYears, getMonth, getYear, toDate } from 'date-fns';
import { Payment } from 'types';

export enum CHECKOUT_ERRORS {
  SERVICE_OFFLINE = 404,
  SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  PAYMENT_FAILURE = 402,
  RUPTURE = 206,
  SESSION_EXPIRED = 204,
  UNEXPECTED = 999,
  UNEXPECTED_MESSAGE = 'Unexpected error has occured',
  INVALID_EMAIL = 'E-mail é inválido',
  INVALID_NAME = 'Nome é inválido',
  INVALID_DOB = 'Data de nascimento é inválida',
  INVALID_ADDRESS_DDD = 'Endereço inválido, DDD é obrigatório',
  INVALID_ADDRESS_PHONE = 'Endereço inválido, telefone é obrigatório',
}

const userErrors = [
  CHECKOUT_ERRORS.INVALID_EMAIL,
  CHECKOUT_ERRORS.INVALID_NAME,
  CHECKOUT_ERRORS.INVALID_DOB,
  CHECKOUT_ERRORS.INVALID_ADDRESS_DDD,
  CHECKOUT_ERRORS.INVALID_ADDRESS_PHONE,
];

export const hasAnyUserError = (errorMessages: { message: CHECKOUT_ERRORS }[]) =>
  !!errorMessages.find(({ message, ...rest }) => (userErrors.includes(message) ? { message, ...rest } : undefined));

/**
 * A function to generate expiration Date for RchloCard, ever actual month and next year
 * @returns {
 *  `cardExpirationMonth`: actual month;
 *  `cardExpirationYear`: next year;
 *  `cardValidThru`: date in the format mm/yyyy
 * }
 */
export function getRchloCardExpiration() {
  const date = toDate(new Date());

  const cardExpirationMonth = getMonth(addMonths(date, 2)).toString().padStart(2, '0');
  const cardExpirationYear = getYear(addYears(date, 1)).toFixed().toString();

  return {
    cardExpirationMonth,
    cardExpirationYear,
    cardValidThru: `${cardExpirationMonth}/${cardExpirationYear}`,
  };
}

type hasCardPaymentSetParams = {
  payments?: Payment[] | undefined;
  creditCardInfo: unknown;
};

export function hasCardPaymentSet({ payments, creditCardInfo }: hasCardPaymentSetParams) {
  const hasPaymentSet = !!payments?.find(
    (method) => method.paymentMethod === 'RchloCard' || method.paymentMethod === 'CreditCard',
  );
  const hasCreditCardInfo = creditCardInfo !== null;
  return hasPaymentSet && hasCreditCardInfo;
}
