import { forwardRef, ReactNode } from 'react';
import type { ComponentProps } from 'react';
import { RadioButton } from 'utils/shared/components';
import { isFree } from 'utils/functions/currency';
import * as S from './DeliveryOption.styles';
import Content from './components/Content';

export interface IDeliveryOptionProps extends Omit<ComponentProps<typeof S.Wrapper>, 'children'> {
  title: string;
  description: ReactNode;
  price: string | number;
  value: string;
  checked: boolean;
  storeNameByBopisOrPickUpStore?: string;
}

export const DeliveryOption = forwardRef<HTMLDivElement, IDeliveryOptionProps>(
  ({ title, description, price, value, checked, storeNameByBopisOrPickUpStore, ...rest }, ref) => {
    return (
      <S.Wrapper ref={ref} {...rest}>
        <S.RadioWrapper>
          <RadioButton value={value} checked={checked} />
        </S.RadioWrapper>
        <S.ContentWrapper>
          {!storeNameByBopisOrPickUpStore ? (
            <Content title={title} description={description} />
          ) : (
            (checked && (
              <Content
                title={title}
                description={description}
                lineX={<S.ContentStoreName>{storeNameByBopisOrPickUpStore}</S.ContentStoreName>}
                lineY={<S.changeStoreButton>ALTERAR LOJA</S.changeStoreButton>}
              />
            )) || <Content title={title} description={description} />
          )}
        </S.ContentWrapper>
        <S.PriceWrapper>
          <S.PriceValue variant={isFree(price) ? 'discount' : undefined}>{price}</S.PriceValue>
        </S.PriceWrapper>
      </S.Wrapper>
    );
  },
);

DeliveryOption.displayName = 'DeliveryOption';
