import { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import * as S from './Backdrop.styles';

export interface IBackdropProps extends Omit<ComponentProps<typeof S.Wrapper>, 'children'> {
  theme?: 'light' | 'dark';
}

export const Backdrop = forwardRef<HTMLDivElement, IBackdropProps>((props, ref) => <S.Wrapper ref={ref} {...props} />);

Backdrop.displayName = 'Backdrop';

Backdrop.defaultProps = {
  theme: 'dark',
};
