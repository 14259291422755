import { isFlagActive } from './helpers';

/**
 * Credit Card Info
 */
const RCHLO_CARD_CVV = process.env?.REACT_APP_RIACHUELO_CREDITCARD_CVV || '';
const RCHLO_CARD_EXPIRATION_DATE = process.env?.REACT_APP_RIACHUELO_CREDITCARD_VALIDATE || '';
const [RCHLO_CARD_EXPIRATION_MONTH, RCHLO_CARD_EXPIRATION_YEAR] = RCHLO_CARD_EXPIRATION_DATE.split('/');

export { RCHLO_CARD_EXPIRATION_MONTH, RCHLO_CARD_EXPIRATION_YEAR, RCHLO_CARD_CVV };

export const PIX_ENABLED = isFlagActive('REACT_APP_FF_PIX');
export const EXCHANGE_CARD_ENABLED = isFlagActive('REACT_APP_FF_EXCHANGE_CARD');

export const SIGNALR_URL = process.env?.REACT_APP_SIGNALR_URL || '';
