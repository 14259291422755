import { forwardRef, useState } from 'react';
import * as S from './PurchaseSummary.styles';

import { ReactComponent as ArrowDownIcon } from 'utils/assets/images/svg/arrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'utils/assets/images/svg/arrowUp.svg';
import { isFree } from 'utils/functions/currency';
import { Button, Price } from 'utils/shared/components';

export interface IPurchaseSummaryItems {
  label: string;
  value?: string | number;
  type?: 'addition' | 'discount';
  enable?: boolean;
  group?: IPurchaseSummaryItems[] | any;
  index?: number;
}

export interface IPurchaseSummaryProps {
  items: IPurchaseSummaryItems[] | any;
  buttonLabel: string;
  onButtonClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const prefixMap = {
  addition: '+',
  discount: '-',
};

export const getSymbol = (value?: string | number, type?: IPurchaseSummaryItems['type'] | string): string | undefined => {
  if (isFree(value) || type === undefined) {
    return;
  }

  return type ? prefixMap[type] : type;
};

function PurchaseSummaryItem({ label, type, value = 0, index = 0, group = [] }: IPurchaseSummaryItems) {
  const hasGroup = group.length > 0;
  const [isOpen, setOpen] = useState(false);

  const handleOnOpen = () => {
    hasGroup && setOpen((prev) => !prev);
  };

  return (
    <>
      <S.TableRow type={type} group={hasGroup} onClick={handleOnOpen} open={isOpen}>
        <S.TableDataCell>{label}</S.TableDataCell>
        <S.TableDataCell>
          <Price label={label} prefix={index > 0 ? getSymbol(value, type) : undefined}>
            {value}
          </Price>
          {hasGroup && (
            <S.GroupButton onClick={handleOnOpen}>
              {!isOpen ? <ArrowDownIcon onClick={handleOnOpen} /> : <ArrowUpIcon onClick={handleOnOpen} />}
            </S.GroupButton>
          )}
        </S.TableDataCell>
      </S.TableRow>
      {hasGroup && isOpen && (
        <S.Table type="group">
          {group
            ?.filter(({ enable = false }: IPurchaseSummaryItems) => enable)
            ?.filter(({ label, value = 0 }: IPurchaseSummaryItems) => !(value === 0 && label === 'Desconto'))
            ?.map(({ label, value, type }) => (
              <S.TableRow type="group" key={label}>
                <S.TableDataCell colSpan={2}>{label}</S.TableDataCell>
                <S.TableDataCell colSpan={2}>
                  <Price label={label} prefix={getSymbol(value, type)}>
                    {value}
                  </Price>
                </S.TableDataCell>
              </S.TableRow>
            ))}
        </S.Table>
      )}
    </>
  );
}

export const PurchaseSummary = forwardRef<HTMLDivElement, IPurchaseSummaryProps>(
  ({ items = [], buttonLabel, onButtonClick, disabled = false, loading = false }, ref) => (
    <S.Wrapper ref={ref}>
      <S.Table>
        <tbody>
          {items
            .filter(({ enable = false }: IPurchaseSummaryItems) => enable)
            .filter(({ label, value = 0 }: IPurchaseSummaryItems) => !(value === 0 && label === 'Desconto'))
            .map(({ label, ...rest }, index: number) => (
              <PurchaseSummaryItem index={index} key={label} label={label} {...rest} />
            ))}
        </tbody>
      </S.Table>
      <S.ActionContainer>
        <Button onClick={onButtonClick} disabled={disabled} isLoading={loading}>
          {buttonLabel}
        </Button>
      </S.ActionContainer>
    </S.Wrapper>
  ),
);

PurchaseSummary.displayName = 'PurchaseSummary';
