import creditCardType, { getTypeInfo as cctGetTypeInfo, types as cctCardType } from 'credit-card-type';
import { CreditCardType } from './types';
import { CardTypes } from 'utils/shared/components/PaymentCard/constants';

const CARD_TYPES: { [x: string]: CardTypes } = {
  visa: 'visa',
  mastercard: 'mastercard',
  'american-express': 'american-express',
  'diners-club': 'diners-club',
  elo: 'elo',
  hipercard: 'hipercard',
  'riachuelo-visa': 'riachuelo-visa',
  'riachuelo-master': 'riachuelo-master',
  riachuelo: 'riachuelo',
};

creditCardType.addCard({
  niceType: 'Riachuelo Master',
  type: CARD_TYPES['riachuelo-master'],
  patterns: [536518, 523679],
  gaps: [4, 8, 12],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
});

creditCardType.addCard({
  niceType: 'Riachuelo Visa',
  type: CARD_TYPES['riachuelo-visa'],
  patterns: [482425, 407703, 405860],
  gaps: [4, 8, 12],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
});

creditCardType.addCard({
  niceType: 'American Express',
  type: CARD_TYPES['american-express'],
  patterns: [34, 37],
  gaps: [4, 10],
  lengths: [15],
  code: {
    name: 'CID',
    size: 4,
  },
});

const RIACHUELO_CARD_TYPE: CreditCardType = {
  niceType: 'Riachuelo',
  type: CARD_TYPES.riachuelo,
  patterns: [0],
  gaps: [5, 8, 11],
  lengths: [14],
  code: {
    name: 'CVV',
    size: 3,
  },
};

export { creditCardType, cctGetTypeInfo, cctCardType, CARD_TYPES, RIACHUELO_CARD_TYPE };
