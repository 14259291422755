import type { Seller, Product } from '@estore/types';
import { removeDuplicateTerm, getValue } from './utils';

/**
 * Função que obtém a tag e formata para string.
 * @param product Item de carrinho.
 * @returns Tag formatado
 */
export function createItemProductTag(product: Product) {
  return getValue(product?.tags?.[0]?.text)?.toLowerCase();
}

/**
 * Função que transforma uma lista de produtos agrupados por Sellers em uma string formatado.
 * @param sellers Lista de produtos agrupados por Sellers.
 * @param splitter Divisor do texto
 * @returns Texto formatado.
 */
export function listItemProductTagsWithSellers(sellers: Seller[] = [], splitter = ':') {
  const text = sellers
    .reduce<string>(
      (acc, curr) =>
        acc +
        curr.items.reduce<string>((acc, { product }) => {
          const text = createItemProductTag(product);
          return acc + (text ? `${text}${splitter}` : '');
        }, ''),
      '',
    )
    ?.replace(/:$/, '');

  return !text ? undefined : removeDuplicateTerm(text?.toLowerCase(), splitter);
}
