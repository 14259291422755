import { Button } from 'utils/shared/components';
import { styled } from 'utils/styles';

export const ButtonWrapper = styled(Button, { mt: '8px' });

export const ModalWrapper = styled('div', {
  p: '20px 16px',

  '> p': {
    textAlign: 'center',
    mb: '8px',
  },
});
