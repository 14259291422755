import * as S from './PaymentCard.styles';

import type { ComponentProps, ReactChild } from 'react';

import { ReactComponent as AmericanExpressLogo } from 'utils/assets/images/svg/americanExpressLogo.svg';
import { ReactComponent as AmericanExpressLogoAntigo } from 'utils/assets/images/svg/americanExpressLogo_antigo.svg';
import { ReactComponent as DinersClubLogo } from 'utils/assets/images/svg/dinersClubLogo.svg';
import { ReactComponent as EloLogo } from 'utils/assets/images/svg/eloLogo.svg';
import { ReactComponent as HipercardLogo } from 'utils/assets/images/svg/hipercardLogo.svg';
import { ReactComponent as MastercardLogo } from 'utils/assets/images/svg/mastercardLogo.svg';
import { ReactComponent as MidwayLogo } from 'utils/assets/images/svg/ic_midway.svg';
import { ReactComponent as VisaLogoAntigo } from 'utils/assets/images/svg/ic_visa_antigo.svg';
import { ReactComponent as VisaLogo } from 'utils/assets/images/svg/ic_visa.svg';

export type CardTypes = Extract<Pick<ComponentProps<typeof S.FrontSide>, 'type'>['type'], string>;

// TODO: remover verificação da feature flag quando a feature for efetivada
const isFeatureUnifiedCardDisabled =
  !process.env.REACT_APP_FF_UNIFIED_CARD_FORM || process.env.REACT_APP_FF_UNIFIED_CARD_FORM === 'false';

export const CardTypesLogo: Record<CardTypes, ReactChild> = {
  'american-express': isFeatureUnifiedCardDisabled ? <AmericanExpressLogoAntigo /> : <AmericanExpressLogo />,
  'diners-club': <DinersClubLogo />,
  elo: <EloLogo />,
  hipercard: <HipercardLogo />,
  mastercard: <MastercardLogo />,
  riachuelo: <MidwayLogo />,
  'riachuelo-master': <MastercardLogo />,
  'riachuelo-visa': isFeatureUnifiedCardDisabled ? <VisaLogoAntigo /> : <VisaLogo />,
  visa: isFeatureUnifiedCardDisabled ? <VisaLogoAntigo /> : <VisaLogo />,
};

export const isRchlo = ['riachuelo', 'riachuelo-master', 'riachuelo-visa'];
