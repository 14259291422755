import { styled } from 'utils/styles';

export const ContentDivider = styled('div', {
  padding: '$1 $0',
});

export const ContentTitle = styled('h2', {
  typo: 'heading2',
  color: '$black',
  userSelect: 'none',
});

export const ContentDescription = styled('p', {
  typo: 'body2',
  color: '$black',
  userSelect: 'none',
});
