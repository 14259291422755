import { type Attribute } from '@estore/types';

export enum ATTRIBUTE_CODES {
  BRAND_DESCRIPTION = 'brand_description',
  BRAND = 'brand',
  GM = 'gm',
  GENDER = 'gender',
  PATTERN = 'pattern',
  DCO = 'dco',
  LIFESTYLE = 'lifestyle',
}

/**
 * Função que facilita recuperar um atributo ou valor.
 * @param attributes Lista de atributos do produto que vem do e-commerce.
 * @param code Código do atributo
 * @param prop Valor da propriedade que deseja recuperar.
 * @returns string ou objeto atributo.
 */
export function getAttributeByCode(
  attributes: Attribute[] = [],
  code: Lowercase<keyof typeof ATTRIBUTE_CODES>,
  prop?: keyof Omit<Attribute, 'code'>,
) {
  return prop
    ? attributes.find((attr: Attribute) => attr?.code === code.toString())?.[prop]
    : attributes.find((attr: Attribute) => attr?.code === code.toString());
}

/**
 * Função que facilita recuperar um atributo ou valor.
 * @param attributes Lista de atributos do produto que vem do e-commerce.
 * @param name Nome do Atributo.
 * @param prop Valor da propriedade que deseja recuperar.
 * @returns string ou objeto atributo.
 */
export function getAttributeByName(attributes: Attribute[] = [], name: string, prop: keyof Omit<Attribute, 'code'>) {
  return prop
    ? attributes.find((attr: Attribute) => attr?.name === name.toString())?.[prop]
    : attributes.find((attr: Attribute) => attr?.name === name.toString());
}
