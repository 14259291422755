import { Dispatch } from 'redux';

import { AddressDeletePayload, AddressPayload, AuthTokenPayload } from 'store/engine/types';
import { engineDeleteAddress, engineGetAddress, enginePostAddress, enginePutAddress } from 'store/engine';
import { analyticsAddressSaveButtonCallback } from 'utils/functions/analytics';
import { AddressResultData } from 'types/adress';
import { AddressType } from './types';
import { GlobalTypes } from './global';
import { setGenericError } from './genericError';

export const Types = {
  GET_ADDRESS: 'address/GET_ADDRESS',
  GET_ADDRESS_SUCCESS: 'address/GET_ADDRESS_SUCCESS',
  GET_ADDRESS_ERROR: 'address/GET_ADDRESS_ERROR',

  SET_ADDRESS: 'address/SET_ADDRESS',

  POST_ADDRESS: 'address/POST_ADDRESS',
  POST_ADDRESS_SUCCESS: 'address/POST_ADDRESS_SUCCESS',
  POST_ADDRESS_ERROR: 'address/POST_ADDRESS_ERROR',

  PUT_ADDRESS: 'address/PUT_ADDRESS',
  PUT_ADDRESS_SUCCESS: 'address/PUT_ADDRESS_SUCCESS',
  PUT_ADDRESS_ERROR: 'address/PUT_ADDRESS_ERROR',

  DELETE_ADDRESS: 'address/DELETE_ADDRESS',
  DELETE_ADDRESS_SUCCESS: 'address/DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_ERROR: 'address/DELETE_ADDRESS_ERROR',

  RESET_ADDRESS_STATE: 'address/RESET_ADDRESS_STATE',

  RESET_ADDRESS_STATUS: 'address/RESET_ADDRESS_STATUS',
};

const initialState: AddressType = {
  addresses: [],
  selectedAddress: null,
  status: 'idle',
};

const ERROR_MESSAGES = ['Ops! Ocorreu um erro inesperado.', 'Por favor, tente novamente mais tarde.'];

export const reducer = (state: AddressType = initialState, action: any) => {
  switch (action.type) {
    // GET ADDRESS
    case Types.GET_ADDRESS:
      return { ...state, status: 'pending' };
    case Types.GET_ADDRESS_SUCCESS:
      let selectedAddress = state.selectedAddress;
      if (state.hasEdited) {
        selectedAddress = action.payload.find((address) => address.code === state?.selectedAddress?.code);
      }
      return {
        ...state,
        addresses: action.payload,
        status: 'success',
        selectedAddress: selectedAddress || action.payload?.[0] || null,
      };
    case Types.GET_ADDRESS_ERROR:
      return { ...state, status: 'error' };

    // SET ADDRESS
    case Types.SET_ADDRESS:
      return { ...state, selectedAddress: action.payload };

    // POST ADDRESS
    case Types.POST_ADDRESS:
      return { ...state, status: 'pending' };
    case Types.POST_ADDRESS_SUCCESS:
      return { ...state, status: 'success' };
    case Types.POST_ADDRESS_ERROR:
      return { ...state, status: 'error' };

    // PUT ADDRESS
    case Types.PUT_ADDRESS:
      return { ...state, status: 'pending' };
    case Types.PUT_ADDRESS_SUCCESS:
      return { ...state, status: 'success', hasEdited: true };
    case Types.PUT_ADDRESS_ERROR:
      return { ...state, status: 'error' };

    // DELETE ADDRESS
    case Types.DELETE_ADDRESS:
      return { ...state, status: 'pending' };
    case Types.DELETE_ADDRESS_SUCCESS:
      return { ...state, status: 'success', addresses: action.payload };
    case Types.DELETE_ADDRESS_ERROR:
      return { ...state, status: 'error' };

    // RESET ADDRESS STATE
    case Types.RESET_ADDRESS_STATE:
    case GlobalTypes.GLOBAL_RESET:
      return { ...initialState };

    case Types.RESET_ADDRESS_STATUS:
      return { ...state, status: 'idle' };
    default:
      return state;
  }
};

const getAddress = () => ({
  type: Types.GET_ADDRESS,
});

const getAddressSuccess = (payload: AddressResultData[]) => ({
  type: Types.GET_ADDRESS_SUCCESS,
  payload: payload || [],
});

const getAddressError = () => ({
  type: Types.GET_ADDRESS_ERROR,
});

const setAddress = (payload) => ({
  type: Types.SET_ADDRESS,
  payload,
});

const postAddress = () => ({
  type: Types.POST_ADDRESS,
});

const postAddressSuccess = () => ({
  type: Types.POST_ADDRESS_SUCCESS,
});

const postAddressError = () => ({
  type: Types.POST_ADDRESS_ERROR,
});

const putAddress = () => ({
  type: Types.PUT_ADDRESS,
});

const putAddressSuccess = () => ({
  type: Types.PUT_ADDRESS_SUCCESS,
});

const putAddressError = () => ({
  type: Types.PUT_ADDRESS_ERROR,
});

const deleteAddress = () => ({
  type: Types.DELETE_ADDRESS,
});

const deleteAddressSuccess = (data) => ({
  type: Types.DELETE_ADDRESS_SUCCESS,
  payload: data,
});

const deleteAddressError = () => ({
  type: Types.DELETE_ADDRESS_ERROR,
});

const resetAddressState = () => ({
  type: Types.RESET_ADDRESS_STATE,
});

const resetAddressStatus = () => ({
  type: Types.RESET_ADDRESS_STATUS,
});

const asyncGetAddress = (payload: AuthTokenPayload, signal?: AbortSignal) => async (dispatch: Dispatch) => {
  dispatch(getAddress());
  try {
    const response = await engineGetAddress(payload, signal);
    const { status, data }: any = response;
    if (status < 400) {
      return dispatch(getAddressSuccess(data?.data));
    }
    dispatch(getAddressError());
  } catch {
    dispatch(getAddressError());
  }
};

const asyncPostAddress = (payload: AddressPayload) => async (dispatch: Dispatch) => {
  dispatch(postAddress());
  const dispatchMessageError = () => dispatch(setGenericError({ messages: ERROR_MESSAGES }));
  try {
    const response = await enginePostAddress(payload);
    const { data } = response;
    if (data.success) {
      analyticsAddressSaveButtonCallback('Novo endereço', true);
      return dispatch(postAddressSuccess());
    }
    analyticsAddressSaveButtonCallback('Novo endereço', false, 'Problema ao cadastrar novo endereço');
    dispatch(postAddressError());
    dispatchMessageError();
  } catch {
    analyticsAddressSaveButtonCallback('Novo endereço', false, 'Problema ao cadastrar novo endereço');
    dispatch(postAddressError());
    dispatchMessageError();
  }
};

const asyncPutAddress = (payload: AddressPayload) => async (dispatch: Dispatch) => {
  dispatch(putAddress());
  const dispatchMessageError = () => dispatch(setGenericError({ messages: ERROR_MESSAGES }));
  try {
    const response = await enginePutAddress(payload);
    const { data } = response;
    if (data.success) {
      analyticsAddressSaveButtonCallback('Editar endereço', true);
      return dispatch(putAddressSuccess());
    }
    analyticsAddressSaveButtonCallback('Editar endereço', false, 'Problema ao editar endereço');
    dispatch(putAddressError());
    dispatchMessageError();
  } catch {
    analyticsAddressSaveButtonCallback('Editar endereço', false, 'Problema ao editar endereço');
    dispatch(putAddressError());
    dispatchMessageError();
  }
};

const asyncDeleteAddress = (payload: AddressDeletePayload) => async (dispatch: Dispatch) => {
  dispatch(deleteAddress());
  const dispatchMessageError = () => dispatch(setGenericError({ messages: ERROR_MESSAGES }));
  try {
    const response = await engineDeleteAddress(payload);
    const { data } = response;
    if (data.success) {
      return dispatch(deleteAddressSuccess(data.data));
    }
    dispatch(deleteAddressError());
    dispatchMessageError();
  } catch {
    dispatch(deleteAddressError());
    dispatchMessageError();
  }
};

export {
  asyncGetAddress,
  asyncPostAddress,
  asyncPutAddress,
  asyncDeleteAddress,
  setAddress,
  resetAddressState,
  resetAddressStatus,
};
