import type { Seller, SellerItem } from '@estore/types';

/**
 * Função que obtém o valor total do item de carrinho multiplicado pela quantidade.
 * @param cartItem Item do Carrinho.
 * @returns valor total dos itens multiplicado pela quantidade.
 */
export function getOriginalPrice(cartItem: SellerItem) {
  return cartItem?.total?.toString();
}

/**
 * Função que transforma preço originais de itens de carrinho em uma string formatado.
 * @param sellers Lista de itens de carrinho agrupados por Seller
 * @param splitter Divisor do texto
 * @returns texto formatado
 */
export function listOriginalPriceWithSellers(sellers: Seller[] = [], splitter = ':') {
  const originalPrice = sellers
    .reduce<string>(
      (acc, { items }) =>
        acc +
        items.reduce<string>((acc, curr) => {
          const value = getOriginalPrice(curr);
          return acc + `${value ? `${value}${splitter}` : ''}`;
        }, ''),
      '',
    )
    ?.replace(/:$/g, '')
    ?.toLowerCase();
  return !originalPrice ? undefined : originalPrice;
}
