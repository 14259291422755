import { styled } from 'utils/styles';

export const Wrapper = styled('div', {
  width: '100%',
  pt: '20px',
  pb: '20px',
  bg: '$white',
  d: 'grid',
  gap: '24px',
  borderTop: '1px solid $gray10',
});

export const ActionContainer = styled('div', {
  pr: 16,
  pl: 16,
});

export const Table = styled('table', {
  d: 'grid',
  gap: '8px',
  variants: {
    type: {
      group: {
        bg: '$gray5',
        pt: '8px',
        pb: '8px',
      },
    },
  },
});

export const TableRow = styled('tr', {
  d: 'grid',
  mb: '0px',
  gridAutoFlow: 'column',
  typo: 'regHeading2',
  pr: 16,
  pl: 16,
  pt: 6,
  pb: 12,
  color: '$black',
  '&:first-child': {
    typo: 'heading1',
    color: '$black',
    pt: 0,
    pb: 12,
  },
  '&:last-child': {
    typo: 'heading1',
    color: '$black',
    mr: 16,
    ml: 16,
    pr: 0,
    pl: 0,
    pt: 12,
    pb: 12,
  },
  variants: {
    type: {
      addition: {
        typo: 'regHeading2',
        color: '$black',
      },
      discount: {
        typo: 'regHeading2',
        color: '$green',
      },
      group: {
        typo: 'boldHeading3',
        color: '$gray75',
        bg: '$gray5',
        pt: '2px',
        pb: '2px',
        '&:first-child': {
          typo: 'boldHeading3',
          color: '$gray75',
          pt: '2px',
          pb: '2px',
        },
        '&:last-child': {
          typo: 'boldHeading3',
          color: '$gray75',
          pt: '2px',
          pb: '2px',
          border: 0,
        },
      },
    },
    group: {
      true: {
        borderTop: '1px solid #DADADA',
        borderBottom: '1px solid #DADADA',
        mr: 16,
        ml: 16,
        pr: 0,
        pl: 0,
        pt: 12,
        cursor: 'pointer',
      },
    },
    open: {
      true: {
        borderBottom: 0,
      },
    },
  },
});

export const TableDataCell = styled('td', {
  '&:nth-child(1)': {
    justifySelf: 'start',
  },
  '&:nth-child(2)': {
    justifySelf: 'end',
  },
});

export const GroupButton = styled('button', {
  ml: '14px',
  border: 0,
  bg: 'transparent',
  cursor: 'pointer',
});
