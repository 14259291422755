// Examples of usage:
// Phone: maskText('21986441590', '(**) *****-****', null);
// -> '(21) 98644-1590'
// Credit Card: maskText('5535504678010206', '**** **** **** ****', '*');
// -> '5535 5046 7801 0206'

export const maskText = (text: string, mask: string, maskChar: string | null) => {
  const textArray = Array.from(text);
  const maskArray = Array.from(mask);
  const maskedTextArray: string[] = [];
  let textArrayIndex = 0;
  maskArray.forEach((maskItem) => {
    if (textArray[textArrayIndex]) {
      if (maskItem === '*') {
        maskedTextArray.push(textArray[textArrayIndex]);
        textArrayIndex++;
      } else {
        maskedTextArray.push(maskItem);
      }
    } else if (maskChar) {
      if (maskItem !== '*') {
        maskedTextArray.push(maskItem);
      } else {
        maskedTextArray.push(maskChar);
      }
    }
  });
  const maskedText = maskedTextArray.join('');

  return maskedText;
};

// Examples of usage:
// Phone: cleanMask('(21) 98644-1590', '() -');
// -> '21986441590'
// Credit Card: cleanMask('5535 5046 7801 0206', ' ');
// -> '5535504678010206'

export const cleanMask = (text: string, unwantedChars: string) => {
  const textArray = Array.from(text);
  const unwantedCharsArray = Array.from(unwantedChars);
  const cleanedTextArray: string[] = [];
  textArray.forEach((textItem) => {
    if (!unwantedCharsArray.includes(textItem)) {
      cleanedTextArray.push(textItem);
    }
  });
  const cleanedText = cleanedTextArray.join('');

  return cleanedText;
};
