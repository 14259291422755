import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';
import * as S from './Stepper.styles';

export interface IStepperProps {
  steps: {
    label: string;
    icon?: ReactNode;
  }[];
  activeStep: number;
}

export const Stepper = forwardRef<HTMLDivElement, IStepperProps>(({ steps, activeStep }, ref) => (
  <S.Wrapper ref={ref}>
    {steps.map(({ label, icon }, index) => (
      <React.Fragment key={label}>
        <S.Step active={activeStep >= index}>
          <S.StepSymbol>{icon ? icon : index + 1}</S.StepSymbol>
          <S.StepLabel>{label}</S.StepLabel>
        </S.Step>
        {steps.length !== index + 1 && <S.Separator />}
      </React.Fragment>
    ))}
  </S.Wrapper>
));

Stepper.displayName = 'Stepper';
