import { styled } from 'utils/styles';

export const ToastWrapper = styled('div', {
  width: 'calc(100% - 32px)',
  p: '12px 20px',
  position: 'fixed',
  bottom: '16px',
  left: '16px',
  zIndex: '999',
  bg: '$black',
  br: '4px',
  d: 'grid',
  gridAutoFlow: 'column',
  jc: 'space-between',
  ai: 'center',
  cursor: 'pointer',
  '& svg': {
    width: '20px',
    '& path': {
      fill: '$green',
    },
  },
});

export const Content = styled('p', {
  typo: 'heading2',
  color: '$white',
});
