const masterArray = [
  'mastercard',
  'Mastercard',
  'MasterCard',
  'MASTER',
  'MASTERCARD',
  'MASTER CARD',
  'master-card',
  'riachuelo-master',
  'Master',
  'master',
  'Riachuelo-master',
];
const visaArray = ['visa', 'Visa', 'VISA', 'riachuelo-visa', 'Riachuelo-visa'];
const hipercardArray = [
  'hipercard',
  'Hipercard',
  'HiperCard',
  'Hiper Card',
  'HIPER',
  'HIPER CARD',
  'HIPERCARD',
  'hiper-card',
];
const riachueloArray = ['Riachuelo', 'riachuelo', 'rchlo', 'RIACHUELO', 'RCHLO'];
const eloArray = ['elo', 'ELO', 'Elo'];
const amexArray = [
  'american express',
  'American Express',
  'amex',
  'AMEX',
  'AMERICAN EXPRESS',
  'AMERICANEXPRESS',
  'american-express',
  'Amex',
];
const dinersArray = [
  'diners',
  'Diners',
  'DINERS',
  'dinersclub',
  'DinersClub',
  'Diners Club',
  'diners-club',
  'diners club',
  'DINERSCLUB',
  'DINERS CLUB',
];

export const creditCardEnum = (niceType: string | undefined) => {
  if (!niceType) return 0;
  switch (niceType) {
    case masterArray.find((type) => type === niceType):
      return 1;
    case visaArray.find((type) => type === niceType):
      return 2;
    case hipercardArray.find((type) => type === niceType):
      return 3;
    case riachueloArray.find((type) => type === niceType):
      return 4;
    case eloArray.find((type) => type === niceType):
      return 5;
    case amexArray.find((type) => type === niceType):
      return 7;
    case dinersArray.find((type) => type === niceType):
      return 9;
    default:
      return 0;
  }
};

export const paymentPlanBrand = (niceType: string | undefined) => {
  switch (niceType) {
    case masterArray.find((type) => type === niceType):
      return 'mastercard';
    case visaArray.find((type) => type === niceType):
      return 'visa';
    case riachueloArray.find((type) => type === niceType):
      return 'riachuelo';
    case hipercardArray.find((type) => type === niceType):
      return 'hipercard';
    case eloArray.find((type) => type === niceType):
      return 'elo';
    case amexArray.find((type) => type === niceType):
      return 'american-express';
    case dinersArray.find((type) => type === niceType):
      return 'diners-club';
    default:
      return undefined;
  }
};
