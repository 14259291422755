import React from 'react';
import * as S from './styles';
import { ReactComponent as FireIcon } from 'utils/assets/images/svg/fire.svg';

type RemainingUnitsProps = {
  message: string;
};

export const RemainingUnits: React.FC<RemainingUnitsProps> = ({ message }) => {
  return (
    <S.Container>
      <FireIcon />
      <S.Message>{message}</S.Message>
    </S.Container>
  );
};
