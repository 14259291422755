import { styled } from 'utils/styles';

export const Container = styled('div', {
  textAlign: 'center',
  minHeight: '56px',
  p: '24px 30px 12px',
  position: 'relative',
  d: 'flex',
  jc: 'center',
  ai: 'center',
});

export const IconButton = styled('button', {
  background: 'none',
  border: 'none',
  position: 'absolute',
  d: 'flex',
  ai: 'center',
  cursor: 'pointer',

  variants: {
    position: {
      left: {
        left: '0',
      },
      right: {
        right: '0',
      },
    },
  },

  '&:disabled': {
    pointerEvents: 'none',
    cursor: 'none',
  },
});

export const Title = styled('h1', {
  color: '$black',
  typo: 'heading1',
});
