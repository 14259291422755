import { ActionType, AppConfigPayments, AppConfigType } from './types';

// Action Types
const Types = {
  GET_PAYMENT_METHODS: 'appConfig/GET_PAYMENT_METHODS',
};

const initialState: AppConfigType = {
  paymentMethods: null,
};

export const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case Types.GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...action.payload.paymentMethods,
        },
      };
    default:
      return state;
  }
};

export const getPaymentMethods = (data: { data: { paymentMethods: AppConfigPayments } }) => {
  const { paymentMethods } = data.data;
  return {
    type: Types.GET_PAYMENT_METHODS,
    payload: { paymentMethods },
  };
};
