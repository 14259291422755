import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import { Typography } from '../Typography/Typography';
import { ReactComponent as ArrowRight } from 'utils/assets/images/svg/chevronRight.svg';

import { ISelectBoxContext, SelectBoxContext, useSelectBox } from './selectBox-context';
import * as S from './styles';

interface ISelectBoxProps extends ComponentProps<typeof S.Container>, ComponentProps<typeof S.ChildrenContainer> {
  children: ReactNode;
  selected: boolean;
  label?: string;
  icon?: ReactElement;
  readOnly?: boolean;
  onAction?: () => void;
}

export const SelectBox = ({ children, onAction, icon, selected, size, label, readOnly, ...rest }: ISelectBoxProps) => {
  const contextValue: ISelectBoxContext = {
    selected,
  };

  const handleClick = () => {
    if (!readOnly && onAction) {
      onAction();
    }
  };

  return (
    <div onClick={handleClick}>
      {label && (
        <Typography variant="body2" css={{ mb: '$2' }}>
          {label}
        </Typography>
      )}
      <SelectBoxContext.Provider value={contextValue}>
        <S.Container selected={selected} {...rest}>
          {icon && <S.IconContainer>{icon}</S.IconContainer>}
          <S.ChildrenContainer size={size}>{children}</S.ChildrenContainer>
          {!readOnly && (
            <S.Button onClick={handleClick}>
              <ArrowRight preserveAspectRatio="xMidYMid meet" width="100%" />
            </S.Button>
          )}
        </S.Container>
      </SelectBoxContext.Provider>
    </div>
  );
};

interface ISelectBoxPlaceholder {
  children: ReactNode;
}

const Placeholder = ({ children }: ISelectBoxPlaceholder) => {
  const { selected } = useSelectBox();
  return <>{!selected ? children : null}</>;
};
interface ISelectBoxSelected {
  children: ReactNode;
}
const Selected = ({ children }: ISelectBoxSelected) => {
  const { selected } = useSelectBox();
  return <>{selected ? children : null}</>;
};

SelectBox.Placeholder = Placeholder;
SelectBox.Selected = Selected;
