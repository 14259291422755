import { ComponentProps } from 'react';
import { ReactComponent as CloseIcon } from 'utils/assets/images/svg/close.svg';

import * as S from './styles';

export interface IALertProps extends ComponentProps<typeof S.Container> {
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  onClose?: () => void;
}

export const Alert = ({ children, rightIcon, leftIcon, onClose, variant, ...rest }: IALertProps) => (
  <S.Container variant={variant} {...rest}>
    {rightIcon && <S.Box className="alert-ui-icon">{rightIcon}</S.Box>}
    <S.Box className="alert-ui-content">{children}</S.Box>
    {typeof onClose === 'function' && !leftIcon && (
      <S.Button variant={variant} onClick={onClose}>
        <CloseIcon style={{ width: '12px' }} />
      </S.Button>
    )}
    {leftIcon}
  </S.Container>
);

Alert.defaultProps = {
  variant: 'default',
  severity: 'error',
  space: 'md',
};
