import { CURRENCY_TYPES } from '@estore/gtm-v4';
import type { Seller, Product } from '@estore/types';
import { getAttributeByCode, getVariationByKey, getCategory, getValue } from './utils';
import { formatDiscountPrice, getFirstLevelCategory } from './utils/product';

export type CartItem = Gtag.Item & { [key: string]: number | string | undefined };

export type CustomEventItem = Partial<{
  qr_code: string;
  user_id: string;
  store: string;
  registration_id: string;
  item_product_lifestyle: string;
  item_product_tag: string;
  original_price: number;
  cart_id: string;
}>;

export type EventItem = CartItem & CustomEventItem;

export type EventItemParams = {
  product: Product;
  affiliation: string;
  quantity: number;
  index: number;
} & Omit<CustomEventItem, 'item_product_lifestyle' | 'item_product_tag' | 'original_price'>;

/**
 * Função que converte produto em item de carrinho.
 * @param product Produto vindo do e-store Desktop
 * @param affiliation 1P ou 3P
 * @param quantity Quantidade de um produto no carrinho.
 * @param index Posição do produto na lista.
 * @returns Item de Carrinho.
 */
export function createCartItem(product: Product, affiliation = 'RCHLO', quantity = 1, index = 0): CartItem {
  return {
    item_id: product?.skuSubGroup || product?.skuGroup,
    item_name: getValue(product?.title),
    affiliation: getValue(affiliation),
    currency: CURRENCY_TYPES.BRL,
    discount: formatDiscountPrice(product),
    index,
    item_brand: getValue(String(getAttributeByCode(product?.attributes, 'brand', 'value')?.toString())),
    item_category: getFirstLevelCategory(product?.categories),
    item_category2: getValue(getAttributeByCode(product?.attributes, 'gm', 'value')?.toString()),
    item_category3: getValue(product?.sku),
    item_category4: getValue(getVariationByKey(product?.variations, 'size', 'value')?.toString()),
    item_category5: getValue(getVariationByKey(product?.variations, 'color', 'value')?.toString()),
    item_list_name: getCategory(product?.categories, product?.categories?.length - 1 || 0),
    item_variant: getValue(getAttributeByCode(product?.attributes, 'dco', 'value')?.toString()),
    price: product?.salePrice,
    quantity,
  };
}

export function createEventItem({
  product,
  affiliation = 'RCHLO',
  quantity = 1,
  index = 0,
}: EventItemParams): EventItem {
  return {
    item_id: product?.skuSubGroup || product?.skuGroup,
    item_name: getValue(product?.title),
    affiliation: getValue(affiliation),
    currency: CURRENCY_TYPES.BRL,
    discount: formatDiscountPrice(product),
    index,
    item_brand: getValue(getAttributeByCode(product?.attributes, 'brand', 'value')),
    item_category: getFirstLevelCategory(product?.categories),
    item_category2: getValue(getAttributeByCode(product?.attributes, 'gm', 'value')),
    item_category3: getValue(product?.sku),
    item_category4: getValue(getVariationByKey(product?.variations, 'size', 'value')),
    item_category5: getValue(getVariationByKey(product?.variations, 'color', 'value')),
    item_list_name: getCategory(product?.categories, product?.categories?.length - 1 || 0),
    item_variant: getValue(getAttributeByCode(product?.attributes, 'dco', 'value')),
    price: product?.salePrice,
    quantity,
  };
}

/**
 * Item de carrinho com produtos 1P e 3P.
 * @param sellers Agrupador de produtos por Seller
 * @returns Lista de items de Carrinho.
 */
export function listCartItemsWithSeller(sellers: Seller[] = []): CartItem[] | undefined {
  const items = sellers.reduce<CartItem[]>(
    (acc, { items, seller }) => [
      ...acc,
      ...items.reduce<CartItem[]>(
        (acc, curr) => [
          ...acc,
          { ...createCartItem(curr.product, seller.businessDisplayName, curr.quantity, curr.itemNumber) },
        ],
        [],
      ),
    ],
    [],
  );
  return items?.length > 0 ? items : undefined;
}
