import { Types } from '../cart';

export const mergeCart = () => ({
  type: Types.MERGE_CART,
});

export const mergeCartSuccess = (data) => ({
  type: Types.MERGE_CART_SUCCESS,
  payload: data,
});

export const mergeCartError = () => ({
  type: Types.MERGE_CART_ERROR,
});

export const getCart = () => ({
  type: Types.GET_CART,
});

export const getCartSuccess = (data) => ({
  type: Types.GET_CART_SUCCESS,
  payload: data,
});

export const getCartError = () => ({
  type: Types.GET_CART_ERROR,
});

export const getMultiCart = () => ({
  type: Types.GET_MULTI_CART,
});

export const getMultiCartSuccess = (data) => ({
  type: Types.GET_MULTI_CART_SUCCESS,
  payload: data,
});

export const getMultiCartError = () => ({
  type: Types.GET_MULTI_CART_ERROR,
});

export const addProductToCart = () => ({
  type: Types.ADD_PRODUCT_TO_CART,
});

export const addProductToCartSuccess = (data) => ({
  type: Types.ADD_PRODUCT_TO_CART_SUCCESS,
  payload: data,
});

export const addProductToCartError = (error) => ({
  type: Types.ADD_PRODUCT_TO_CART_ERROR,
  payload: error,
});

export const removeProductFromCart = () => ({
  type: Types.REMOVE_PRODUCT_FROM_CART,
});

export const removeProductFromCartSuccess = (data) => ({
  type: Types.REMOVE_PRODUCT_FROM_CART_SUCCESS,
  payload: data,
});

export const removeProductFromCartError = () => ({
  type: Types.REMOVE_PRODUCT_FROM_CART_ERROR,
});

export const setDeliveryMethod = ({ index, method }) => ({
  type: Types.SET_DELIVERY_METHOD,
  payload: { index, method },
});

export const patchDeliveryMethod = () => ({
  type: Types.PATCH_DELIVERY_METHOD,
});

export const patchDeliveryMethodSuccess = (data) => ({
  type: Types.PATCH_DELIVERY_METHOD_SUCCESS,
  payload: data,
});

export const patchDeliveryMethodError = () => ({
  type: Types.PATCH_DELIVERY_METHOD_ERROR,
});

export const AllowedPeopleRequestMethod = () => ({
  type: Types.ALLOWED_PEOPLE_REQUEST,
});

export const AllowedPeopleRequestMethodError = () => ({
  type: Types.ALLOWED_PEOPLE_REQUEST_ERROR,
});

export const setAllowedPeopleMethodSuccess = (data: any) => ({
  type: Types.SET_ALLOWED_PEOPLE_SUCCESS,
  payload: data,
});

export const deleteAllowedPeopleMethodSuccess = (data: any) => ({
  type: Types.REMOVE_ALLOWED_PEOPLE_SUCCESS,
  payload: data,
});

export const getAdditionalInformation = () => ({
  type: Types.GET_ADDITIONAL_INFORMATION,
});

export const getAdditionalInformationSuccess = (data) => ({
  type: Types.GET_ADDITIONAL_INFORMATION_SUCCESS,
  payload: data,
});

export const getAdditionalInformationError = () => ({
  type: Types.GET_ADDITIONAL_INFORMATION_ERROR,
});

export const updateCartWithCheckout = (payload: any) => ({
  type: Types.UPDATE_CART_WITH_CHECKOUT,
  payload,
});

export const resetCoupon = () => ({
  type: Types.RESET_COUPON,
});

export const resetCartStatus = () => ({
  type: Types.RESET_CART_STATUS,
});

export const resetDeliveryMethod = () => ({
  type: Types.RESET_DELIVERY_METHOD,
});
