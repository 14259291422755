import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { Backdrop } from '../Backdrop';
import { ReactComponent as CloseIcon } from 'utils/assets/images/svg/close.svg';
import * as S from './Modal.styles';

export interface IModalProps {
  title: string;
  content: ReactNode;
  onClose?: () => void;
  removeLine?: boolean;
  hasCloseButton?: boolean;
}

export const Modal = forwardRef<HTMLDivElement, IModalProps>(
  ({ title, content, hasCloseButton, onClose, removeLine }, ref) => (
    <>
      <Backdrop onClick={onClose} />
      <S.Wrapper ref={ref}>
        <S.TitleWrapper>
          <S.Title>{title}</S.Title>
          {hasCloseButton && <CloseIcon onClick={onClose} />}
        </S.TitleWrapper>
        {!removeLine && <S.Line />}
        <S.ContentWrapper>{content}</S.ContentWrapper>
      </S.Wrapper>
    </>
  ),
);

Modal.displayName = 'Modal';

Modal.defaultProps = {
  hasCloseButton: true,
};
