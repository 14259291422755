import { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import * as S from './Box.styles';

export interface IBoxProps extends ComponentProps<typeof S.Box> {
  isRounded?: boolean;
  isFloating?: boolean;
}

export const Box = forwardRef<HTMLDivElement, IBoxProps>(({ children, ...rest }, ref) => (
  <S.Box ref={ref} {...rest}>
    {children}
  </S.Box>
));

Box.displayName = 'Box';
