import axios from 'axios';

const ESTORE_API_BASE_URL = process.env.REACT_APP_API_DOTNET_FACADE_NEW;

export const estoreApi = axios.create({
  baseURL: ESTORE_API_BASE_URL,
});

export const estoreEngineApi = axios.create({
  baseURL: `${ESTORE_API_BASE_URL}/engine`,
});
