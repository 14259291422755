import {
  useAddPaymentInfoMSiteDispatch,
  useAddShippingInfoMSiteDispatch,
  useBeginCheckoutMSiteDispatch,
  usePurchaseMSiteDispatch,
  useRemoveFromCartMSiteDispatch,
  useViewCartMSiteDispatch,
  useExceptionDispatch,
  useAddToCartMSiteDispatch,
  type ExceptionParams,
  type CartParamsContext,
  getTagsText,
  type EventSelectContentCoupon,
  useSelectContentDispatch,
  type EventSelectContent,
} from '@estore/gtm-v4';
import type { IShippingMethod, ShippingGroup, CartState } from 'store/ducks/types';
import { type Cart } from 'types';
import { type PaymentMethods } from 'types';
import { type EventItem, createEventItem, listCartItemsWithSeller } from './cart-item';
import { listItemProductLifestyleWithSellers } from './item-product-lifestyle';
import { listItemProductTagsWithSellers } from './item-product-tag';
import { listOriginalPriceWithSellers } from './original-price';
import { getSelectedShippingMethodGroup } from './shipping_tier';
import { getAttributeByCode, getValue } from './utils';
import { analyticsSharedBagUserProperties } from 'utils/functions/analytics';
import { sanitizeText } from 'utils/functions/analytics/helpers';
import sha1Encrypt from 'utils/functions/encrypt';
import { EVENT_NAMES, CUSTOM_EVENT_NAMES, useEventDispatch } from '@estore/ga4';

export type GTMCart = CartState & {
  isSharedBag: boolean;
};

export type EventParams = Pick<GTMCart, 'cart' | 'total' | 'recoveryCart' | 'uuid' | 'isSharedBag'>;
export type PaymentEventParams = EventParams & {
  coupon?: string;
  payments: { paymentMethod: PaymentMethods | string | null }[];
};
export type ShippingEventParams = EventParams & {
  deliveryMethods: IShippingMethod[];
};
export type PurchaseEventParams = PaymentEventParams & {
  orderId?: string;
  affiliation?: string;
  tax?: number;
  shipping?: number;
  shipping_tier?: string;
  saved_card: 'sim' | 'nao';
};

function sanitizeEventParams(obj: any): CartParamsContext | undefined {
  if (!obj) {
    return;
  }

  const params = Object.entries(obj);

  if (params?.length === 0) {
    return;
  }

  return params.reduce<EventParams | undefined>((acc, [key, value]) => {
    let newAcc: any = acc;
    if (!newAcc) {
      newAcc = {};
    }

    return !value ? { ...newAcc } : { ...newAcc, [key]: value };
  }, undefined);
}

function getGuestCardValue(isSharedBag: boolean) {
  return isSharedBag ? 'sim' : 'nao';
}

function createEventParams({
  total: value,
  recoveryCart,
  cart: { seller: sellers = [], cartId },
  uuid,
  isSharedBag,
}: EventParams) {
  const params = {
    value,
    qr_code: recoveryCart?.code?.toString(),
    guest_cart: getGuestCardValue(isSharedBag),
    user_id: uuid,
    cart_id: cartId ? cartId : undefined,
    store:
      recoveryCart?.storePrefix && recoveryCart?.storeName
        ? getValue(`${recoveryCart?.storePrefix} ${recoveryCart?.storeName}`)
        : undefined,
    registration_id: recoveryCart?.sellerId?.toString(),
    item_product_lifestyle: listItemProductLifestyleWithSellers(sellers),
    item_product_tag: listItemProductTagsWithSellers(sellers),
    original_price: listOriginalPriceWithSellers(sellers),

    items: listCartItemsWithSeller(sellers),
  };
  return sanitizeEventParams({ ...params });
}

interface Info {
  value: number;
  coupon?: string;
  freightId?: string;
  qr_code: string;
  user_id: string;
  store?: string;
  registration_id: string;
}

interface ShippingInfo extends Info {
  shippingGroups: ShippingGroup[];
  freightId?: string;
  cart_id: string;
  isSharedBag: boolean;
}

interface PaymentInfo extends Info {
  cart: Cart;
  currentPaymentMethod?: PaymentMethods;
  isSharedBag: boolean;
}

function createShippingInfo({
  value,
  coupon,
  shippingGroups = [],
  freightId,
  qr_code,
  user_id,
  store,
  registration_id,
  cart_id,
  isSharedBag,
}: ShippingInfo) {
  const shipping_tier = sanitizeText(getSelectedShippingMethodGroup(shippingGroups)?.[0]?.description);

  const { item_product_lifestyle, item_product_tag, original_price, items } = shippingGroups
    ?.filter(({ methods }) =>
      Object.values(methods)
        .flatMap((methods) => methods)
        .find((value) => value.selected && value.freightId === freightId),
    )
    ?.reduce<{
      item_product_lifestyle?: string;
      item_product_tag?: string;
      original_price?: string;
      items: EventItem[];
    }>(
      (acc, { products, sellerId }) => {
        const { item_product_lifestyle = '', item_product_tag = '', original_price = '', items = [] } = acc;

        const current = products.reduce<{
          item_product_lifestyle?: string;
          item_product_tag?: string;
          original_price?: string;
          items: EventItem[];
        }>(
          ({ item_product_lifestyle = '', item_product_tag = '', original_price = '', items = [] }, product, index) => {
            const lifestyle = getAttributeByCode(product.attributes, 'lifestyle', 'value');
            const tag = getTagsText(product.tags);
            return {
              item_product_lifestyle:
                item_product_lifestyle + (lifestyle ? `${sanitizeText(lifestyle.toString())}:` : ''),
              item_product_tag: item_product_tag + (tag ? `${sanitizeText(tag)}:` : ''),
              original_price: original_price + (product.total || product.salePrice).toString() + ':',
              items: [
                ...items,
                {
                  ...createEventItem({
                    product,
                    affiliation: sellerId,
                    index: index + 1,
                    quantity: product?.quantity || 1,
                  }),
                },
              ],
            };
          },
          {
            item_product_lifestyle: undefined,
            item_product_tag: undefined,
            original_price: undefined,
            items: [],
          },
        );

        return {
          item_product_lifestyle: item_product_lifestyle + current.item_product_lifestyle,
          item_product_tag: item_product_tag + current.item_product_tag,
          original_price: original_price + current.original_price,
          items: [...items, ...current.items],
        };
      },
      {
        item_product_lifestyle: undefined,
        item_product_tag: undefined,
        original_price: undefined,
        items: [],
      },
    );

  const params = {
    value,
    coupon,
    shipping_tier,
    qr_code,
    guest_cart: getGuestCardValue(isSharedBag),
    user_id: sha1Encrypt(user_id).toString(),
    store: store ? sanitizeText(store) : undefined,
    registration_id,
    item_product_lifestyle: item_product_lifestyle?.replace(/:$/g, ''),
    item_product_tag: item_product_tag?.replace(/:$/g, ''),
    original_price: original_price?.replace(/:$/g, ''),
    cart_id,
    items,
  };

  return sanitizeEventParams({ ...params });
}

function createPaymentInfo({
  value,
  coupon,
  currentPaymentMethod,
  cart,
  qr_code,
  user_id,
  store,
  registration_id,
  isSharedBag,
}: PaymentInfo) {
  const payment_type = sanitizeText(String(currentPaymentMethod));
  const items = cart?.seller?.flatMap(({ items, seller }) =>
    items.map(({ product, itemNumber, quantity }) =>
      createEventItem({
        product,
        affiliation: seller?.slug || seller?.id,
        index: itemNumber,
        quantity: quantity || 1,
      }),
    ),
  );

  const params = {
    value,
    coupon,
    payment_type,
    qr_code,
    guest_cart: getGuestCardValue(isSharedBag),
    user_id: sha1Encrypt(user_id).toString(),
    store: store ? sanitizeText(store) : undefined,
    registration_id,
    item_product_lifestyle: listItemProductLifestyleWithSellers(cart?.seller),
    item_product_tag: listItemProductTagsWithSellers(cart?.seller),
    original_price: listOriginalPriceWithSellers(cart?.seller),
    cart_id: cart?.cartId,
    items,
  };
  return sanitizeEventParams({ ...params });
}

export function useViewCart(eventParams: EventParams) {
  const params = createEventParams(eventParams);
  return useViewCartMSiteDispatch({ ...params });
}

export function useBeginCheckout(eventParams: EventParams) {
  const params = createEventParams(eventParams);
  return useBeginCheckoutMSiteDispatch({ ...params });
}

export function useAddShippingInfo(params: ShippingInfo) {
  const shippingEventParams = createShippingInfo(params);
  return useAddShippingInfoMSiteDispatch({ ...shippingEventParams });
}

export function useAddPaymentInfo(params: PaymentInfo) {
  const paymentEventParams = createPaymentInfo(params);
  return useAddPaymentInfoMSiteDispatch({ ...paymentEventParams });
}

export function usePurchase({
  coupon,
  payments,
  shipping,
  shipping_tier,
  orderId,
  saved_card = 'nao',
  ...eventParams
}: PurchaseEventParams) {
  const params = createEventParams(eventParams);
  const card_type = payments
    ?.reduce<string>((acc, { paymentMethod }) => acc + (paymentMethod ? `${getValue(paymentMethod)}:` : ''), '')
    ?.replace(/:$/, '')
    ?.toLowerCase();
  const transaction_id = orderId ? orderId : undefined;
  let purchaseEventParams = params;
  if (card_type) {
    purchaseEventParams = { ...purchaseEventParams, card_type };
  }
  if (coupon) {
    purchaseEventParams = { ...purchaseEventParams, coupon };
  }
  if (transaction_id) {
    purchaseEventParams = { ...purchaseEventParams, transaction_id };
  }
  if (shipping !== undefined) {
    purchaseEventParams = { ...purchaseEventParams, shipping };
  }
  if (shipping_tier) {
    purchaseEventParams = { ...purchaseEventParams, shipping_tier };
  }
  if (saved_card) {
    purchaseEventParams = { ...purchaseEventParams, saved_card };
  }
  return usePurchaseMSiteDispatch({
    ...purchaseEventParams,
  });
}

export function useRemoveFromCart(eventParams: EventParams) {
  const params = createEventParams(eventParams);
  return useRemoveFromCartMSiteDispatch({ ...params });
}

export function useException(params: ExceptionParams) {
  return useExceptionDispatch({ ...params });
}

export function useExceptionCheckout(
  params: Omit<ExceptionParams, 'flow'> & { cart_id?: string; coupon_name?: string },
) {
  return useExceptionDispatch({
    ...params,
    flow: 'checkout:msite',
  });
}

export function useAddToCart(eventParams: EventParams) {
  const params = createEventParams(eventParams);
  return useAddToCartMSiteDispatch({ ...params });
}

export interface SuccessEventParams {
  description: string;
  flow?: 'checkout';
  coupon_name: string;
  cart_id: string;
}

export function useSuccess({ description, flow = 'checkout', coupon_name, cart_id }: SuccessEventParams) {
  const params = {
    description: sanitizeText(description),
    flow,
    coupon_name,
    cart_id,
  };
  return useEventDispatch(CUSTOM_EVENT_NAMES.SUCCESS, params);
}

export interface EventSelectContentCouponParams extends EventSelectContentCoupon {
  cart_id: string;
}

export interface EventSelectContentCheckoutParams extends EventSelectContent {
  cart_id?: string;
  user_id?: string;
}

export function useSelectContentCheckout(params: Omit<EventSelectContentCheckoutParams, 'flow'>) {
  const defaultParams = {
    flow: 'checkout:msite',
  };
  return useSelectContentDispatch<EventSelectContentCheckoutParams>({ ...defaultParams, ...params });
}

export function useAllowedPeople({ contentType, flow, cartId }: { contentType: string; flow: string; cartId: string }) {
  const eventParams = {
    flow,
    cart_id: cartId,
    device_type: 'msite',
    content_type: contentType,
  };
  return useEventDispatch(EVENT_NAMES.SELECT_CONTENT, eventParams);
}

export function useSharedBagSelectContent({
  contentType,
  flow,
  cartId,
}: {
  contentType: string;
  flow: string;
  cartId: string;
}) {
  const eventParams = {
    flow,
    cart_id: cartId,
    device_type: 'msite',
    content_type: contentType,
  };
  return useEventDispatch(EVENT_NAMES.SELECT_CONTENT, eventParams);
}

export function useSharedBagUserProperties({ isSharedBag }: { isSharedBag: boolean }) {
  const eventParams = {
    guest_cart: getGuestCardValue(isSharedBag),
  };
  return analyticsSharedBagUserProperties('user_properties', eventParams);
}
