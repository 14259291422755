import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  containerRef?: React.RefObject<HTMLElement>;
  children: ReactNode;
}

export const Portal = ({ children, containerRef }: IPortalProps) => {
  const containerElement = containerRef?.current ?? globalThis?.document?.body;

  return createPortal(children, containerElement);
};
