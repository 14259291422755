import sha1Encrypt from 'utils/functions/encrypt';
import { ProductType, ProductAttributeType, ProductVariationType } from 'modules/Bag/components/ProductList/types';
import { getCheckoutOptions, getDimension17, sanitizeText } from './helpers';
import { AnalyticsStep, Values } from './types';

type PropertyType = ProductAttributeType | ProductVariationType;

type DeliveryMethodType = {
  type?: string | undefined;
  date?: string | undefined;
  cost?: number | undefined;
};

// Helpers
const dispatchGTM = (gtmParams: {}) => {
  if (!gtmParams) return;
  const window = global.window as any;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(gtmParams);
};

const getValue = (param: string | undefined) => {
  if (typeof param !== 'string') {
    return undefined;
  }

  return sanitizeText(param);
};

const getCurrencyValue = (number: number | undefined) => number?.toFixed(2)?.replace('.', ',');

const getProperty = (properties: PropertyType[], param: string, key: 'code' | 'key') => {
  let property: PropertyType | undefined;
  if (properties) {
    property = properties.find((curProperty) => curProperty[key] === param);
  }

  return property?.value;
};

const formatDeliveryMethod = (deliveryMethod: any) => {
  const formattedDeliveryMethod: DeliveryMethodType = {};
  formattedDeliveryMethod.type = deliveryMethod?.description;
  switch (deliveryMethod?.description) {
    case 'Retira rápido':
      formattedDeliveryMethod.date = 'Retira em 4h';
      formattedDeliveryMethod.cost = 0;
      break;
    case 'Retira em loja':
      formattedDeliveryMethod.date = deliveryMethod?.arrivalDateStore?.slice(0, 10);
      formattedDeliveryMethod.cost = 0;
      break;
    case 'Agendada':
      formattedDeliveryMethod.date = deliveryMethod?.additionalInformation?.deliveryDate;
      formattedDeliveryMethod.cost = deliveryMethod?.finalShippingCost;
      break;
    // 'Normal', 'Expresso' or any other
    default:
      formattedDeliveryMethod.date =
        deliveryMethod?.deliveryEstimateDateExact || deliveryMethod?.deliveryEstimateBusinessDay?.slice(0, 10);
      formattedDeliveryMethod.cost = deliveryMethod?.finalShippingCost;
  }

  return formattedDeliveryMethod;
};

const salesChannel = (channelCode: string) =>
  channelCode === 'e.store-Mobile' ? 'venda-em-loja' : 'venda-pelo-whatsapp';

// Dimensões Globais
export const analyticsGlobalDimensions = (
  userId: string | null | undefined,
  authStatus: boolean,
  cartId: string | null | undefined,
  storeName?: string | null | undefined,
  sellerId?: number | null | undefined,
  channelCode?: string | null | undefined,
) => {
  const eventParams = {
    dimension1: userId ? sha1Encrypt(userId)?.toString() : undefined,
    dimension4: authStatus ? 'logged-in' : 'logged-out',
    dimension5: cartId,
    dimension6: storeName && sanitizeText(storeName),
    dimension7: sellerId,
    dimesion23: channelCode && salesChannel(channelCode),
  };
  dispatchGTM(eventParams);
};

export const analyticsResetPasswordCallback = (isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:redefinir-senha',
    eventAction: 'callback:redefinir-senha',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsRegisterInputs = (field: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:criar-conta',
    eventAction: 'interacao:campo',
    eventLabel: `preencheu:${sanitizeText(field)}`,
  };
  dispatchGTM(eventParams);
};

// Nova Sacola
export const analyticsBagCallback = (isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:sacola',
    eventAction: 'callback:sacola',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsTrashButtonClick = (buttonName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:sacola:modal:remover-esse-item',
    eventAction: 'clique:botao',
    eventLabel: sanitizeText(buttonName),
  };
  dispatchGTM(eventParams);
};

export const analyticsContinueButtonClick = () => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:sacola',
    eventAction: 'clique:botao',
    eventLabel: 'continuar',
  };
  dispatchGTM(eventParams);
};

export const analyticsPageItemClick = (pageName: string, clickedItem: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(pageName)}`,
    eventAction: 'clique:botao',
    eventLabel: sanitizeText(clickedItem),
  };
  dispatchGTM(eventParams);
};

export const analyticsPageItemSelection = (pageName: string, selectedOption: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(pageName)}`,
    eventAction: 'clique:botao',
    eventLabel: `selecionar:${sanitizeText(selectedOption)}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsNavigateToPrevPage = (pageName: string, prevPage: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(pageName)}`,
    eventAction: 'clique:botao',
    eventLabel: `voltar:${sanitizeText(prevPage)}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsNavigateToNextPage = (pageName: string, nextPage: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(pageName)}`,
    eventAction: 'clique:botao',
    eventLabel: `ir:${sanitizeText(nextPage)}`,
  };
  dispatchGTM(eventParams);
};

// Checkout
export const analyticsCheckoutOptionClick = (buttonName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:compra',
    eventAction: 'clique:botao',
    eventLabel: sanitizeText(buttonName),
  };
  dispatchGTM(eventParams);
};

export const analyticsDeliveryAddressCallback = (isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:endereco',
    eventAction: 'callback:endereco',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsAddressButtonClick = (buttonName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:endereco',
    eventAction: 'clique:botao',
    eventLabel: sanitizeText(buttonName),
  };
  dispatchGTM(eventParams);
};

export const analyticsAddressFieldFilling = (formName: string, fieldName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(formName)}`,
    eventAction: 'interacao:campo',
    eventLabel: `preencheu:${sanitizeText(fieldName)}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsAddressSaveButtonClick = (formName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(formName)}`,
    eventAction: 'clique:botao',
    eventLabel: 'salvar',
  };
  dispatchGTM(eventParams);
};

export const analyticsAddressSaveButtonCallback = (formName: string, isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: `estore:checkout:${sanitizeText(formName)}`,
    eventAction: 'callback:endereco',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsCouponButtonClick = (couponName: string, buttonName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:cupom',
    eventAction: 'clique:botao',
    eventLabel: `${couponName?.toLowerCase()}:${sanitizeText(buttonName)}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsCouponButtonCallback = (couponName: string, isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:cupom',
    eventAction: `callback:cupom:${couponName?.toLowerCase()}`,
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsPaymentOptionClick = (selectedOption: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:pagamento',
    eventAction: 'clique:opcao',
    eventLabel: sanitizeText(selectedOption),
  };
  dispatchGTM(eventParams);
};

export const analyticsCreditCardCallback = (isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:pagamento',
    eventAction: 'callback:dados-cartao',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsInstallmentOptionClick = (quantity: number, amount: number) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:pagamento',
    eventAction: 'interacao:parcelamento',
    eventLabel: `${quantity.toString()}x${amount.toString().replace(/\./g, ',')}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsPaymentContinueButtonClick = (pageName: string) => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:pagamento',
    eventAction: 'clique:botao',
    eventLabel: `${sanitizeText(pageName)}:continuar`,
  };
  dispatchGTM(eventParams);
};

export const analyticsFinishButtonClick = () => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:compra',
    eventAction: 'clique:botao',
    eventLabel: 'finalizar',
  };
  dispatchGTM(eventParams);
};

export const analyticsFinishButtonCallback = (isResolved: boolean, errorMessage?: string) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${getValue(errorMessage)}`;
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:compra',
    eventAction: 'callback:finalizar-compra',
    eventLabel,
  };
  dispatchGTM(eventParams);
};

export const analyticsModalContinueButtonClick = () => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:checkout:compra:modal:pagamento-recusado',
    eventAction: 'clique:botao',
    eventLabel: 'continuar',
  };
  dispatchGTM(eventParams);
};

export const analyticsCopyCodeButton = () => {
  const eventParams = {
    event: 'event',
    eventCategory: 'estore:purchase',
    eventAction: 'clique:botao',
    eventLabel: 'copiar-codigo-barras',
  };
  dispatchGTM(eventParams);
};

// Enhanced Ecommerce
export const analyticsAdditionOfProductToCart = (product: ProductType, productQuantity: number) => {
  const eventParams = {
    event: 'addToCart',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'add',
    ecommerce: {
      add: {
        products: [
          {
            name: getValue(product?.title),
            id: getValue(product?.skuGroup),
            price: getCurrencyValue(product?.salePrice),
            brand: getValue(getProperty(product?.attributes, 'brand', 'code')),
            category: product?.categories?.map((category) => category?.tree)?.join(';'),
            variant: getValue(getProperty(product?.attributes, 'dco', 'code')),
            quantity: productQuantity.toString(),
            dimension15: getValue(getProperty(product?.variations, 'color', 'key')),
            dimension16: undefined,
            dimension17: undefined,
            dimension18: getValue(getProperty(product?.variations, 'size', 'key')),
            dimension19: getValue(getProperty(product?.attributes, 'gm', 'code')),
            dimension20: getCurrencyValue(product?.listPrice),
            dimension21: getValue(getProperty(product?.attributes, 'lifestyle', 'code')),
            dimension22: getValue(getProperty(product?.attributes, 'gender', 'code')),
          },
        ],
      },
    },
  };
  dispatchGTM(eventParams);
};

export const analyticsRemovalOfProductFromCart = (product: ProductType, productQuantity: number) => {
  const eventParams = {
    event: 'removeFromCart',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'remove',
    ecommerce: {
      remove: {
        products: [
          {
            name: getValue(product?.title),
            id: getValue(product?.skuGroup),
            price: getCurrencyValue(product?.salePrice),
            brand: getValue(getProperty(product?.attributes, 'brand', 'code')),
            category: product?.categories?.map((category) => category?.tree)?.join(';'),
            variant: getValue(getProperty(product?.attributes, 'dco', 'code')),
            quantity: productQuantity.toString(),
            dimension15: getValue(getProperty(product?.variations, 'color', 'key')),
            dimension16: undefined,
            dimension17: undefined,
            dimension18: getValue(getProperty(product?.variations, 'size', 'key')),
            dimension19: getValue(getProperty(product?.attributes, 'gm', 'code')),
            dimension20: getCurrencyValue(product?.listPrice),
            dimension21: getValue(getProperty(product?.attributes, 'lifestyle', 'code')),
            dimension22: getValue(getProperty(product?.attributes, 'gender', 'code')),
          },
        ],
      },
    },
  };
  dispatchGTM(eventParams);
};

export const analyticsCheckoutFlowPageLoad = (
  step: Values<typeof AnalyticsStep>,
  products: any,
  cartState: any,
  checkoutState: any,
) => {
  const formattedDeliveryMethods = cartState?.deliveryMethods?.map((deliveryMethod: any) =>
    formatDeliveryMethod(deliveryMethod),
  );
  const eventParams = {
    event: 'checkout',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'checkout',
    noInteraction: '1',
    dimension10: checkoutState?.paymentMethod?.toLowerCase(),
    dimension11: formattedDeliveryMethods?.map((deliveryMethod: any) => getValue(deliveryMethod?.type))?.join(';'),
    dimension12: checkoutState?.installmentInfo?.quantity?.toString() || '1',
    dimension14: formattedDeliveryMethods?.map((deliveryMethod: any) => getValue(deliveryMethod?.date))?.join(';'),
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
        products: products?.map((product: any) => ({
          name: getValue(product?.title),
          id: getValue(product?.skuGroup),
          price: getCurrencyValue(product?.salePrice),
          brand: getValue(getProperty(product?.attributes, 'brand', 'code')),
          category: product?.categories?.map((category: any) => category?.tree)?.join(';'),
          variant: getValue(getProperty(product?.attributes, 'dco', 'code')),
          quantity: product?.quantity?.toString(),
          dimension13: getValue(product?.sku),
          dimension15: getValue(getProperty(product?.variations, 'color', 'key')),
          dimension16: undefined,
          dimension17: undefined,
          dimension18: getValue(getProperty(product?.variations, 'size', 'key')),
          dimension19: getValue(getProperty(product?.attributes, 'gm', 'code')),
          dimension20: getCurrencyValue(product?.listPrice),
          dimension21: getValue(getProperty(product?.attributes, 'lifestyle', 'code')),
          dimension22: getValue(getProperty(product?.attributes, 'gender', 'code')),
          dimension24: undefined,
        })),
      },
    },
  };
  dispatchGTM(eventParams);
};

export const analyticsDeliveryMethods = (deliveryMethods, sellerId) => {
  const eventLabel = getCheckoutOptions(deliveryMethods);
  const dimension17 = getDimension17(sellerId);

  const eventParams = {
    event: 'checkoutOption',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'checkoutOption',
    eventLabel,
    ecommerce: {
      checkout_option: {
        actionField: {
          step: AnalyticsStep.Shipping,
          option: 'shipping',
        },
      },
    },
    dimension17,
  };

  dispatchGTM(eventParams);
};

export const analyticsPurchaseConfirmation = (products: any, cartState: any, checkoutState: any) => {
  const formattedDeliveryMethods = cartState?.deliveryMethods?.map((deliveryMethod: any) =>
    formatDeliveryMethod(deliveryMethod),
  );
  const eventParams = {
    event: 'purchase',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'purchase',
    noInteraction: '1',
    metric1: checkoutState?.couponAmount?.toString()?.replace(/\./g, ','),
    dimension8:
      checkoutState?.paymentMethod === 'CreditCard'
        ? 'credito'
        : checkoutState?.paymentMethod === 'RchloCard'
        ? 'riachuelo'
        : undefined,
    dimension9: checkoutState?.creditCardInfo?.cardBrand,
    dimension10: checkoutState?.paymentMethod?.toLowerCase(),
    dimension11: formattedDeliveryMethods?.map((deliveryMethod: any) => getValue(deliveryMethod?.type))?.join(';'),
    dimension12: checkoutState?.installmentInfo?.quantity?.toString() || '1',
    dimension14: formattedDeliveryMethods?.map((deliveryMethod: any) => getValue(deliveryMethod?.date))?.join(';'),
    dimension23: undefined,
    ecommerce: {
      purchase: {
        actionField: {
          id: checkoutState?.order?.orderId?.toString(),
          affiliation: 'riachuelo',
          revenue: checkoutState?.total?.toString()?.replace(/\./g, ','),
          shipping: checkoutState?.freightAmount?.toString()?.replace(/\./g, ','),
          tax: '0',
          coupon: checkoutState?.coupon?.toLowerCase(),
        },
        products: products?.map((product: any) => ({
          name: getValue(product?.title),
          id: getValue(product?.skuGroup),
          price: getCurrencyValue(product?.salePrice),
          brand: getValue(getProperty(product?.attributes, 'brand', 'code')),
          category: product?.categories?.map((category: any) => category?.tree)?.join(';'),
          variant: getValue(getProperty(product?.attributes, 'dco', 'code')),
          quantity: product?.quantity?.toString(),
          dimension13: getValue(product?.sku),
          dimension15: getValue(getProperty(product?.variations, 'color', 'key')),
          dimension16: undefined,
          dimension17: undefined,
          dimension18: getValue(getProperty(product?.variations, 'size', 'key')),
          dimension19: getValue(getProperty(product?.attributes, 'gm', 'code')),
          dimension20: getCurrencyValue(product?.listPrice),
          dimension21: getValue(getProperty(product?.attributes, 'lifestyle', 'code')),
          dimension22: getValue(getProperty(product?.attributes, 'gender', 'code')),
          dimension24: undefined,
        })),
      },
    },
  };
  dispatchGTM(eventParams);
};

const reviewOptions = {
  1: 'muito-insatisfeito',
  2: 'insatisfeito',
  3: 'indiferente',
  4: 'satisfeito',
  5: 'muito-satisfeito',
};

export const analyticsCsatModal = () => {
  const eventParams = {
    event: 'csat',
    eventCategory: 'rchlover:csat',
    eventAction: 'visualizacao:modal',
    eventLabel: 'csat-visualizado',
  };
  dispatchGTM(eventParams);
};

export const analyticsCloseCsatModal = (buttonLocation: string, review: number) => {
  const getReview = (review) => reviewOptions[review] || 'default';
  const eventParams = {
    event: 'csat',
    eventCategory: 'rchlover:csat',
    eventAction: 'clique:botao:modal',
    eventLabel: `fechar:${sanitizeText(buttonLocation)}:${getReview(review)}`,
  };
  dispatchGTM(eventParams);
};

export const analyticsSendCsat = (review, feedback) => {
  const getReview = (review) => reviewOptions[review] || 'default';

  const eventParams = {
    event: 'csat',
    eventCategory: 'rchlover:csat',
    eventAction: 'clique:botao:modal',
    eventLabel: `${getReview(review)}:${sanitizeText(feedback) || 'default'}:sugestao-enviada`,
  };
  dispatchGTM(eventParams);
};

export const analyticsSharedBagUserProperties = (event, params) => {
  const eventParams = { event, ...params };
  dispatchGTM(eventParams);
};
