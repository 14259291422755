import * as S from './styles';

import { Button } from '..';
import { useState } from 'react';
import { ReactComponent as LoaderIcon } from 'utils/assets/images/svg/loader.svg';
import { ReactComponent as WifiOff } from 'utils/assets/images/svg/wifi_off.svg';

export const OfflineModal = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClick = () => {
    onClick();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  return (
    <S.ModalWrapper>
      <WifiOff />
      <p>Ops! Não identificamos conexão com internet.</p>
      <Button color="secondary" onClick={handleClick} style={{ marginTop: '20px' }} disabled={isLoading}>
        {isLoading ? <LoaderIcon /> : 'Tentar novamente'}
      </Button>
    </S.ModalWrapper>
  );
};
