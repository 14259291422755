import { styled } from 'utils/styles';

export const StoreName = styled('span', {
  d: 'block',
  typo: 'heading3',
  color: '$black',
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

export const StoreAddress = styled('span', {
  d: 'block',
  typo: 'heading3',
  color: '$black',
});

export const DeliveryPeriod = styled('span', {
  d: 'block',
  typo: 'heading3',
  color: '$gray65',
});

export const DeliveryForecastWrapper = styled('div', {
  d: 'grid',
  gridAutoFlow: 'column',
  jc: 'start',
  ai: 'center',
  gap: '12px',
  '& svg': {
    width: '18px',
    '& path': {
      fill: '$blue',
    },
  },
});

export const DeliveryForecast = styled('p', {
  typo: 'heading3',
  color: '$blue',
  textTransform: 'lowercase',
});
