import { PRODUCT_ERROR_MESSAGES } from 'utils/shared/constants';

export const isSoldOut = (soldOut: boolean, remainingUnits: number) => soldOut || remainingUnits === 0;
export const hasExceededRemainingUnits = (quantity, remainingUnits) => quantity > remainingUnits;
interface IErrorConditions {
  condition: boolean;
  message: string;
}

export const productIsUnavailable = (product, quantity) => {
  const { remainingUnits, soldOut } = product;

  // ordenar por prioridade
  const conditions: IErrorConditions[] = [
    {
      condition: isSoldOut(soldOut, remainingUnits),
      message: PRODUCT_ERROR_MESSAGES.UNAVAILABLE,
    },
    {
      condition: hasExceededRemainingUnits(quantity, remainingUnits),
      message: PRODUCT_ERROR_MESSAGES.QUANTITY_UNAVAILABLE.replace('%qtt%', remainingUnits),
    },
  ];

  const error = conditions.reduce<false | string>((isError, { condition, message }) => {
    return isError || (condition && message);
  }, false);

  return error;
};

interface IAction {
  type: string;
  sku: string;
  product: any;
}

interface IActionRemove extends IAction {
  type: 'remove';
}

interface IActionQuantyty extends IAction {
  type: 'decrease' | 'increase';
  quantity: number;
}

export const handleOnDecrease = (product: any, quantity: number): IActionQuantyty | IActionRemove => {
  const isLastItem = quantity - 1 === 0;

  if (isLastItem) {
    return {
      type: 'remove',
      sku: product.sku,
      product,
    };
  }

  const hasExceeded = hasExceededRemainingUnits(quantity, product.remainingUnits);

  const removeQuantity = hasExceeded ? quantity - product.remainingUnits : 1;

  return {
    type: 'decrease',
    sku: product.sku,
    product,
    quantity: removeQuantity,
  };
};

export const isQuantityAction = (action: IActionRemove | IActionQuantyty): action is IActionQuantyty =>
  (action as IActionQuantyty).quantity !== undefined;

export const isRCHLOSeller = (sellerId: string) => sellerId === 'RCHLO';

export const getSellerDisplayName = (sellerId: string, sellerDisplayName: string) => {
  return isRCHLOSeller(sellerId) ? 'Riachuelo' : sellerDisplayName;
};
