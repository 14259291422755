import { styled } from 'utils/styles';

export const Wrapper = styled('div', {
  width: '100%',
  position: 'fixed',
  bottom: '0',
  left: '0',
  zIndex: '999',
  bg: '$white',
  br: '20px 20px 0 0',
});

export const TitleWrapper = styled('div', {
  p: '20px 16px',
  d: 'grid',
  jc: 'center',
  position: 'relative',
  '& svg': {
    width: '14px',
    cursor: 'pointer',
    position: 'absolute',
    top: '23px',
    right: '23px',
    '& path': {
      fill: '$black',
    },
  },
});

export const Title = styled('h1', {
  typo: 'heading1',
  color: '$black',
});

export const Line = styled('hr', {
  borderTop: '1px solid $gray30',
  borderRight: 'none',
  borderBottom: 'none',
  borderLeft: 'none',
});

export const ContentWrapper = styled('div', {
  maxHeight: '80vh',
  overflowX: 'hidden',
  overflowY: 'auto',
});
