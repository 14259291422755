import { useLoginMSite, useExceptionDispatch, ExceptionParams } from '@estore/gtm-v4';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectQrCode, selectUser } from 'store/helpers/selectors';
import { getStoreName } from './utils/text/getStoreName';

export const useGtagLogin = () => {
  const qrCodeInfo = useSelector(selectQrCode);
  const { isAuthenticated } = useSelector(selectUser);

  useEffect(() => {
    if (isAuthenticated) {
      useLoginMSite({
        store: getStoreName(qrCodeInfo?.storePrefix, qrCodeInfo?.storeName),
      });
    }
  }, [isAuthenticated]);
};

export const useGtagExceptionLogin = (params: ExceptionParams) => {
  useExceptionDispatch(params);
};
