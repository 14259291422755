import type { Seller, SellerItem } from '@estore/types';
import { getAttributeByCode, removeDuplicateTerm } from './utils';

/**
 * Função que cria texto lifestyle concatenado por um `splitter`
 * @param items Items do Carrinho.
 * @param splitter Divisor de termos.
 * @returns string com os valores concatenados por `:`
 */
export function createItemProductLifestyle(items: SellerItem[] = [], splitter = ':') {
  if (items.length === 1) {
    return getAttributeByCode(items?.[0]?.product?.attributes, 'lifestyle', 'value')?.toString();
  }
  const text = items
    .reduce<string>((acc, { product }) => {
      const value = getAttributeByCode(product?.attributes, 'lifestyle', 'value')?.toString();
      return acc + `${!value ? '' : `${value}${splitter}`}`;
    }, '')
    ?.replace(/:$/g, '');

  return !text ? undefined : removeDuplicateTerm(text, splitter);
}

/**
 * Função que converte uma lista agrupado por sellers em uma string formatado
 * @param sellers Lista de produtos agrupados por Seller.
 * @param splitter Divisor do texto.
 * @returns Texto tratado
 */
export function listItemProductLifestyleWithSellers(sellers: Seller[] = [], splitter = ':') {
  if (sellers.length === 1) {
    return createItemProductLifestyle(sellers?.[0].items, splitter);
  }
  const text = sellers
    .reduce<string>((acc, { items }) => {
      const text = createItemProductLifestyle(items, splitter);
      return acc + `${!text ? '' : `${text}${splitter}`}`;
    }, '')
    ?.replace(/:$/g, '');

  return !text ? undefined : removeDuplicateTerm(text, splitter);
}
