import { type IShippingMethod, type ShippingGroup } from 'store/ducks/types';
import { getValue } from './utils';

/**
 * Função que obtém os métodos selecionados.
 * @param shippingGroups Agrupamento de produtos por tipo de entregas disponíveis.
 * @returns Métodos de entregas selecionados.
 */
export function getSelectedShippingMethodGroup(shippingGroups: ShippingGroup[] = []) {
  return shippingGroups.reduce<IShippingMethod[]>(
    (acc, { methods }) => [
      ...acc,
      ...Object.entries(methods).reduce<IShippingMethod[]>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, [_, value]) => [
          ...acc,
          ...value.reduce<IShippingMethod[]>((acc, curr) => (curr.selected ? [...acc, { ...curr }] : [...acc]), []),
        ],
        [],
      ),
    ],
    [],
  );
}
/**
 * Função que converte meios de entrega em string formatado.
 * @param deliveryMethods Métodos de entrega.
 * @param splitter Divisor de texto
 * @returns Texto formatado.
 */
export function listShippingTier(deliveryMethods: IShippingMethod[], splitter = ':') {
  const shippingTier = deliveryMethods
    .reduce<string>((acc, { description }) => {
      return acc + (description ? `${getValue(description)}${splitter}` : '');
    }, '')
    .replace(/:$/g, '')
    .toLowerCase();
  return shippingTier === '' ? undefined : shippingTier;
}

/**
 * Função que converte Métodos de envio selecionado para string formatado.
 * @param shippingGroups Agrupamento de produtos por tipo de entregas disponíveis.
 * @param splitter Divisor do texto.
 * @returns Texto formatado com divisor.
 */
export function listShippingTierWithShippingGroups(shippingGroups: ShippingGroup[] = [], splitter = ':') {
  const selectedShippingMethods = getSelectedShippingMethodGroup(shippingGroups);
  if (selectedShippingMethods.length === 0) {
    return;
  }
  if (selectedShippingMethods.length === 1) {
    return getValue(selectedShippingMethods?.[0]?.description);
  }
  return listShippingTier(selectedShippingMethods, splitter);
}
