import { styled } from 'utils/styles';
import { Price as PriceComponent } from '../Price';

export const Container = styled('div', {
  pb: '$4',
  ['&+&']: {
    borderTop: '1px solid $colors$gray10',
    pt: '$3',
  },
});

export const Row = styled('div');

export const Image = styled('img', {
  width: '80px',
});

export const Title = styled('h2', {
  typo: 'heading1',
});

export const Variations = styled('ul', {
  listStyle: 'none',
  typo: 'body2',
});

export const VariationItem = styled('li');

export const Button = styled('button', {
  border: 'none',
  p: '0 $2',
  bg: 'transparent',
});

export const PriceContainer = styled('div', {
  d: 'flex',
  flexFlow: 'column',
  position: 'relative',
});

export const Price = styled(PriceComponent, {
  typo: 'heading1',
});

export const SalePrice = styled(PriceComponent, {
  typo: 'heading2',
  textDecoration: 'line-through',
  d: 'inline-flex',
});

export const DiscountPercentage = styled(PriceComponent, {
  typo: 'heading2',
  d: 'inline',
});
