import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer as authCustomerState } from './ducks/authCustomer';
import { reducer as qrCodeState } from './ducks/qrcode';
import { reducer as cartState } from './ducks/cart';
import { reducer as addressState } from './ducks/address';
import { reducer as checkoutState } from './ducks/checkout';
import { reducer as genericError } from './ducks/genericError';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  storage,
};
import { reducer as appConfigState } from './ducks/appConfig';

const rootReducer = combineReducers({
  addressState,
  authCustomerState,
  cartState,
  checkoutState,
  qrCodeState,
  appConfigState,
  genericError,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
