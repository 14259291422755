const defaultFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
];

export const fonts = {
  heading: `Archivo, ${defaultFonts}`,
  body: `Archivo, ${defaultFonts}`,
  component: `"Arboria-Book", "Arboria", Archivo, ${defaultFonts}`,
};
