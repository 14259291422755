import * as S from './Toast.styles';

import { Backdrop } from '../Backdrop';
import { ReactComponent as ConfirmationIcon } from 'utils/assets/images/svg/confirmation.svg';
import { forwardRef, useEffect } from 'react';

export interface IToastProps {
  content?: string;
  onClose: () => void;
  hasIcon?: boolean;
  autoClose?: number;
}

export const Toast = forwardRef<HTMLDivElement, IToastProps>(
  ({ content, onClose, hasIcon = true, autoClose = 0, ...rest }, ref) => {
    useEffect(() => {
      let timer: NodeJS.Timeout | null = null;
      if (autoClose > 0) timer = setTimeout(() => onClose(), autoClose);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [autoClose]);

    return (
      <>
        <Backdrop theme="light" onClick={onClose} />
        <S.ToastWrapper ref={ref} onClick={onClose} {...rest}>
          <S.Content>{content}</S.Content>
          {hasIcon && <ConfirmationIcon />}
        </S.ToastWrapper>
      </>
    );
  },
);

Toast.displayName = 'Toast';
