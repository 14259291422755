import * as S from './styles';

export const RemoveItemModal = ({ onClickRemove, onClickContinue }) => {
  return (
    <S.ModalWrapper>
      <p>Deseja remover este item da sacola?</p>
      <S.ButtonWrapper color="secondary" onClick={onClickRemove}>
        REMOVER ITEM
      </S.ButtonWrapper>
      <S.ButtonWrapper color="secondary" variant="outlined" onClick={onClickContinue}>
        VOLTAR PARA A SACOLA
      </S.ButtonWrapper>
    </S.ModalWrapper>
  );
};
