export const NO_NUMBER = 'S/N';
export const API = process.env.REACT_APP_API_DOTNET_FACADE_NEW;

export const stepperArray = [
  {
    key: 'sacola',
    label: 'sacola',
  },
  {
    key: 'modo de envio',
    label: 'modo de envio',
  },
  {
    key: 'pagamento',
    label: 'pagamento',
  },
  {
    key: 'sucesso',
    label: 'sucesso',
  },
];

export const schedulingPeriods: Record<number, string> = {
  1: 'Manhã',
  2: 'Tarde',
};

export const shippingMethodKey = {
  scheduled: 'Agendada',
};

export enum PRODUCT_ERROR_MESSAGES {
  UNAVAILABLE = 'Este produto encontra-se indisponível.\nPor favor, remova-o da sacola.',
  QUANTITY_UNAVAILABLE = 'Restam apenas %qtt% unidades deste item no estoque.\nPor favor, ajuste a quantidade antes de continuar.',
}
