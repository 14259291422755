export const cleanEmptySpaces = (str: string) => str.replace(/\s/g, '');

export const cleanDotsAndHyphens = (str: string) => str.replace(/[\.\-\/]/g, '');

export const cleanCardNumber = (cardNumber: string) => cleanDotsAndHyphens(cleanEmptySpaces(cardNumber));

export const stringLengthRespectLimits = (str: string, minLimit: number, maxLimit: number) => {
  return str.length >= minLimit && str.length <= maxLimit;
};

export const stringHasLength = (str: string, length: number) => str.length === length;

export const extractNumbers = (text: string) => text.replace(/\D/g, '');
