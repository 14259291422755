import { styled } from 'utils/styles';

export const Container = styled('div', {
  bg: '$white',
  bs: '$default',
  p: '$3 $3 0 $3',
  mb: '$spacing20',
});

export const Title = styled('h2', {
  typo: 'body2',
  pb: '$3',
});

export const Seller = styled('span', {
  color: '$blue',
});
