import { ReactNode } from 'react';
import * as S from './Content.styles';

interface ContentProps {
  title: string;
  description: ReactNode;
  lineX?: ReactNode;
  lineY?: ReactNode;
}

const Content = ({ title, description, lineX, lineY }: ContentProps) => {
  return (
    <>
      <S.ContentTitle>{title}</S.ContentTitle>
      <S.ContentDivider>
        {lineX}
        <S.ContentDescription>{description}</S.ContentDescription>
      </S.ContentDivider>
      {lineY}
    </>
  );
};

export default Content;
