import { styled } from 'utils/styles';

export const Wrapper = styled('div', {
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '888',
  opacity: '0.5',
  variants: {
    theme: {
      light: {
        bg: '$white',
      },
      dark: {
        bg: '$black',
      },
    },
  },
});
