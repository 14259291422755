import * as S from './styles';

import { Button } from '..';
import { selectCart } from 'store/helpers/selectors';
import { useSelector } from 'react-redux';

export const ErrorBoundaryModal = ({ onClick }) => {
  const { recoveryCart, cart } = useSelector(selectCart);

  return (
    <S.ModalWrapper>
      <p>Ops! Ocorreu um erro inesperado.</p>
      <p>Por favor, tente novamente mais tarde.</p>
      <Button color="secondary" onClick={onClick} style={{ marginTop: '20px' }}>
        ATUALIZAR PÁGINA
      </Button>

      <p style={{ marginTop: '8px', opacity: 0.6, wordBreak: 'break-all' }}>ERRO{recoveryCart?.code || cart?.cartId}</p>
    </S.ModalWrapper>
  );
};
