import { Helmet as ReactHelmet } from 'react-helmet-async';

function Helmet({ children, ...rest }) {
  if (!ReactHelmet) {
    throw new Error("react-helmet-async doesn't implemented!");
  }
  return <ReactHelmet {...rest}>{children}</ReactHelmet>;
}

export default Helmet;
