import { globalCss } from '../styles.config';

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    m: 0,
    p: 0,
  },
  body: {
    fontFamily: '$body',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontSize: '16px',
    height: '100vh',
    bg: '$background',
    opacity: '1',
    '&.gray': {
      borderColor: '$wildSand',
    },
  },
  '#root': {
    height: '100%',
    width: '100%',
  },
  'input, button, label, select': {
    fontFamily: '$body',
  },
  textarea: {
    fontFamily: '$body',
    fontSize: '0.75rem',
    fontWeight: '350',
    lineHeight: '1rem',
    borderRadius: '8px',
    border: 'border: 1px solid #757575',
    boxSizing: 'border-box',
  },
  '.grecaptcha-badge': {
    visibility: 'hidden',
  },
});
