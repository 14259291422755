import { Dispatch } from 'redux';

import { ActionType, QrCodeType } from './types';
import { getQrCodeProperties } from 'services';
import { GlobalTypes } from './global';

// Action Types
const Types = {
  GET_QRCODE_INFO: 'qrcode/GET_QRCODE_INFO',
  GET_QRCODE_INFO_SUCCESS: 'qrcode/GET_QRCODE_INFO_SUCCESS',
  GET_QRCODE_INFO_ERROR: 'qrcode/GET_QRCODE_INFO_ERROR',
  RESET_QRCODE_ERROR: 'qrcode/RESET_QRCODE_ERROR',
};

const initialState: QrCodeType = {
  cartId: null,
  qrCodeError: false,
  qrCode: null,
};

export const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case Types.GET_QRCODE_INFO:
      return { ...initialState, qrCode: action.payload };
    case Types.GET_QRCODE_INFO_SUCCESS:
      return { ...state, ...action.payload.data };
    case Types.GET_QRCODE_INFO_ERROR:
      return { ...initialState, qrCodeError: true };
    case Types.RESET_QRCODE_ERROR:
      return { ...state, qrCodeError: false };
    case GlobalTypes.GLOBAL_RESET:
      return { ...initialState };
    default:
      return state;
  }
};

const getQRCodeInfo = (payload) => ({ type: Types.GET_QRCODE_INFO, payload });

const resetQrCodeError = () => ({ type: Types.RESET_QRCODE_ERROR });

const getQRCodeInfoSuccess = (data) => ({
  type: Types.GET_QRCODE_INFO_SUCCESS,
  payload: { data },
});

const getQRCodeInfoError = () => ({ type: Types.GET_QRCODE_INFO_ERROR });

const asyncGetQRCodeInfo = (qrCode: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(getQRCodeInfo(qrCode));
    try {
      const response = await getQrCodeProperties(qrCode);
      const { data } = response.data;
      dispatch(getQRCodeInfoSuccess(data));
    } catch {
      dispatch(getQRCodeInfoError());
    }
  };
};

export { asyncGetQRCodeInfo, resetQrCodeError };
