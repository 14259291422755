import { styled } from 'utils/styles';

export const Wrapper = styled('div', {
  d: 'grid',
  variants: {
    disabled: {
      true: {
        cursor: 'none',
      },
    },
  },
});

export const InputWrapper = styled('div', {
  height: '56px',
  p: '0 12px',
  d: 'grid',
  jc: 'stretch',
  alignContent: 'center',
  bg: '$gray10',
  borderBottom: '2px solid $gray65',
  br: '4px 4px 0 0',
  '&:focus-within': {
    borderColor: '$blue',
    '& label': {
      color: '$blue',
    },
  },
  variants: {
    variant: {
      info: {
        borderColor: '$headings',
        '&:focus-within': {
          borderColor: '$headings',
          '& label': {
            color: '$headings',
          },
        },
      },
      warning: {
        borderColor: '$warning',
        '&:focus-within': {
          borderColor: '$warning',
          '& label': {
            color: '$warning',
          },
        },
      },
      error: {
        borderColor: '$error',
        '&:focus-within': {
          borderColor: '$error',
          '& label': {
            color: '$error',
          },
        },
      },
    },
    disabled: {
      true: {
        '&:focus-within': {
          borderColor: '$gray65',
          '& label': {
            color: '$gray65',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: 'info',
      disabled: true,
      css: {
        borderColor: '$gray65',
      },
    },
    {
      variant: 'warning',
      disabled: true,
      css: {
        borderColor: '$gray65',
      },
    },
    {
      variant: 'error',
      disabled: true,
      css: {
        borderColor: '$gray65',
      },
    },
  ],
});

export const Label = styled('label', {
  maxHeight: '100px',
  typo: 'heading3',
  color: '$gray65',
});

export const Input = styled('input', {
  width: '100%',
  bg: 'transparent',
  border: 'none',
  outline: 'none',
  typo: 'heading1',
  color: '$black',
  '&::placeholder': {
    color: '$gray65',
  },
  '&:disabled': {
    cursor: 'none',
    color: '$gray30',
  },
});

export const MessageWrapper = styled('div', {
  p: '0 12px',
});

export const Message = styled('span', {
  typo: 'body2',
  color: '$headings',
  variants: {
    variant: {
      info: {
        color: '$headings',
      },
      warning: {
        color: '$warning',
      },
      error: {
        color: '$error',
      },
    },
  },
});

export const InfoMessage = styled('span', {
  typo: 'heading3',
  color: '$headings',
});

export const InputContainer = styled('div', {
  d: 'flex',
});
