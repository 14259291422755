import * as S from './styles';

export interface IValueProps {
  value: number;
}

export const Value = ({ value, ...props }: IValueProps) => {
  const checkedValue = Number.isFinite(value) ? value : 0;

  const displayedValue = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(checkedValue);

  return (
    <S.Container {...props} data-clarity-unmask="true">
      {displayedValue}
    </S.Container>
  );
};
