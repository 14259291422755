import { ActionType, IGenericError } from './types';

export interface ISetGenericError {
  title?: string;
  messages: Array<string>;
}

// Action Types
const Types = {
  SET_GENERIC_ERROR: 'genericError/SET_GENERIC_ERROR',
  CLEAR_GENERIC_ERROR: 'genericError/CLEAR_GENERIC_ERROR',
};

const defaultErrorTitle = 'Erro';

const initialState: IGenericError = {
  title: '',
  messages: [],
};

const setGenericError = ({ title, messages }: ISetGenericError) => ({
  type: Types.SET_GENERIC_ERROR,
  payload: {
    title: title || defaultErrorTitle,
    messages,
  },
});
const clearGenericError = () => ({ type: Types.CLEAR_GENERIC_ERROR });

export const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case Types.SET_GENERIC_ERROR:
      return { ...initialState, ...action.payload };
    case Types.CLEAR_GENERIC_ERROR:
      return { ...initialState };
    default:
      return state;
  }
};

export { setGenericError, clearGenericError };
