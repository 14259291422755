/* eslint-disable no-console */
import { ErrorBoundaryModal, ErrorGenericModal } from 'utils/shared/components';
import { Modal } from 'utils/shared/components';
import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-use-error-boundary';
import { asyncGetCart } from 'store/ducks/cart';
import { useSelector } from 'react-redux';
import { selectUser, selectGenericError } from 'store/helpers/selectors';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OfflineModal } from 'utils/shared/components/OfflineModal/OfflineModal';
import { clearGenericError } from 'store/ducks/genericError';

const ErrorBoundary = ({ children }) => {
  const dispatch: any = useDispatch();
  const genericError = useSelector(selectGenericError);
  const [error, resetError]: any = useErrorBoundary((_, errorInfo) => {
    console.error(errorInfo as unknown as Error);
  });
  const { authToken } = useSelector(selectUser);
  const history = useHistory();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  const handleError = () => {
    resetError();
    dispatch(asyncGetCart({ authToken })).then(() => {
      history.push(history.location.pathname);
    });
  };

  const handleClearGenericError = () => {
    dispatch(clearGenericError());
  };

  if (error) {
    return (
      <>
        {children}
        <Modal
          title="Erro"
          content={<ErrorBoundaryModal onClick={handleError} />}
          hasCloseButton
          onClose={handleError}
        />
      </>
    );
  }

  if (genericError?.messages?.length) {
    return (
      <>
        {children}
        <Modal
          title={genericError.title}
          content={<ErrorGenericModal onClick={handleClearGenericError} messages={genericError.messages} />}
          hasCloseButton
          onClose={handleClearGenericError}
        />
      </>
    );
  }

  if (!isOnline) {
    return (
      <>
        {children}
        <Modal
          title="Sem internet"
          content={<OfflineModal onClick={() => setIsOnline(isOnline)} />}
          hasCloseButton={false}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
