import { styled } from 'utils/styles';

export const Container = styled('p', {
  variants: {
    variant: {
      inherit: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
      },
      body1: {
        typo: 'body1',
      },
      body2: {
        typo: 'body2',
      },
      heading1: {
        typo: 'heading1',
      },
      heading2: {
        typo: 'heading2',
      },
      regHeading2: {
        typo: 'regHeading2',
      },
      heading3: {
        typo: 'heading3',
      },
    },
  },
  defaultVariants: {
    variant: 'inherit',
  },
});
